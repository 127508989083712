

.responsive-massage{
    height: 100%;
    width: 100%;
    z-index: 999999999;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: none;
}

.responsive-massage .txt{
    width: 80%;
    max-width: 720px;
    text-align: center;
}

.responsive-massage .txt .logo-img{
    max-width: 100%;
}

.responsive-massage .txt p{
    margin-top: 30px;
    font: 19px/30px "SFPD Bold";
    letter-spacing: 0;
    color: #2b2935;
    margin-bottom: 30px;
    padding: 0 5%;
}

.responsive-massage .txt p.p2{
    margin-top: -10px;
    color: #f05529;
}

.responsive-massage .txt ul{
    margin: 0;
}

.responsive-massage .txt ul li{
    list-style: none;
    display: inline-block;
    margin: 0 10px;
}

.responsive-massage .txt ul li a{
    display: block;
}

.responsive-massage .txt ul li a img{
    width: 100px;
}


#call-button {
    background: rgba(230, 86, 47, .9);
    display: block;
    height: 70px;
    width: 70px;
    position: fixed;
    right: 30px;
    bottom: 20px;
    border: 0;
    border-radius: 50%;
    z-index: 9999;
    outline: 0 !important;
    box-shadow: none;
    transition: all .5s;
}

#call-button .fas {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 35px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    margin: auto;
    text-align: center;
    transition: all .5s;
}

#call-button .fa-plus {
    display: none;
}

#call-button.active {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#call-button.active .fa-headset {
    display: none;
}

#call-button.active .fa-plus {
    display: block;
}

#call-menu {
    position: fixed;
    z-index: 9999;
    right: -400px;
    bottom: 100px;
    opacity: 1;
    transition: all .5s;
    width: 350px;
    box-shadow: -2px 4px 20px #00000029;
    border-radius: 10px;
    overflow: hidden;
}

#call-menu h4 {
    background: #2b2935;
    color: #fff;
    padding: 20px;
    font: 20px/24px "SFPD Bold";
    margin: 0;
}

#call-menu h4 i {
    margin-right: 10px;
}

#call-menu .pm-in {
    padding: 5px 20px;
    background: #fff;
}

#call-menu .pm-in ul {
    margin: 0;
}

#call-menu .pm-in ul li {
    position: relative;
    padding: 20px 20px 20px 70px;
    border-bottom: 1px solid #747474;
    list-style: none;
    margin: 0;
    cursor: pointer;
}

#call-menu .pm-in ul li:last-child {
    border-bottom: 0;
}
#call-menu .pm-in ul li a{
    text-decoration: none;
}

#call-menu .pm-in ul li .icn {
    position: absolute;
    left: 0;
    top: 15px;
    width: 50px;
    height: 50px;
    background: #77b53c;
    border-radius: 50%;
}

#call-menu .pm-in ul li .icn i {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    margin: auto;
    text-align: center;
}

#call-menu .pm-in ul li p.tp {
    font: 16px/20px "SFPD Bold";
    letter-spacing: 0;
    color: #2b2935;
    margin-bottom: 0;
}

#call-menu .pm-in ul li p.bp {
    font: 12px/20px "SFPD Regular";
    letter-spacing: 0;
    color: #2b2935;
    margin-bottom: 0;
}

#call-menu.active {
    right: 30px;
}

.det-p{
    margin-top: 20px;
    text-align: left;
    font: 16px/16px "SFPD Bold";
    letter-spacing: 0;
    color: #000;
    margin-bottom: 0;
    opacity: .7;
}

.transaction-top.reportstt-top {
    min-height: 800px;
}

@media (min-width: 1200px) and (max-width: 1680px) {

    .transaction-top.reportstt-top {
        min-height: 620px;
    }

    #call-button {
        bottom: 10px;
        -webkit-transform: scale(.75);
        transform: scale(.75);
        -webkit-transform-origin: center;
        transform-origin: center;
        right: 20px;
    }

    #call-menu {
        -webkit-transform: scale(.75);
        transform: scale(.75);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        bottom: 85px;
    }

    #call-button.active {
        -webkit-transform: rotate(-45deg) scale(.75);
        transform: rotate(-45deg) scale(.75);
    }
}

@media (max-width: 1199px) {
    .responsive-massage{display: flex;}
}

