
#inside-container .main-row .logo-txt {
    margin-top: 0px;
    padding-left: 15px;
    padding-right: 25px;
    -ms-flex: 0 0 calc(100% - 650px);
    flex: 0 0 calc(100% - 650px);
    max-width: calc(100% - 650px);
}

#inside-container .main-row.banner-mr {
    align-items: center;
    min-height: 100vh;
}
.carousel-indicators{
    visibility: hidden;
}

#inside-container .main-row.banner-mr .carousel-inner img {
    height: calc(100vh - 300px);
    object-fit: contain;
    width: 100%;
    object-position: center;
}

#inside-container .main-row.banner-mr .sign-box{
    margin-top: 0 !important;
}
@media (min-width: 1921px) {
    #inside-container .main-row.banner-mr .sign-box {
        flex: 0 0 650px;
        max-width: 650px;
    }
}
@media (min-width: 1200px) and (max-width: 1680px) {
    #inside-container .main-row .logo-txt {
        margin-top: 0px;
        -ms-flex: 0 0 calc(100% - 37.203%);
        flex: 0 0 calc(100% - 37.203%);
        max-width: calc(100% - 37.203%);
        padding-right: 25px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #inside-container .main-row .logo-txt {
        margin-top: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}




