
.backdrop-loading {
    z-index: 999999999 !important;
    color: #b51e11;
    background-color: #1c7430;
    background-image: url("/src/images/webxlogo_png.png");
}

.slider {
    position: absolute;
    width: 100px;
    height: 100px;
    background: blue;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}
.pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
}
.pagination > li > a{
    border: 1px solid #47ccde ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde ;
    border-color: #47ccde;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}

.in.table-datepicker .date-btn {
    height: 40px;
    width: 100%;
    border: 1px solid #2b2935;
    border-radius: 4px;
    background: #fff;
    font: 12px/22px "SFPD Bold";
    letter-spacing: 0;
    color: #2b2935;
}

.active-class-table{
    display: block;
}

.inactive-class-table{
    display: none;
}

button{
    cursor: pointer;
}

#other-id{
    display: none;
}

#other-id.act{
    display: block;
}

#activation-modal .modal-body .lft .ext-btn.ext2{
    margin-top: 70px;
}

#activation-modal.payment-link-modal.modal-dialog.plmd{
    min-height: calc(100vh - 200px);
    margin: 0 auto;
}
.qrp{
    font-size: 40px;
    font-weight: 700;
    margin-top: 5px;
}
.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht h5 {
    font: 16px/12px "SFPD Bold" !important;
    margin-bottom: 10px !important;
}

.cpy-btn{
    height: 40px;
    width: 40px;
    border: 0;
    background: #89869a;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 18px;
    transform: translateY(1px);
}
.cpy-btn2 {
    height: 64px;
    width: 64px;
    border: 0;
    background: #89869a;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 28px;
    transform: translateY(5px);
}
.ps-keys h6 {
    font: 16px/22px "SFPD Bold";
    margin-bottom: 10px;
    margin-top: 0;
    color: #e6562f;
}

.ps-keys  .form-control[readonly] {
    background-color: #77b53c26;
    opacity: 1;
    font: 14px/24px "SFPD Bold";
    margin-bottom: 20px;
    padding: 20px;
    border-color: #77b53c;
}
@media print {


    .section-to-print, .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.my-qr-modal .close{
    cursor: pointer;
    z-index: 2;
    position: relative;
    font-size: 30px;
    opacity: 1;
    color: #dc5b37;
    outline: 0;
    drop-shadow:0;
}

.settings-outer .lft-sd .nxt-btn.qr-button {
    background: transparent;
    color: #6e6e70;
    border: 0;
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    margin: auto;
}

table.transactions-table.invoice-table.iinvt.rldt tbody>div {
    width: 260px !important;
}

table.transactions-table.invoice-table.iinvt.rldt tbody>div>div {
    width: 280px !important;
}

table.transactions-table.rldt tbody>div>div tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


table.transactions-table.invoice-table.rldt tbody tr td {
    font: 14px/10px "SFPD Bold";
}
table.transactions-table.invoice-table.rldt thead th{
    font: 12.8px/10px "SFPD Bold";
}

.react-switch-bg {
    background: #77b53cc7 !important;
}