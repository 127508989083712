.modal-dialog.activation-modal-dialog {
  max-width: 1200px;
}

#activation-modal .modal-body .rht .t-in .sp-box,
.ctm-modal .modal-body .rht .t-in .sp-box {
  padding: 15px 15px;
}

#activation-modal .modal-body .rht .t-in .sp-box .s-name,
.ctm-modal .modal-body .rht .t-in .sp-box .s-name {
  font: 16px/21px "SFPD Bold";
  padding: 15px;
  margin-bottom: 20px;
}

#activation-modal .activation-modal-dialog .modal-body #v-pills-1 > .t-in {
  margin: 0 -30px;
}

#activation-modal .modal-body .rht .t-in .sp-box p,
.ctm-modal .modal-body .rht .t-in .sp-box p {
  font: 15px/30px "SFPD Bold";
  margin-bottom: 5px;
}

#activation-modal .modal-body .rht .t-in .sp-box h4.fee,
.ctm-modal .modal-body .rht .t-in .sp-box h4.fee {
  font: 22px/30px "SFPD Bold";
}

#activation-modal .modal-body .rht .t-in .sp-box select,
.ctm-modal .modal-body .rht .t-in .sp-box select {
  width: 190px;
  height: 50px;
  padding: 0 15px;
  margin-bottom: 10px;
  font-size: 15px;
}

#activation-modal .modal-body .rht .t-in .sp-box .slct:after,
.ctm-modal .modal-body .rht .t-in .sp-box .slct:after {
  right: 15px;
  top: 17px;
}

#activation-modal .modal-body .rht .t-in .sp-box p.fp,
.ctm-modal .modal-body .rht .t-in .sp-box p.fp {
  font: 12px/18px "SFPD Bold";
  padding: 0 0px;
}

.conformation-outer {
  background: #747474cc;
}
.conformation-outer > .row.row_clr {
  min-height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.conformation-outer .conformation-inner {
  -ms-flex: 0 0 660px;
  -webkit-box-flex: 0;
          flex: 0 0 660px;
  max-width: 660px;
  margin: 40px 0;
}
.conformation-outer .conformation-inner .conformation-inner-top {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  margin-bottom: 30px;
}
.conformation-outer .conformation-inner .conformation-inner-top h3 {
  font: 42px/50px "SFPD Regular";
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 0;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  -webkit-box-shadow: 0px 20px 25px #00000029;
          box-shadow: 0px 20px 25px #00000029;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .status {
  font: 20px/26px "SFPD Bold";
  letter-spacing: 0.37px;
  color: #2b2935;
  margin: 0 0 20px;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .ref-no {
  font: 25px/31px "SFPD Bold";
  letter-spacing: 0.43px;
  color: #2b2935;
  margin-bottom: 10px;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .ref-no span {
  color: #e6562f;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .g-link {
  font: 16px/18px "SFPD Bold";
  color: #77b53c;
  display: block;
  margin: 0 0 20px;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .g-link i {
  margin-left: 5px;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .g-link:hover {
  color: #e6562f;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .g-link.last {
  margin-bottom: 0;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner p {
  font: 15px/19px "SFPD Regular";
  letter-spacing: 0.27px;
  color: #747474;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner p a {
  color: #e6562f;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner p a:hover {
  color: #77b53c;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .separator {
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  opacity: 0.2;
  margin: 20px 0;
}
.conformation-outer .conformation-inner .conformation-inner-middle .inner .bp {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin: 20px 0 10px;
}
.conformation-outer .conformation-inner .conformation-inner-bottom {
  margin-top: 30px;
  text-align: center;
}
.conformation-outer .conformation-inner .conformation-inner-bottom p {
  font: 14px/16px "SFPD Regular";
  letter-spacing: 0.23px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}
.conformation-outer .conformation-inner .conformation-inner-bottom .go {
  width: 80%;
  padding: 20px 10px;
  background: #e6562f;
  -webkit-box-shadow: -2px 4px 20px #00000012;
  box-shadow: -2px 4px 20px #00000012;
  border-radius: 6px;
  display: block;
  font: 700 18px/22px "Roboto";
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 auto;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.conformation-outer .conformation-inner .conformation-inner-bottom .go:hover {
  background: #77b53c;
}

.confirmation-notification-modal-dialog {
  max-width: 976px;
}
.confirmation-notification-modal-dialog .modal-content {
  border-radius: 4px;
  border: 0;
}
.confirmation-notification-modal-dialog .modal-content .modal-body {
  padding: 0;
}
.confirmation-notification-modal-dialog .modal-content .modal-body .container-fluid {
  padding: 0;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle {
  display: grid;
  grid-template-columns: auto auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 60px;
  position: relative;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .close {
  position: absolute;
  right: 40px;
  top: 40px;
  opacity: 1;
  outline: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .close:hover {
  opacity: 0.6;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .img img {
  width: 100%;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt {
  padding-left: 50px;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt .title {
  font: 32px/40px "SFPD Bold";
  color: #2b2935;
  margin-bottom: 20px;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt .title span {
  color: #77b53c;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt .title span.orange {
  color: #e6562f;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt p {
  font: 15px/25px "SFPD Regular";
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0;
}
.confirmation-notification-modal-dialog .modal-content .mb-middle .txt p span {
  color: #e6562f;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom {
  display: grid;
  grid-template-columns: 50% 50%;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link a {
  width: 100%;
  padding: 20px 10px;
  background: #e6562f;
  display: block;
  font: 700 18px/22px "Roboto";
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 auto;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  text-align: center;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link a:hover {
  background: #77b53c;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link.single-link {
  grid-column-start: 1;
  grid-column-end: 3;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link.g-link a {
  background: #f2f2f2;
  color: #2b2935;
}
.confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link.g-link a:hover {
  background: #77b53c;
  color: #fff;
}

#main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-middle .t-icon {
  width: 50px;
  height: 50px;
  background: #77b53c;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 5px auto 20px;
  position: relative;
}

#outer-container.color-orange #main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-middle .t-icon,
#outer-container.color-black #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #77b53c;
}

#main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-last .t-icon {
  width: 66px;
  height: 66px;
  background: #e6562f;
  border-radius: 50%;
  font-size: 28px;
  color: #fff;
  margin: 5px auto 20px;
}

#main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-last .t-icon i {
  color: #fff;
}

#outer-container.color-green #main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-last .t-icon {
  background: #77b53c;
}

#outer-container.color-black #main-content .top-account-line.activation-complete-top-account-line .tal-in.tal-in-last .t-icon {
  background: #2b2935;
}

#main-content .top-account-line.cc-top-account-line {
  padding: 30px 0;
}
#main-content .top-account-line.cc-top-account-line .cc-middle {
  display: grid;
  grid-template-columns: auto auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px;
  position: relative;
  max-width: 950px;
  margin: 0 auto;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .img img {
  width: 100%;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt {
  padding-left: 50px;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .title {
  font: 32px/40px "SFPD Regular";
  color: #2b2935;
  margin-bottom: 20px;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .title span {
  color: #77b53c;
  font-family: "SFPD Bold";
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .title span.orange {
  color: #e6562f;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt p {
  font: 20px/30px "SFPD Bold";
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt p span {
  color: #e6562f;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links {
  margin-top: 20px;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a {
  font: 18px/25px "SFPD Bold";
  color: #e6562f;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a:hover {
  color: #77b53c;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a i {
  margin: 0 3px;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a.green {
  color: #77b53c;
}
#main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a.green:hover {
  color: #e6562f;
}

@media (min-width: 992px) and (max-width: 1680px) {
  .modal-dialog.activation-modal-dialog .modal-content {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: center;
            transform-origin: center;
    margin: -4% 0;
  }

  .conformation-outer .conformation-inner .conformation-inner-top h3 {
    font: 35px/50px "SFPD Regular";
    margin-top: 15px;
  }

  .conformation-outer .conformation-inner .conformation-inner-top {
    margin-bottom: 20px;
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner .status {
    font: 18px/24px "SFPD Bold";
    margin: 0 0 15px;
  }

  .conformation-outer .conformation-inner {
    -ms-flex: 0 0 600px;
    -webkit-box-flex: 0;
    flex: 0 0 600px;
    max-width: 600px;
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner {
    padding: 35px;
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner .ref-no {
    font: 23px/31px "SFPD Bold";
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner .g-link {
    font: 14px/18px "SFPD Bold";
    margin: 0 0 15px;
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner p {
    font: 14px/19px "SFPD Regular";
  }

  .conformation-outer .conformation-inner .conformation-inner-middle .inner .bp {
    font: 16px/21px "SFPD Bold";
    margin: 18px 0 10px;
  }

  .conformation-outer .conformation-inner .conformation-inner-bottom p {
    font: 12.8px/16px "SFPD Regular";
    margin-bottom: 25px;
  }

  .conformation-outer .conformation-inner .conformation-inner-bottom .go {
    padding: 15px 10px;
    font: 700 15px/22px "Roboto";
  }

  .confirmation-notification-modal-dialog .modal-content .mb-middle {
    padding: 30px;
  }

  .confirmation-notification-modal-dialog .modal-content .mb-bottom .b-link a {
    font: 700 16px/22px "Roboto";
  }

  .confirmation-notification-modal-dialog .modal-content .mb-middle .txt .title {
    font: 28px/36px "SFPD Bold";
    margin-bottom: 15px;
  }

  .confirmation-notification-modal-dialog {
    max-width: 900px;
  }

  #main-content .top-account-line .tal-in h4 {
    font: 17px/21px "SFPD Bold";
  }

  #main-content .top-account-line .tal-in p {
    font: 12.8px/16px "SFPD Regular";
    margin-bottom: 10px;
  }

  #main-content .top-account-line .tal-in a {
    font: 16px/25px "SFPD Bold";
  }

  #main-content .top-account-line {
    padding: 30px 0;
  }

  #main-content .top-account-line.cc-top-account-line {
    padding: 20px 0;
  }

  #main-content .top-account-line.cc-top-account-line .cc-middle .txt .title {
    font: 28px/40px "SFPD Regular";
    margin-bottom: 15px;
  }

  #main-content .top-account-line.cc-top-account-line .cc-middle .txt p {
    font: 15px/26px "SFPD Bold";
  }

  #main-content .top-account-line.cc-top-account-line .cc-middle .txt .links a {
    font: 15px/25px "SFPD Bold";
  }

  #main-content .top-account-line.cc-top-account-line .cc-middle .txt .links {
    margin-top: 15px;
  }

  #main-content .top-account-line.cc-top-account-line .cc-middle {
    max-width: 700px;
    grid-template-columns: 30% 70%;
  }
}
/*
dashboard-popups.html
confirmation-paid-confirmed.html
confirmation-paid-submitted.html
confirmation-documents-uploaded.html
confirmation-credit-check-successful.html
confirmation-credit-check-failed.html
dashboard-activation-complete.html
dashboard-credit-check.html
dashboard-credit-check-failed.html
*//*# sourceMappingURL=screen.css.map */