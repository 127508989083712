.destinations-search .sidebar__inner ul {
    margin-bottom: 40px;
}

.destinations-search p {
    margin-bottom: 40px;
}

.destinations-search h5.b-time {
    font: 600 17px/26px 'Poppins', sans-serif;
    color: #4f5767;
}

.destinations-search h5.b-time span.ii-span {
    display: block;
    font-weight: 600;
}

.destinations-search ul li {
    margin-bottom: 10px;
}

.destinations-search .map-col {
    margin-top: 10px;
}

ul.box-icon-list li img, ul.box-icon-list li svg {
    width: 100%;
}

#nav-tabContent .box-icon-list, .tab-content .box-icon-list {
    padding-left: 0;
}

#nav-tabContent .box-icon-list li, .tab-content .box-icon-list li {
    width: auto;
    max-width: 32px;
}

.home-page #middle-main-content {
    margin-top: 90px;
}

.bin-info-form label {
    font: 12px/14px "SFPD Bold";
    padding: 0;
}

.bin-info-form .col-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-end;
}

.bin-info-form .form-control {
    height: 35px;
    border: 1px solid #747474;
    border-radius: 6px;
    padding: 0 5px;
    outline: 0 !important;
    box-shadow: none;
    font: 12px/16px "SFPD Regular";
}

@media (min-width: 1200px) and (max-width: 1680px) {
    .transaction-top .tab-content .tr-form.inv-tr .srch-btn {
        padding: 8.5px 30px;
        font: 12.8px/19px "SFPD Bold";
        top:-5px;
    }
}


@media (max-width: 991px) and (min-width: 768px) {
    .home-page #middle-main-content {
        margin-top: 70px;
    }
}

@media only screen and (max-width: 767px) {
    .dwn-pop {
        bottom: auto;
        top: 430px;
    }

    .home-page #middle-main-content {
        margin-top: 40px;
    }
}

.settings-outer .lft-sd .nxt-btn.chg-pwd{
    background: transparent;
    color: #6e6e70;
    border: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.col-md-6.pack-detail {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1350px) {
    .col-md-6.pack-detail {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}
.t-in .sp-box .check-plan {
    padding: -1px 30px;
    background: #77b53c;
    border-radius: 6px;
    margin-bottom: 10px;
    font: 18px/22px "SFPD Bold";
    letter-spacing: 0.3px;
    color: #fff;
    position: relative;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    width: 50%;
    margin-top: 15px;
    text-transform: uppercase;
    border: 2px solid ;
    outline: 0;
}

.t-in .sp-box .check-plan:hover{
    background: #e6562f;
}

.t-in .sp-box .check-plan {
    padding: 8px 25px;
    font: 16px/22px "SFPD Bold";
    width: 55%;
    margin: 0px 0 15px;
    border-radius: 6px;
    transition: all .35s;
    border: 0;
}

.check-plan-btn {
    padding: -1px 30px;
    background: #77b53c;
    border-radius: 6px;
    margin-bottom: 10px;
    font: 18px / 22px "SFPD Bold";
    letter-spacing: 0.3px;
    color: #fff;
    position: relative;
    transition: all 0.35s;
    width: 50%;
    margin-top: 15px;
    text-transform: uppercase;
    border: 2px solid;
    outline: 0;
}

.check-plan-btn:hover{
    background: #e6562f;
}

/*Payment Details Table*/

.ins-payment-details-table{
    margin-top: 20px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.ins-payment-details-table p{
    margin-bottom: 0;
}
.ins-payment-details-table.table tr{
    border: 1px solid #dee2e6;
}
.ins-payment-details-table.table th {
    padding: 1rem .75rem;
    vertical-align: middle;
    border-top: 0;
    background: #11133a;
    color: #fff;
}
.ins-payment-details-table.table td {
    padding: .25rem .75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}
.ins-payment-details-table.table td label {
    display: inline-block;
    margin: 0px 0px 0px;
    transform: translateY(3px) scale(0.88);
}

.ins-payment-details-table.table td button.nt-btn {
    padding: 6px 20px 5px;
    background: #e6562f;
    border-radius: 6px;
    color: #fff;
    position: relative;
    transition: all 0.35s;
    text-transform: uppercase;
    border: 2px solid #e6562f;
    outline: 0;
    margin: 7px 0 7px 15px;
    font-size: 14px;
}

/*.ins-payment-details-table.table td:last-child {
    text-align: center;
}*/

.rg-container #prev{
    cursor: pointer;
    padding: 6px 20px 5px;
    background: #e6562f;
    border-radius: 6px;
    color: #fff;
    position: relative;
    transition: all 0.35s;
    text-transform: uppercase;
    border: 2px solid #e6562f;
    outline: 0;
    margin: 0;
    font-size: 14px;
}

.rg-container #prev.disable-btn-scl{
    background: #bdbdbd;
    border-color: #bdbdbd;
    cursor: not-allowed !important;
}

.rg-container #next{
    cursor: pointer;
    padding: 6px 20px 5px;
    background: #8bc540;
    border-radius: 6px;
    color: #fff;
    position: relative;
    transition: all 0.35s;
    text-transform: uppercase;
    border: 2px solid #8bc540;
    outline: 0;
    margin: 0;
    font-size: 14px;
}

.cbcpl{
    text-align: left;
    font: 20px "SFPD Bold";
    letter-spacing: 0;
    color: #2b2935;
    margin-bottom: 20px;
}

.sd-tab button.wts-btn{
    height: 60px;
    width: 60px;
    border: 0;
    background: #89869a;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 20px;
    transform: translateY(1px);
}


.sd-tab button.cpy-btn{
    height: 60px;
    width: 60px;
    border: 0;
    background: #89869a;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 20px;
    transform: translateY(2px);
}

.sd-tab button.btn-sms{
    height: 60px;
    width: 60px;
    border: 0;
    background: #0a9a44;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 20px;
    transform: translateY(2px);
}

.sd-tab button.btn-email{
    height: 60px;
    width: 60px;
    border: 0;
    background: #180cc9;
    color: #fff;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 20px;
    transform: translateY(1px);
}

