@charset "UTF-8";
/*create scss structure*/
/*
You can define a default value as well if an attribute
isn't passed

@mixin default-text-color($tc: $black){
  color: $tc;
}
*/
#outer-container.color-orange #side-navigation .act-acc p.ns span {
  color: #e6562f;
}
#outer-container.color-orange #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}
#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #e6562f;
}
#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a {
  color: #e6562f;
}
#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #e6562f;
}
#outer-container.color-orange #turn-box {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#FF7651), to(#FFD0C4)) 0% 0%;
  background: transparent linear-gradient(180deg, #FF7651 0%, #FFD0C4 100%) 0% 0%;
}
#outer-container.color-orange #main-content .top-line p span.rate {
  color: #E6562F;
}
#outer-container.color-orange #main-content .top-line p a {
  color: #77B53C;
}
#outer-container.color-orange .mid-gate-line .gate-tool .g-tool a {
  color: #e6562f;
}
#outer-container.color-orange #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #e6562f;
}
#outer-container.color-orange #main-content .top-account-line .tal-in a {
  color: #e6562f;
}
#outer-container.color-orange .balance-line .in h5 span {
  color: #e6562f;
}
#outer-container.color-orange .transactions .t-list li.active {
  background: #77b53c;
}
#outer-container.color-orange .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #e6562f;
}
#outer-container.color-orange .footer p a {
  color: #e6562f;
}
#outer-container.color-orange .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #e6562f;
}
#outer-container.color-orange #main-content .top-line .notification-bell:after {
  background: #e6562f;
}

#outer-container.color-green #side-navigation .act-acc p.ns span {
  color: #77B53C;
}
#outer-container.color-green #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}
#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #77B53C;
}
#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a {
  color: #77B53C;
}
#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #77B53C;
}
#outer-container.color-green #turn-box {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#77B53C), to(#81F811)) 0% 0%;
  background: transparent linear-gradient(180deg, #77B53C 0%, #81F811 100%) 0% 0%;
}
#outer-container.color-green #main-content .top-line p span.rate {
  color: #77B53C;
}
#outer-container.color-green #main-content .top-line p a {
  color: #77B53C;
}
#outer-container.color-green .mid-gate-line .gate-tool .g-tool a {
  color: #77B53C;
}
#outer-container.color-green #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #77B53C;
}
#outer-container.color-green #main-content .top-account-line .tal-in a {
  color: #77B53C;
}
#outer-container.color-green .balance-line .in h5 span {
  color: #77B53C;
}
#outer-container.color-green .transactions .t-list li.active {
  background: #E6562F;
}
#outer-container.color-green .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #77B53C;
}
#outer-container.color-green .footer p a {
  color: #77B53C;
}
#outer-container.color-green .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #77B53C;
}
#outer-container.color-green #main-content .top-line .notification-bell:after {
  background: #77B53C;
}

#outer-container.color-black #side-navigation .act-acc p.ns span {
  color: #e6562f;
}
#outer-container.color-black #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}
#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #2B2935;
}
#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a {
  color: #2B2935;
}
#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #2B2935;
}
#outer-container.color-black #turn-box {
  background: transparent linear-gradient(225deg, #2B2935 0%, #CBCBCB80 100%) 0% 0%;
}
#outer-container.color-black #main-content .top-line p span.rate {
  color: #E6562F;
}
#outer-container.color-black #main-content .top-line p a {
  color: #77B53C;
}
#outer-container.color-black .mid-gate-line .gate-tool .g-tool a {
  color: #e6562f;
}
#outer-container.color-black #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #2B2935;
}
#outer-container.color-black #main-content .top-account-line .tal-in a {
  color: #e6562f;
}
#outer-container.color-black .balance-line .in h5 span {
  color: #e6562f;
}
#outer-container.color-black .transactions .t-list li.active {
  background: #2B2935;
}
#outer-container.color-black .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #e6562f;
}
#outer-container.color-black .footer p a {
  color: #e6562f;
}
#outer-container.color-black .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #e6562f;
}
#outer-container.color-black #main-content .top-line .notification-bell:after {
  background: #e6562f;
}

/*basic styles*/
@font-face {
  font-family: "SFPD Regular";
  src: url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot");
  /* IE9*/
  src: url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot?#iefix") format("embedded-opentype"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff2") format("woff2"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff") format("woff"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.ttf") format("truetype"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.svg#SF Pro Display Regular") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "SFPD Bold";
  src: url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.eot");
  /* IE9*/
  src: url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.eot?#iefix") format("embedded-opentype"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.woff2") format("woff2"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.woff") format("woff"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.ttf") format("truetype"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.svg#SF Pro Display Bold") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "SFPD Light";
  src: url("../fonts/70c38766a71f8f7f0a477605da15fcd3.eot");
  /* IE9*/
  src: url("../fonts/70c38766a71f8f7f0a477605da15fcd3.eot?#iefix") format("embedded-opentype"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.woff2") format("woff2"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.woff") format("woff"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.ttf") format("truetype"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.svg#SF Pro Display Light") format("svg");
  /* iOS 4.1- */
}
.row_clr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

* {
  padding: 0;
  margin: 0;
}

.no-pad {
  padding: 0;
}

@media (min-width: 768px) {
  .col-sm-20 {
    width: 20%;
    position: relative;
  }
}
.hid {
  opacity: 0;
}

#__bs_notify__ {
  display: none !important;
}

body {
  background: #fff;
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
/*main styles*/
/*create scss structure*/
/*
You can define a default value as well if an attribute
isn't passed

@mixin default-text-color($tc: $black){
  color: $tc;
}
*/
#outer-container.color-orange #side-navigation .act-acc p.ns span {
  color: #e6562f;
}

#outer-container.color-orange #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}

#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #e6562f;
}

#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a {
  color: #e6562f;
}

#outer-container.color-orange #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #e6562f;
}

#outer-container.color-orange #turn-box {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#ff7651), to(#ffd0c4)) 0% 0%;
  background: transparent linear-gradient(180deg, #ff7651 0%, #ffd0c4 100%) 0% 0%;
}

#outer-container.color-orange #main-content .top-line p span.rate {
  color: #e6562f;
}

#outer-container.color-orange #main-content .top-line p a {
  color: #77b53c;
}

#outer-container.color-orange .mid-gate-line .gate-tool .g-tool a {
  color: #e6562f;
}

#outer-container.color-orange #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #e6562f;
}

#outer-container.color-orange #main-content .top-account-line .tal-in a {
  color: #e6562f;
}

#outer-container.color-orange .balance-line .in h5 span {
  color: #e6562f;
}

#outer-container.color-orange .transactions .t-list li.active {
  background: #77b53c;
}

#outer-container.color-orange .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #e6562f;
}

#outer-container.color-orange .footer p a {
  color: #e6562f;
}

#outer-container.color-orange .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #e6562f;
}

#outer-container.color-orange #main-content .top-line .notification-bell:after {
  background: #e6562f;
}

#outer-container.color-green #side-navigation .act-acc p.ns span {
  color: #77b53c;
}

#outer-container.color-green #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}

#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #77b53c;
}

#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a {
  color: #77b53c;
}

#outer-container.color-green #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #77b53c;
}

#outer-container.color-green #turn-box {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#77b53c), to(#81f811)) 0% 0%;
  background: transparent linear-gradient(180deg, #77b53c 0%, #81f811 100%) 0% 0%;
}

#outer-container.color-green #main-content .top-line p span.rate {
  color: #77b53c;
}

#outer-container.color-green #main-content .top-line p a {
  color: #77b53c;
}

#outer-container.color-green .mid-gate-line .gate-tool .g-tool a {
  color: #77b53c;
}

#outer-container.color-green #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #77b53c;
}

#outer-container.color-green #main-content .top-account-line .tal-in a {
  color: #77b53c;
}

#outer-container.color-green .balance-line .in h5 span {
  color: #77b53c;
}

#outer-container.color-green .transactions .t-list li.active {
  background: #e6562f;
}

#outer-container.color-green .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #77b53c;
}

#outer-container.color-green .footer p a {
  color: #77b53c;
}

#outer-container.color-green .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #77b53c;
}

#outer-container.color-green #main-content .top-line .notification-bell:after {
  background: #77b53c;
}

#outer-container.color-black #side-navigation .act-acc p.ns span {
  color: #e6562f;
}

#outer-container.color-black #side-navigation .act-acc .line-outer .line-comp .gl {
  background: #77b53c;
}

#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a:after {
  background: #2b2935;
}

#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a {
  color: #2b2935;
}

#outer-container.color-black #side-navigation .nav-list-outer .nav-list li.active a .na {
  color: #2b2935;
}

#outer-container.color-black #turn-box {
  background: transparent linear-gradient(225deg, #2b2935 0%, #cbcbcb80 100%) 0% 0%;
}

#outer-container.color-black #main-content .top-line p span.rate {
  color: #e6562f;
}

#outer-container.color-black #main-content .top-line p a {
  color: #77b53c;
}

#outer-container.color-black .mid-gate-line .gate-tool .g-tool a {
  color: #e6562f;
}

#outer-container.color-black #main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  background: #2b2935;
}

#outer-container.color-black #main-content .top-account-line .tal-in a {
  color: #e6562f;
}

#outer-container.color-black .balance-line .in h5 span {
  color: #e6562f;
}

#outer-container.color-black .transactions .t-list li.active {
  background: #2b2935;
}

#outer-container.color-black .mid-gate-line.w-boxes .gate-tool h3 span {
  color: #e6562f;
}

#outer-container.color-black .footer p a {
  color: #e6562f;
}

#outer-container.color-black .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  color: #e6562f;
}

#outer-container.color-black #main-content .top-line .notification-bell:after {
  background: #e6562f;
}

/*basic styles*/
@font-face {
  font-family: "SFPD Regular";
  src: url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot");
  /* IE9*/
  src: url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot?#iefix") format("embedded-opentype"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff2") format("woff2"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff") format("woff"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.ttf") format("truetype"), url("../fonts/0b51833ff62e4af8acb5fd3e2bf59e97.svg#SF Pro Display Regular") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "SFPD Bold";
  src: url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.eot");
  /* IE9*/
  src: url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.eot?#iefix") format("embedded-opentype"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.woff2") format("woff2"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.woff") format("woff"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.ttf") format("truetype"), url("../fonts/3cff9206b4c8d578245ef1aaeeaf9e84.svg#SF Pro Display Bold") format("svg");
  /* iOS 4.1- */
}
@font-face {
  font-family: "SFPD Light";
  src: url("../fonts/70c38766a71f8f7f0a477605da15fcd3.eot");
  /* IE9*/
  src: url("../fonts/70c38766a71f8f7f0a477605da15fcd3.eot?#iefix") format("embedded-opentype"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.woff2") format("woff2"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.woff") format("woff"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.ttf") format("truetype"), url("../fonts/70c38766a71f8f7f0a477605da15fcd3.svg#SF Pro Display Light") format("svg");
  /* iOS 4.1- */
}
.row_clr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

* {
  padding: 0;
  margin: 0;
}

.no-pad {
  padding: 0;
}

@media (min-width: 768px) {
  .col-sm-20 {
    width: 20%;
    position: relative;
  }
}
.hid {
  opacity: 0;
}

#__bs_notify__ {
  display: none !important;
}

body {
  background: #fff;
  font-family: "Poppins", sans-serif;
  color: #2e2e2e;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
/*main styles*/
/*Sign Up*/
#turn-box {
  width: 1878px;
  height: 1878px;
  background: -webkit-gradient(linear, left top, left bottom, from(#2b2935), to(rgba(203, 203, 203, 0.5)));
  background: linear-gradient(#2b2935 0%, rgba(203, 203, 203, 0.5) 100%);
  opacity: 0.45;
  position: absolute;
  -webkit-transform: matrix(0.54, 0.84, -0.84, 0.54, 0, 0);
  transform: rotate(60deg);
  top: 190px;
  right: -45%;
  border-radius: 5%;
}

#outer-container {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background: #f9f9f9;
  overflow: hidden;
  position: relative;
  padding: 0;
}

#outer-container.outer-container-inside-page {
  height: auto;
}

#inside-container {
  max-width: 100%;
  padding: 0 120px;
}

#inside-container .main-row {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#inside-container .main-row .logo-txt {
  margin-top: 150px;
  padding-left: 30px;
}

#inside-container .main-row .logo-txt h1 {
  font: 700 50px/60px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin: 95px 0 20px;
}

#inside-container .main-row .logo-txt h1 span {
  color: #e6562f;
}

#inside-container .main-row .logo-txt h3 {
  font: 400 21px/18px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin: 0;
}

#inside-container .main-row .logo-txt h4 {
  text-align: left;
  font: 400 22px/40px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-top: 80px;
}

#inside-container .main-row .logo-txt h4 a {
  color: #77b53c;
  text-decoration: none;
  margin-left: 15px;
}

#inside-container .main-row .logo-txt .help {
  font: 700 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 30px;
  text-decoration: none;
}

.sign-box {
  width: 625px;
  height: calc(100vh - 200px);
  background: #fff;
  border-radius: 4px;
  margin-top: 100px;
  padding: 70px 85px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 37.203%;
          flex: 0 0 37.203%;
  max-width: 37.203%;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.sign-box .cont {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font: 700 16px/19px "SFPD Bold";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  left: 0;
  top: 0;
  width: 100%;
  letter-spacing: 0.5px;
  color: #555;
}

.sign-box .cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sign-box .checkmark {
  position: absolute;
  top: 2px;
  left: 5px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid #59595c;
  border-radius: 2px;
}

.sign-box .cont:hover input ~ .checkmark {
  background-color: #ccc;
}

.sign-box .cont input:checked ~ .checkmark {
  background-color: #77b53c;
  border: 1px solid #77b53c;
}

.sign-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sign-box .cont input:checked ~ .checkmark:after {
  display: block;
}

.sign-box .cont .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#inside-container .copy-txt {
  text-align: center;
  padding: 0 15%;
}

#inside-container .copy-txt .cop-p {
  font: 400 12px "SFPD Regular";
  text-align: center;
  line-height: 17px;
  color: #555;
  margin-bottom: 16px;
}

#inside-container .copy-txt .cop-p .green-txt {
  color: #77b53c;
  text-decoration: none;
}

#inside-container .help {
  text-align: right;
  margin-top: 25px;
  padding: 0;
}

#inside-container .help .help-btn {
  font: 700 17px "SFPD Bold";
  color: #9394ad;
  border: 0;
  background: transparent;
  line-height: 22px;
}

#inside-container .help .help-btn .far {
  margin-left: 10px;
  font-size: 22px;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

#inside-container .sign-top .sign-icon {
  margin: 0 auto 20px;
  display: block;
}

#inside-container .sign-top .sign-head {
  font: 700 30px/36px "SFPD Regular";
  color: #555;
  text-align: center;
  margin-bottom: 40px;
}

#inside-container .form-type-1 {
  padding: 0;
}

#inside-container .form-type-1 .form-group {
  margin-bottom: 20px;
  position: relative;
}

#inside-container .form-type-1 .form-group .form-control {
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  height: 57px;
  border: 0;
  background: #fff;
  font: 400 16px/19px "SFPD Regular";
  letter-spacing: 0.27px;
  color: rgba(75, 77, 119, 0.6);
  padding: 20px;
  border-radius: 6px;
}

#inside-container .form-type-1 ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(75, 77, 119, 0.6);
}

#inside-container .form-type-1 :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(75, 77, 119, 0.6);
}

#inside-container .form-type-1 ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(75, 77, 119, 0.6);
}

#inside-container .form-type-1 ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: rgba(75, 77, 119, 0.6);
}

#inside-container .form-type-1 ::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(75, 77, 119, 0.6);
}

#inside-container .form-type-1 .password {
  padding-right: 65px;
}

#inside-container .form-type-1 .password-txt {
  position: absolute;
  top: 0;
  width: 65px;
  right: 0;
  height: 100%;
  border: 0;
  border-radius: 6px;
  background: transparent;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  outline: 0;
}

#inside-container .form-type-1 .password-txt img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#inside-container .form-type-1 .password-txt:after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(../../images/visibility-orange.svg);
  position: absolute;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

#inside-container .form-type-1 .password-txt.act:after {
  opacity: 1;
}

#inside-container .form-type-1 .password-txt.act img {
  opacity: 0;
}

#inside-container .form-type-1 .c-code {
  position: absolute;
  top: 0;
  width: 65px;
  left: 0;
  height: 100%;
  border: 0;
  border-radius: 6px;
  background: transparent;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  outline: 0;
  font: 400 16px/19px "SFPD Regular";
  letter-spacing: 0.27px;
  color: rgba(75, 77, 119, 0.6);
  padding: 20px 10px;
  text-align: center;
}

#inside-container .form-type-1 .c-code:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 28px;
  background: #9394ad;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

#inside-container .form-type-1 #tel {
  padding-left: 80px;
}

#inside-container .form-type-1 .fogp {
  text-align: right;
}

#inside-container .form-type-1 .fogp .fp {
  font: 700 16px "SFPD Bold";
  color: #77b53c;
  line-height: 20px;
  letter-spacing: 0.27px;
  text-decoration: none;
}

#inside-container .form-type-1 .submit #submit-btn {
  font: 700 18px "Roboto", sans-serif;
  letter-spacing: 0.3px;
  line-height: 22px;
  width: 100%;
  height: 57px;
  border: 1px solid #e6562f;
  text-align: center;
  display: block;
  color: #fff;
  padding: 16px 35px;
  border-radius: 6px;
  margin: 60px auto 30px;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: #e6562f;
  outline: 0;
  text-transform: uppercase;
}

#inside-container .form-type-1 .submit #submit-btn:hover {
  background: #fff;
  color: #e6562f;
}

#inside-container .form-type-1 .submit #submit-btn.create-account-btn {
  margin-top: 20px;
}

#inside-container .no-account {
  text-align: center;
}

#inside-container .no-account p.sms-p {
  font: 700 14px/16px "SFPD Bold";
  letter-spacing: 0.23px;
  color: #555;
}

#inside-container .no-account .no-account-p {
  font: 400 16px/20px "SFPD Regular";
  color: #59595c;
  margin-bottom: 55px;
}

#inside-container .no-account .no-account-p .caccount {
  color: #e6562f;
  font-family: "SFPD Bold";
  text-decoration: none;
}

#inside-container .sign-box p.time-left {
  text-align: center;
  font: 400 21px/25px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 30px;
}

#inside-container .sign-box .pin-ver-form .form-group .form-control {
  height: 60px;
}

#inside-container .sign-box .change-tel {
  font: 700 18px "Roboto", sans-serif;
  letter-spacing: 0.3px;
  line-height: 22px;
  width: 100%;
  height: 57px;
  border: 1px solid #e6562f;
  text-align: center;
  display: block;
  color: #e6562f;
  padding: 16px 35px;
  border-radius: 6px;
  margin: -10px auto 30px;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: #fff;
  outline: 0;
  text-transform: uppercase;
}

#inside-container .sign-box .change-tel:hover {
  background: #e6562f;
  color: #fff;
}

#inside-container .sign-box .go-back {
  text-align: center;
}

#inside-container .sign-box .go-back a {
  font: 700 18px/22px "SFPD Bold";
  letter-spacing: 0.37px;
  color: #555;
  text-decoration: none;
}

/*dashboard page*/
#side-navigation {
  width: 350px;
  height: 100vh;
  background: #fff;
  margin: 0;
  padding: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 350px;
          flex: 0 0 350px;
  max-width: 350px;
  -webkit-box-shadow: 0 10px 30px #d1d5df80;
          box-shadow: 0 10px 30px #d1d5df80;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

#side-navigation > .sn-row {
  height: 100%;
}

#side-navigation .logo-outer {
  text-align: center;
  height: 100px;
  position: relative;
  padding: 0;
}

#side-navigation .logo-outer img.logo {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#side-navigation .act-acc {
  background: #f8f9fa;
  -webkit-box-shadow: 0 3px 6px #00000029;
          box-shadow: 0 3px 6px #00000029;
  padding: 16px 40px;
  max-height: 124px;
}

#side-navigation .act-acc h3 {
  font: 14px/14px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 12px;
  position: relative;
}

#side-navigation .act-acc h3 .arrow-r {
  position: absolute;
  right: 0;
  top: 4px;
}

#side-navigation .act-acc .t-amt {
  font: 22px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

#side-navigation .act-acc p.ns {
  font: 12.8px/16px "SFPD Regular";
  letter-spacing: 0;
  margin-bottom: 10px;
}

#side-navigation .act-acc .line-outer {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#side-navigation .act-acc .line-outer .line-comp {
  height: 10px;
  width: 100%;
  background: #e6e8ee 0% 0% no-repeat padding-box;
  border-radius: 50px;
  position: relative;
  margin-bottom: 2px;
}

#side-navigation .act-acc .line-outer .line-comp .gl {
  position: absolute;
  left: 0;
  top: 0;
  width: 15%;
  height: 10px;
  border-radius: 50px;
}

#side-navigation .act-acc .line-outer span {
  font: 12px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #141821;
}

#side-navigation .nav-list-outer {
  padding: 20px 0;
  height: calc(100% - 100px - 124px - 100px);
}

#side-navigation .nav-list-outer .nav-list {
  height: 100%;
  margin: 0;
}

#side-navigation .nav-list-outer .nav-list li {
  width: 100%;
  height: calc((100% / 8) - 8px);
  margin-bottom: 8px;
}

#side-navigation .nav-list-outer .nav-list li a {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-decoration: none;
  padding-left: 110px;
  position: relative;
  font: 18px/27px "SFPD Bold";
  letter-spacing: 0;
  color: #c3c6d1;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

#side-navigation .nav-list-outer .nav-list li a:after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 90px;
  border-radius: 0 30px 30px 0;
  z-index: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  opacity: 0;
}

#side-navigation .nav-list-outer .nav-list li a .ni {
  position: absolute;
  left: 44px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  z-index: 2;
}

#side-navigation .nav-list-outer .nav-list li a .ni.wi {
  opacity: 0;
}

#side-navigation .nav-list-outer .nav-list li a .na {
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  z-index: 2;
  color: #c3c6d1;
}

#side-navigation .nav-list-outer .nav-list li a .na.oa {
  opacity: 0;
}

#side-navigation .nav-list-outer .nav-list li:hover a:after {
  opacity: 1;
  background: #c3c6d1;
}

#side-navigation .nav-list-outer .nav-list li:hover a .ni.wi {
  opacity: 1;
}

#side-navigation .nav-list-outer .nav-list li:hover a .ni.gi {
  opacity: 0;
}

#side-navigation .nav-list-outer .nav-list li.active a:after {
  opacity: 1;
}

#side-navigation .nav-list-outer .nav-list li.active a .ni.wi {
  opacity: 1;
}

#side-navigation .nav-list-outer .nav-list li.active a .ni.gi {
  opacity: 0;
}

#side-navigation .nav-user {
  height: 100px;
  padding: 0 40px;
  border-top: 1px solid #eeeff3;
}

#side-navigation .nav-user .nu-in {
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-decoration: none;
  position: relative;
  font: 18px/27px "SFPD Bold";
  letter-spacing: 0;
  color: #c3c6d1;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

#side-navigation .nav-user .nu-in a {
  display: block;
  width: 100%;
  font: 18px/30px "SFPD Bold";
  letter-spacing: 0;
  color: #141821;
  text-decoration: none;
}

#side-navigation .nav-user .nu-in a img.user {
  margin-right: 25px;
}

#side-navigation .nav-user .nu-in a img.dots {
  margin-left: 48px;
}

#main-content {
  z-index: 3;
  position: relative;
  padding-left: 350px;
}

#main-content .top-line {
  padding: 19.5px 0;
  padding-left: 30px;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px #00000012;
          box-shadow: 0 5px 10px #00000012;
  z-index: 2;
}

#main-content .top-line p {
  font: 20px/21px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

#main-content .top-line p a {
  margin-left: 10px;
}

#main-content .top-line .notification-bell {
  position: absolute;
  right: 250px;
  top: 16px;
  width: 25px;
  height: 25px;
  text-align: center;
}

#main-content .top-line .notification-bell:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  background: #e6562f;
  right: 2px;
  top: 0;
  border-radius: 50%;
  border: 1px solid #fff;
}

#main-content .top-line .sandbox {
  position: absolute;
  padding: 17.5px 20px;
  right: 0;
  top: 0;
  font: 20px/25px "SFPD Bold";
  letter-spacing: 0;
  color: #fff;
  min-width: 215px;
  background: #e6562f;
  text-align: center;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#main-content .top-line .sandbox .l-circle {
  height: 24px;
  width: 24px;
  float: left;
  display: inline-block;
  border: 4px solid #fff;
  border-radius: 50%;
  margin-right: 15px;
}

#main-content .top-account-line {
  padding: 50px 0;
  background: #fff;
}

#main-content .top-account-line > .row {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#main-content .top-account-line .tal-in {
  width: 220px;
  text-align: center;
  position: relative;
}

#main-content .top-account-line .tal-in .t-icon {
  width: 50px;
  height: 50px;
  background: #77b53c;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 5px auto 20px;
  position: relative;
}

#main-content .top-account-line .tal-in .t-icon i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}

#main-content .top-account-line .tal-in h4 {
  font: 20px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 10px;
}

#main-content .top-account-line .tal-in p {
  font: 14px/16px "SFPD Regular";
  letter-spacing: 0;
  color: #747474;
}

#main-content .top-account-line .tal-in a {
  font: 20px/25px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  text-decoration: none;
}

#main-content .top-account-line .tal-in a i {
  margin-left: 7px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

#main-content .top-account-line .tal-in.tal-in-middle {
  margin: 0 280px;
}

#main-content .top-account-line .tal-in.tal-in-middle .t-icon {
  width: 66px;
  height: 66px;
  background: #e6562f;
  border-radius: 50%;
  font-size: 28px;
  color: #fff;
  margin: 5px auto 20px;
}

#main-content .top-account-line .tal-in.tal-in-middle:before {
  content: "";
  width: 200px;
  height: 3px;
  position: absolute;
  top: 55%;
  left: -242px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(33%, #77b53c), color-stop(0%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, #77b53c 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 25px 3px;
  background-repeat: repeat-x;
}

#main-content .top-account-line .tal-in.tal-in-middle:after {
  content: "";
  width: 200px;
  height: 3px;
  position: absolute;
  top: 55%;
  right: -238px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(33%, #77b53c), color-stop(0%, rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, #77b53c 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 25px 3px;
  background-repeat: repeat-x;
}

#main-content .top-account-line .tal-in.tal-in-last .t-icon {
  background: #e4e4e5;
}

#main-content .top-account-line .tal-in.tal-in-last .t-icon i {
  color: #2b2935;
}

.mid-gate-line {
  margin-top: 40px;
  margin-bottom: 50px;
}

.mid-gate-line .gate-tool > .row {
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  overflow: hidden;
}

.mid-gate-line .gate-tool h3 {
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin: 0 0 20px;
  position: relative;
}

.mid-gate-line .gate-tool h3 span.rs {
  position: absolute;
  right: 0;
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935 !important;
  top: 5px;
}

.mid-gate-line .gate-tool .g-tool {
  background: #fff;
  padding: 42px 40px;
  text-align: center;
}

.mid-gate-line .gate-tool .g-tool .icon-d {
  margin-bottom: 26px;
}

.mid-gate-line .gate-tool .g-tool .icon-d i {
  font-size: 70px;
  color: #2b2935;
  opacity: 0.5;
}

.mid-gate-line .gate-tool .g-tool h4 {
  font: 24px/30px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 10px;
}

.mid-gate-line .gate-tool .g-tool p {
  font: 14px/16px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  padding: 0;
  margin-bottom: 15px;
}

.mid-gate-line .gate-tool .g-tool a {
  font: 15px/25px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  text-decoration: none;
}

.mid-gate-line .gate-tool .g-tool a i {
  margin-left: 7px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.mid-gate-line .gate-tool .g-tool:after {
  content: "";
  height: calc(100% - 60px);
  width: 1px;
  background: #707070;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 30px;
}

.mid-gate-line .personalize-db > .row {
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
}

.mid-gate-line .personalize-db h3 {
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin: 0 0 20px;
  position: relative;
}

.mid-gate-line .personalize-db h3 span.rs {
  position: absolute;
  right: 0;
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935 !important;
  top: 5px;
}

.mid-gate-line .personalize-db .nav-tabs {
  border: 0;
}

.mid-gate-line .personalize-db .nav-tabs .nav-item {
  width: 33.333333%;
  text-align: center;
}

.mid-gate-line .personalize-db .nav-tabs .nav-item a {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  padding: 20px 10px;
  border: 0;
  background: #f1f5f8;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.mid-gate-line .personalize-db .nav-tabs .nav-item a.active {
  background: #fff;
}

.mid-gate-line .personalize-db .tab-content .tab-pane {
  padding: 30px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 > .row {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img {
  width: 180px;
  float: left;
  margin-right: 20px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box {
  width: 180px;
  height: 180px;
  background: #f1f5f8;
  border-radius: 4px;
  position: relative;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box img {
  position: absolute;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img p {
  text-align: center;
  font: 12px/14px "SFPD Regular";
  letter-spacing: 0;
  color: #747474;
  margin-bottom: 0;
  margin-top: 10px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 h4 {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 23px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p1 {
  font: 14px/17px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 10px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p2 {
  font: 11px/14px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 20px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
  font: 15px/25px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  text-decoration: none;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label i {
  margin-left: 7px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form #my-file {
  display: none;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-cont {
  width: calc(100% - 200px);
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab2 h4 {
  font-family: "SFPD Bold";
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #2b2935;
  margin-bottom: 25px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab2 .user-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: -11px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab2 .user-list li {
  list-style: none;
  padding: 0 10px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 20px;
  cursor: pointer;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab2 .user-list li img {
  width: 100%;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 h4 {
  font-family: "SFPD Bold";
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #2b2935;
  margin-bottom: 25px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: -11px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li {
  list-style: none;
  padding: 0 10px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333%;
          flex: 0 0 33.333%;
  max-width: 33.333%;
  margin-bottom: 24px;
  cursor: pointer;
  text-align: center;
  font-family: "SFPD Bold";
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #2b2935;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li span {
  display: block;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li .ball {
  width: 71px;
  height: 71px;
  border-radius: 50%;
  background: red;
  margin: 0 auto 20px;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li img {
  width: 100%;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.orange .ball {
  background: #e6562f;
  -webkit-filter: drop-shadow(-2px 4px 20px rgba(230, 86, 47, 0.3));
  filter: drop-shadow(-2px 4px 20px rgba(230, 86, 47, 0.3));
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.orange span {
  color: #e6562f;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.green .ball {
  background: #77b53c;
  -webkit-filter: drop-shadow(-2px 4px 20px rgba(119, 181, 60, 0.3));
  filter: drop-shadow(-2px 4px 20px rgba(119, 181, 60, 0.3));
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.green span {
  color: #77b53c;
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.black .ball {
  background: #2b2935;
  -webkit-filter: drop-shadow(-2px 4px 20px rgba(43, 41, 53, 0.3));
  filter: drop-shadow(-2px 4px 20px rgba(43, 41, 53, 0.3));
}

.mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li.black span {
  color: #2b2935;
}

.mid-gate-line .gate-tool .g-tool:last-child:after {
  display: none;
}

.balance-line {
  padding: 20px 0;
  background: #fff;
  margin-bottom: 40px;
}

.balance-line > .row {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.balance-line .in {
  display: inline-block;
  float: left;
  padding: 0 30px;
  position: relative;
}

.balance-line .in:after {
  content: "";
  height: calc(100%);
  width: 1px;
  background: #707070;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
}

.balance-line .in:first-child:after, .balance-line .in:last-child:after {
  display: none;
}

.balance-line .in h5 {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

.balance-line .in h5 span {
  color: #e6562f;
}

.balance-line .in h3 {
  font: 28px/34px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

.balance-line .in .date-btn {
  height: 60px;
  width: 230px;
  border: 1px solid #2b2935;
  border-radius: 4px;
  background: #fff;
  font: 20px/27px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-right: -30px;
}

.balance-line .in .date-btn i {
  margin: 0 5px;
}

.transactions {
  margin-bottom: 40px;
  padding-right: 30px;
}

.transactions .t-list {
  margin-bottom: 0;
}

.transactions .t-list li {
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  padding: 20px;
  list-style: none;
  margin-bottom: 13px;
  position: relative;
}

.transactions .t-list li i {
  position: absolute;
  font-size: 30px;
  right: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  color: #fff;
}

.transactions .t-list li h5 {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

.transactions .t-list li h5 span {
  color: #e6562f;
}

.transactions .t-list li h3 {
  font: 22px/34px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

.transactions .t-list li.active {
  background: #77b53c;
}

.transactions .t-list li.active h5, .transactions .t-list li.active h3 {
  color: #fff;
}

.transactions .t-list li:last-child {
  margin-bottom: 0;
}

.transactions .img-box {
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  position: relative;
}

.transactions .img-box .mi {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mid-gate-line.w-boxes {
  margin-top: 0;
}

.mid-gate-line.w-boxes .gate-tool h3 span {
  color: #e6562f;
}

.mid-gate-line.w-boxes .img-box {
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  position: relative;
  height: 336px;
}

.mid-gate-line.w-boxes .img-box .mi {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 80%;
}

.footer {
  padding: 20px 30px;
  background: #fff;
}

.footer p {
  font: 14px/16px "SFPD Bold";
  margin: 0;
}

.footer p a {
  color: #e6562f;
}

@media (min-width: 1200px) {
  #activation-modal.modal-dialog {
    max-width: 1160px;
    margin: 1.75rem auto;
  }

  .ctm-modal.modal-dialog {
    max-width: 1160px;
    margin: 1.75rem auto;
  }
}
#activation-modal .modal-body, .ctm-modal .modal-body {
  padding: 0;
}

#activation-modal .modal-body .lft, .ctm-modal .modal-body .lft {
  padding: 40px 30px;
  background: #f9f9f9;
}

#activation-modal .modal-body .lft h4, .ctm-modal .modal-body .lft h4 {
  font: 30px/36px "SFPD Bold";
  letter-spacing: 0;
  color: #555;
  margin-bottom: 20px;
}

#activation-modal .modal-body .lft p, .ctm-modal .modal-body .lft p {
  font: 16px/19px "SFPD Regular";
  letter-spacing: 0.27px;
  color: #555;
  margin-bottom: 30px;
}

#activation-modal .modal-body .lft #v-pills-tab a,
.ctm-modal .modal-body .lft #v-pills-tab a {
  padding: 18px 30px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

#activation-modal .modal-body .lft #v-pills-tab a .fa,
.ctm-modal .modal-body .lft #v-pills-tab a .fa {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}

#activation-modal .modal-body .lft #v-pills-tab a .fas,
.ctm-modal .modal-body .lft #v-pills-tab a .fas {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #77b53c;
  opacity: 0;
}

#activation-modal .modal-body .lft #v-pills-tab a.active,
.ctm-modal .modal-body .lft #v-pills-tab a.active {
  background: #77b53c;
  color: #fff;
}

#activation-modal .modal-body .lft #v-pills-tab a.compleated,
.ctm-modal .modal-body .lft #v-pills-tab a.compleated {
  background: #fff;
  color: #77b53c;
  padding-left: 50px;
}

#activation-modal .modal-body .lft #v-pills-tab a.compleated .fa,
.ctm-modal .modal-body .lft #v-pills-tab a.compleated .fa {
  color: #77b53c;
}

#activation-modal .modal-body .lft #v-pills-tab a.compleated .fas,
.ctm-modal .modal-body .lft #v-pills-tab a.compleated .fas {
  opacity: 1;
}

#activation-modal .modal-body .lft #v-pills-tab a.deactivate,
.ctm-modal .modal-body .lft #v-pills-tab a.deactivate {
  pointer-events: none !important;
}

#activation-modal .modal-body .lft .sf-box, .ctm-modal .modal-body .lft .sf-box {
  background: #fff;
  border: 1px solid #77b53c;
  border-radius: 4px;
  padding: 15px 30px;
}

#activation-modal .modal-body .lft .sf-box h5,
.ctm-modal .modal-body .lft .sf-box h5 {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

#activation-modal .modal-body .lft .sf-box p,
.ctm-modal .modal-body .lft .sf-box p {
  font: 10px/12px "SFPD Bold";
  letter-spacing: 0;
  color: #747474;
  margin-bottom: 5px;
}

#activation-modal .modal-body .lft .sf-box p span,
.ctm-modal .modal-body .lft .sf-box p span {
  color: #2b2935;
  display: inline-block;
  width: 90px;
}

#activation-modal .modal-body .lft .sf-box p.ptp,
.ctm-modal .modal-body .lft .sf-box p.ptp {
  font: 15px/18px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-top: 10px;
  margin-bottom: 3px;
}

#activation-modal .modal-body .lft .sf-box p.am,
.ctm-modal .modal-body .lft .sf-box p.am {
  font: 900 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  margin-bottom: 0;
}

#activation-modal .modal-body .lft .nxt-btn,
.ctm-modal .modal-body .lft .nxt-btn {
  padding: 16px 30px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#activation-modal .modal-body .lft .ext-btn,
.ctm-modal .modal-body .lft .ext-btn {
  padding: 16px 30px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #e6562f;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#activation-modal .modal-body .lft a.help, .ctm-modal .modal-body .lft a.help {
  font: 700 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 20px;
}

#activation-modal .modal-body .rht, .ctm-modal .modal-body .rht {
  padding: 40px;
}

#activation-modal .modal-body .rht .t-in .sp-box,
.ctm-modal .modal-body .rht .t-in .sp-box {
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 10px;
  border-radius: 10px;
  padding: 30px 50px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0);
}

#activation-modal .modal-body .rht .t-in .sp-box .s-name,
.ctm-modal .modal-body .rht .t-in .sp-box .s-name {
  background: #2b2935;
  text-align: center;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #fff;
  padding: 25px;
  margin-bottom: 25px;
}

#activation-modal .modal-body .rht .t-in .sp-box p,
.ctm-modal .modal-body .rht .t-in .sp-box p {
  text-align: center;
  font: 18px/30px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 12px;
}

#activation-modal .modal-body .rht .t-in .sp-box h4.fee,
.ctm-modal .modal-body .rht .t-in .sp-box h4.fee {
  font: 25px/30px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

#activation-modal .modal-body .rht .t-in .sp-box .line,
.ctm-modal .modal-body .rht .t-in .sp-box .line {
  width: 100%;
  height: 1px;
  margin: 18px 0 15px;
  background: #747474;
  opacity: 0.5;
}

#activation-modal .modal-body .rht .t-in .sp-box .slct,
.ctm-modal .modal-body .rht .t-in .sp-box .slct {
  position: relative;
}

#activation-modal .modal-body .rht .t-in .sp-box .slct:after,
.ctm-modal .modal-body .rht .t-in .sp-box .slct:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 40px;
  top: 20px;
  color: #2b2935;
  z-index: 3;
  font-weight: 900;
}

#activation-modal .modal-body .rht .t-in .sp-box select,
.ctm-modal .modal-body .rht .t-in .sp-box select {
  background: #fff;
  border: 1px solid #747474;
  border-radius: 6px;
  width: 200px;
  height: 58px;
  padding: 0 25px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 10px;
}

#activation-modal .modal-body .rht .t-in .sp-box p.fp,
.ctm-modal .modal-body .rht .t-in .sp-box p.fp {
  font: 14px/20px "SFPD Bold";
  padding: 0 15px;
}

#activation-modal .modal-body .rht .t-in .sp-box p.fp span,
.ctm-modal .modal-body .rht .t-in .sp-box p.fp span {
  color: #e6562f;
}

#activation-modal .modal-body .rht .t-in .sp-box.selected,
.ctm-modal .modal-body .rht .t-in .sp-box.selected {
  -webkit-box-shadow: -2px 4px 20px #e6562f1a;
          box-shadow: -2px 4px 20px #e6562f1a;
  border: 2px solid #e6562f33;
}

#activation-modal .modal-body .rht .t-in .sp-bot,
.ctm-modal .modal-body .rht .t-in .sp-bot {
  margin-top: 30px;
}

#activation-modal .modal-body .rht .t-in .sp-bot p,
.ctm-modal .modal-body .rht .t-in .sp-bot p {
  text-align: center;
  font: 18px/21px "SFPD Bold";
  color: #555;
}

#activation-modal .modal-body .rht .t-in .sp-bot p a,
.ctm-modal .modal-body .rht .t-in .sp-bot p a {
  color: #e6562f;
}

#activation-modal .modal-body .rht .administrator-information h3,
.ctm-modal .modal-body .rht .administrator-information h3 {
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #555;
  margin-bottom: 30px;
}

#activation-modal .modal-body .rht .administrator-information h3.ht2,
.ctm-modal .modal-body .rht .administrator-information h3.ht2 {
  margin-top: 20px;
}

#activation-modal .modal-body .rht .administrator-information label,
.ctm-modal .modal-body .rht .administrator-information label {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  opacity: 0.7;
}

#activation-modal .modal-body .rht .administrator-information .form-control,
.ctm-modal .modal-body .rht .administrator-information .form-control {
  height: 58px;
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 0 25px;
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 16px/18px "SFPD Regular";
}

#activation-modal .modal-body .rht .administrator-information select,
.ctm-modal .modal-body .rht .administrator-information select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#activation-modal .modal-body .rht .administrator-information .slct,
.ctm-modal .modal-body .rht .administrator-information .slct {
  position: relative;
}

#activation-modal .modal-body .rht .administrator-information .slct:after,
.ctm-modal .modal-body .rht .administrator-information .slct:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 25px;
  bottom: 22px;
  color: #747474;
  z-index: 3;
  font-weight: 900;
}

#activation-modal .modal-body .rht .administrator-information .db-row,
.ctm-modal .modal-body .rht .administrator-information .db-row {
  margin: 0 -5px;
}

#activation-modal .modal-body .rht .administrator-information .db-row .col-md-4,
.ctm-modal .modal-body .rht .administrator-information .db-row .col-md-4 {
  padding: 0 5px;
}

#activation-modal .modal-body .rht .administrator-information .db-row .col-md-4 .slct:after,
.ctm-modal .modal-body .rht .administrator-information .db-row .col-md-4 .slct:after {
  right: 15px;
  color: #747474;
}

#activation-modal .modal-body .rht .administrator-information .db-row .col-md-4 .form-control,
.ctm-modal .modal-body .rht .administrator-information .db-row .col-md-4 .form-control {
  padding: 0 15px;
  font: 14px/14px "SFPD Regular";
  color: #747474;
}

#activation-modal .modal-body .rht .administrator-information .sm-fg,
.ctm-modal .modal-body .rht .administrator-information .sm-fg {
  padding-left: 60px;
  position: relative;
}

#activation-modal .modal-body .rht .administrator-information .sm-fg .form-control,
.ctm-modal .modal-body .rht .administrator-information .sm-fg .form-control {
  height: 42px;
}

#activation-modal .modal-body .rht .administrator-information .sm-fg .cir,
.ctm-modal .modal-body .rht .administrator-information .sm-fg .cir {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 1px;
  background: #3b5998;
  border-radius: 50%;
}

#activation-modal .modal-body .rht .administrator-information .sm-fg .cir i,
.ctm-modal .modal-body .rht .administrator-information .sm-fg .cir i {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-size: 25px;
}

#activation-modal .modal-body .rht .administrator-information .sm-fg .cir.ins,
.ctm-modal .modal-body .rht .administrator-information .sm-fg .cir.ins {
  background: #6a453b;
}

#activation-modal .modal-body .rht .pay-opts .p-us,
.ctm-modal .modal-body .rht .pay-opts .p-us {
  font: 22px/26px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  text-align: center;
  margin: 40px 0 30px;
}

#activation-modal .modal-body .rht .pay-opts .nav-tabs,
.ctm-modal .modal-body .rht .pay-opts .nav-tabs {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 0;
}

#activation-modal .modal-body .rht .pay-opts .nav-tabs .nav-link,
.ctm-modal .modal-body .rht .pay-opts .nav-tabs .nav-link {
  padding: 18px 30px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 0;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-box-shadow: -2px 4px 6px #00000012;
          box-shadow: -2px 4px 6px #00000012;
  width: calc(50% - 15px);
  border: 0;
  text-align: center;
}

#activation-modal .modal-body .rht .pay-opts .nav-tabs .nav-link.active,
.ctm-modal .modal-body .rht .pay-opts .nav-tabs .nav-link.active {
  background: #77b53c;
  color: #fff;
}

#activation-modal .modal-body .rht .pay-opts .pcond,
.ctm-modal .modal-body .rht .pay-opts .pcond {
  padding-left: 50px;
}

#activation-modal .modal-body .rht .pay-opts .pcond p,
.ctm-modal .modal-body .rht .pay-opts .pcond p {
  font: 16px/19px "SFPD Regular";
  letter-spacing: 0.27px;
  color: #555;
  margin-bottom: 15px;
}

#activation-modal .modal-body .rht .pay-opts .pcond p a,
.ctm-modal .modal-body .rht .pay-opts .pcond p a {
  color: #e6562f;
  text-decoration: none;
}

#activation-modal .modal-body .rht .pay-opts .pcnt1,
.ctm-modal .modal-body .rht .pay-opts .pcnt1 {
  margin-top: 20px;
}

#activation-modal .modal-body .rht .pay-opts .sub-btn,
.ctm-modal .modal-body .rht .pay-opts .sub-btn {
  padding: 15px 30px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#activation-modal .modal-body .rht .pay-opts .fm .fm-in,
.ctm-modal .modal-body .rht .pay-opts .fm .fm-in {
  margin-top: 30px;
  border-top: 1px solid #747474;
}

#activation-modal .modal-body .rht .pay-opts .fm .fm-in .clist,
.ctm-modal .modal-body .rht .pay-opts .fm .fm-in .clist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 70px;
}

#activation-modal .modal-body .rht .pay-opts .fm .fm-in .clist li,
.ctm-modal .modal-body .rht .pay-opts .fm .fm-in .clist li {
  max-width: 20%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  margin-bottom: 20px;
  list-style: none;
}

#activation-modal .modal-body .rht .offline-pay h4,
.ctm-modal .modal-body .rht .offline-pay h4 {
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 20px;
}

#activation-modal .modal-body .rht .offline-pay p,
.ctm-modal .modal-body .rht .offline-pay p {
  font: 17px/25px "SFPD Regular";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 10px;
}

#activation-modal .modal-body .rht .offline-pay p b,
.ctm-modal .modal-body .rht .offline-pay p b {
  font-family: "SFPD Bold";
}

#activation-modal .modal-body .rht .offline-pay p a,
.ctm-modal .modal-body .rht .offline-pay p a {
  color: #e6562f;
}

#activation-modal .modal-body .rht .offline-pay h3,
.ctm-modal .modal-body .rht .offline-pay h3 {
  font: 30px/25px "SFPD Bold";
  letter-spacing: 0.5px;
  color: #2b2935;
}

#activation-modal .modal-body .rht .offline-pay .oft,
.ctm-modal .modal-body .rht .offline-pay .oft {
  margin-top: 40px;
}

#activation-modal .modal-body .rht .offline-pay .b-list,
.ctm-modal .modal-body .rht .offline-pay .b-list {
  margin: 20px 0 0;
}

#activation-modal .modal-body .rht .offline-pay .b-list li,
.ctm-modal .modal-body .rht .offline-pay .b-list li {
  list-style: none;
  font: 16px/22px "SFPD Regular";
  letter-spacing: 0.27px;
  color: #2b2935;
}

#activation-modal .modal-body .rht .offline-pay .b-list li span,
.ctm-modal .modal-body .rht .offline-pay .b-list li span {
  font-family: "SFPD Bold";
  width: 100px;
  display: inline-block;
  margin-bottom: 3px;
}

#activation-modal .modal-body .rht .offline-pay .upload,
.ctm-modal .modal-body .rht .offline-pay .upload {
  width: 100%;
  height: 134px;
  background: #f1f5f8;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  cursor: pointer;
}

#activation-modal .modal-body .rht .offline-pay .upload img,
.ctm-modal .modal-body .rht .offline-pay .upload img {
  width: 70%;
}

.cont {
  display: block;
  position: absolute;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  left: 10px;
  top: 0;
  width: 35px;
  opacity: 1 !important;
}

/* Hide the browser's default checkbox */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 5px;
  height: 17px;
  width: 17px;
  background-color: transparent;
  border: 1px solid #becad6;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cont input:checked ~ .checkmark {
  background-color: #77b53c;
  border: 1px solid #77b53c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cont .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#main-content.dashboard-live-mode .top-line .sandbox {
  background: #77b53c;
}

#main-content.dashboard-live-mode .top-line p {
  text-align: right;
  padding-right: 355px;
  position: relative;
}

#main-content.dashboard-live-mode .top-line p:after {
  content: "";
  height: 32px;
  width: 1px;
  background: #2b2935;
  position: absolute;
  right: 312px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#main-content.dashboard-live-mode .top-line p span.lt {
  float: left;
}

#main-content.dashboard-live-mode .top-line p span.lt .name {
  color: #e6562f;
  font-family: "SFPD Bold";
}

/* table */
table.transactions-table tr {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-align: left;
  color: #333;
}

table.transactions-table tr.highlight {
  background-color: #dcf1f0 !important;
}

table.transactions-table.zebra tr:nth-child(even) {
  background-color: #f6f6f6;
}

table.transactions-table th {
  font-weight: bold;
  padding: 0.35em;
  font-size: 0.9em;
}

table.transactions-table td {
  padding: 0.35em;
  font-size: 0.9em;
}

table.transactions-table .highlight td {
  font-weight: bold;
}

table.transactions-table th.number, td.number {
  text-align: right;
}

/* media queries */
@media screen and (max-width: 600px) {
  .rg-container {
    max-width: 600px;
    margin: 0 auto;
  }

  table.transactions-table {
    width: 100%;
  }

  table.transactions-table tr.hide-mobile,
table.transactions-table th.hide-mobile,
table.transactions-table td.hide-mobile {
    display: none;
  }

  table.transactions-table thead {
    display: none;
  }

  table.transactions-table tbody {
    width: 100%;
  }

  table.transactions-table tr, table.transactions-table th,
table.transactions-table td {
    display: block;
    padding: 0;
  }

  table.transactions-table tr {
    border-bottom: none;
    margin: 0 0 1em 0;
    padding: 0.5em;
  }

  table.transactions-table tr.highlight {
    background-color: inherit !important;
  }

  table.transactions-table.zebra tr:nth-child(even) {
    background-color: transparent;
  }

  table.transactions-table.zebra td:nth-child(even) {
    background-color: #f6f6f6;
  }

  table.transactions-table tr:nth-child(even) {
    background-color: transparent;
  }

  table.transactions-table td {
    padding: 0.5em 0 0.25em 0;
    border-bottom: 1px dotted #ccc;
    text-align: right;
  }

  table.transactions-table td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    display: inline-block;
    content: attr(data-title);
    float: left;
    margin-right: 0.5em;
    font-size: 0.95em;
  }

  table.transactions-table td:last-child {
    padding-right: 0;
    border-bottom: 2px solid #ccc;
  }

  table.transactions-table td:empty {
    display: none;
  }

  table.transactions-table .highlight td {
    background-color: inherit;
    font-weight: normal;
  }
}
table.transactions-table {
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

table.transactions-table thead th {
  background: #2b2935;
  font: 400 14px/16px "SFPD Bold";
  color: #fff;
  padding: 20px;
  border: 0;
}

table.transactions-table tbody tr {
  border: 0;
  background-color: #fff;
}

table.transactions-table tbody tr th, table.transactions-table tbody tr td {
  font: 400 14px/16px "SFPD Bold";
  color: #2b2935;
  padding: 20px;
  border: 0;
  vertical-align: middle;
}

table.transactions-table tbody tr td .dot {
  margin-right: 5px;
}

table.transactions-table tbody tr th .dot.green,
table.transactions-table tbody tr td .dot.green {
  color: #77b53c;
}

table.transactions-table tbody tr th .dot.yellow,
table.transactions-table tbody tr td .dot.yellow, .dot.yellow {
  color: #ffcd29;
}

table.transactions-table tbody tr th .dot.red,
table.transactions-table tbody tr td .dot.red, .dot.red {
  color: #e6562f;
}

table.transactions-table tbody tr th .dot.blue,
table.transactions-table tbody tr td .dot.blue, .dot.blue {
  color: #2fbae6;
}

table.transactions-table tbody tr th .dot.green,
table.transactions-table tbody tr td .dot.green, .dot.green {
  color: #77b53c;
}

table.transactions-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

table.transactions-table tbody tr td .discover-card {
  margin-right: 5px;
  color: #084c90;
}

table.transactions-table tbody tr td .amex-card {
  margin-right: 5px;
  color: #016cca;
}

table.transactions-table tbody tr td .visa-card {
  margin-right: 5px;
  color: #1a1f71;
}

#main-content .notification-bar {
  padding: 0;
}

#main-content .notification-bar .nb-in {
  padding: 30px;
  background: #77b53c;
  position: relative;
}

#main-content .notification-bar p {
  font: 18px/24px "SFPD Bold";
  letter-spacing: 0;
  color: #fff;
  margin-bottom: 0;
}

#main-content .notification-bar p span.underlined {
  text-decoration: underline;
}

#main-content .notification-bar .colps-btn {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  background: transparent;
  border: 0;
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #fff;
  outline: 0;
}

#main-content .notification-bar .colps-btn i {
  margin-left: 3px;
}

.transaction-top {
  margin: 40px 0 20px;
}

.transaction-top .nav-tabs {
  margin: 0;
  border: 0;
  padding: 0 15px;
}

.transaction-top .nav-tabs .nav-item {
  margin-right: 20px;
}

.transaction-top .nav-tabs .nav-item .nav-link {
  padding: 20px;
  background: #fff;
  border: 0;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  min-width: 12.5vw;
}

.transaction-top .nav-tabs .nav-item .nav-link .tt {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  display: block;
  margin-bottom: 5px;
}

.transaction-top .nav-tabs .nav-item .nav-link .amt {
  font: 25px/34px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

.transaction-top .nav-tabs .nav-item .nav-link.active {
  background: #77b53c;
}

.transaction-top .nav-tabs .nav-item .nav-link.active .tt {
  color: #fff;
}

.transaction-top .nav-tabs .nav-item .nav-link.active .amt {
  color: #fff;
}

.transaction-top .tab-content .tt-ttp {
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  padding: 10px 0 15px;
  margin: 20px 0 25px;
}

.transaction-top .tab-content .tt-form {
  padding: 0;
}

.transaction-top .tab-content .tt-form > .row {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.transaction-top .tab-content .tt-form .form-group {
  width: 20%;
  padding: 0 15px;
  margin: 0;
}

.transaction-top .tab-content .tt-form .form-group label {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
}

.transaction-top .tab-content .tt-form .form-group .form-control {
  height: 45px;
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 0 25px;
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 14px/18px "SFPD Regular";
}

.transaction-top .tab-content .tt-form .form-group .slct {
  position: relative;
}

.transaction-top .tab-content .tt-form .form-group .slct i {
  position: absolute;
  left: 20px;
  top: 14px;
}

.transaction-top .tab-content .tt-form .form-group .slct:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 20px;
  top: 14px;
  color: #2b2935;
  z-index: 3;
  font-weight: 900;
}

.transaction-top .tab-content .tt-form .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.transaction-top .tab-content .tt-form .form-group .srch-btn {
  padding: 11px 30px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 0;
  font: 16px/19px "SFPD Bold";
  letter-spacing: 0.3px;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  outline: 0;
  transition: all 0.35s;
  color: #2b2935;
  border: 2px solid #2b2935;
}

.transaction-top .tab-content .tt-form .form-group .srch-btn:hover {
  background: #2b2935;
  color: #fff;
}

.transaction-top .tab-content .tt-form .form-group .srch-btn.clear-btn {
  color: #e6562f;
  border: 2px solid #e6562f;
}

.transaction-top .tab-content .tt-form .form-group .srch-btn.clear-btn:hover {
  background: #e6562f;
  color: #fff;
}

.transaction-top .tab-content .tr-form {
  padding: 0;
  padding-left: 80px;
}

.transaction-top .tab-content .tr-form > .row {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.transaction-top .tab-content .tr-form .form-group {
  width: calc((100% - 65px) / 2);
  padding: 0 15px;
  margin: 0;
}

.transaction-top .tab-content .tr-form .form-group:last-child {
  width: 65px;
}

.transaction-top .tab-content .tr-form .form-group label {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
}

.transaction-top .tab-content .tr-form .form-group .form-control {
  height: 45px;
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 0 25px;
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 14px/18px "SFPD Regular";
}

.transaction-top .tab-content .tr-form .form-group .slct {
  position: relative;
}

.transaction-top .tab-content .tr-form .form-group .slct i {
  position: absolute;
  left: 10px;
  top: 18px;
  font-size: 10px;
}

.transaction-top .tab-content .tr-form .form-group .slct:after {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 10px;
  top: 14px;
  color: #2b2935;
  z-index: 3;
  font-weight: 900;
}

.transaction-top .tab-content .tr-form .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.transaction-top .tab-content .tr-form .form-group .dwn-btn {
  width: 30px;
  font-size: 30px;
  border: 0;
  background: transparent;
  outline: 0;
  color: #707070;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.transaction-top .tab-content .tr-form .form-group .dwn-btn:hover {
  color: #000;
}

.dot-drop {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  line-height: 16px;
  padding-top: 0;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.dot-drop:focus, .dot-drop:hover {
  color: #e6562f;
}

.dot-drop:after {
  display: none;
}

.table-drop {
  -webkit-transform: none !important;
  transform: none !important;
  left: auto !important;
  right: 50px !important;
  top: -6px !important;
  background: #fff;
  -webkit-box-shadow: -2px 4px 20px #00000029;
          box-shadow: -2px 4px 20px #00000029;
  border: 0;
}

.table-drop.it-3 {
  min-width: 120px;
}

.table-drop .dropdown-item {
  display: inline-block;
  float: left;
  width: 40px;
  padding: 0;
  clear: none;
  text-align: center;
  color: #747474;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  background: transparent;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.table-drop .dropdown-item:hover {
  color: #e6562f;
}

.modal-type-2 .tdh {
  text-align: left;
  font: 30px/36px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 30px;
}

.modal-type-2 .lft .tdm-in {
  padding: 0 0 30px;
}

.modal-type-2 .lft .tdm-in .tdh {
  margin-bottom: 20px;
}

.modal-type-2 .lft .tdm-in h6 {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
}

.modal-type-2 .lft .tdm-in h5.sh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
}

.modal-type-2 .lft .tdm-in .i-list {
  margin-bottom: 20px;
}

.modal-type-2 .lft .tdm-in .i-list li {
  font: 16px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  padding-left: 25px;
}

.modal-type-2 .lft .tdm-in .i-list .fa, .modal-type-2 .lft .tdm-in .i-list .far,
.modal-type-2 .lft .tdm-in .i-list .fas {
  position: absolute;
  left: 0;
  top: 2px;
}

.modal-type-2 .rht .tt-icns {
  margin-bottom: 20px;
}

.modal-type-2 .rht .tt-icns .icon-box {
  height: 150.5px;
  background: #fff;
  border: 5px solid #77b53c;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin-bottom: 20px;
}

.modal-type-2 .rht .tt-icns p {
  text-align: center;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  opacity: 0.7;
  margin-bottom: 5px;
}

.modal-type-2 .rht .tt-icns p.sp {
  font: 12px/14px "SFPD Regular";
  padding: 0 10px;
}

.modal-type-2 .rht .tt-icns p.sp span {
  color: #e6562f;
}

.modal-type-2 .rht .details-box .dbx-in {
  background: #fff;
  border: 0.5px solid #77b53c;
  border-radius: 4px;
  padding: 0 15px 30px;
  text-align: center;
}

.modal-type-2 .rht .details-box .dbx-in h3 {
  background: #77b53c;
  padding: 15px 0;
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #fff;
  text-align: center;
  margin: 0 -15px 30px;
}

.modal-type-2 .rht .details-box .dbx-in h6 {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  opacity: 0.7;
}

.modal-type-2 .rht .details-box .dbx-in h5.sh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
}

.modal-type-2 .rht .details-box .dbx-in h5.sh:nth-last-of-type(1) {
  margin-bottom: 0;
}

.modal-type-2 .rht .details-box.dbx-full {
  margin-top: 35px;
}

.modal-type-2 .rht .details-box.dbx-full .dbx-in {
  padding: 0;
}

.modal-type-2 .rht .details-box.dbx-full .dbx-in h3 {
  margin: 0;
}

.modal-type-2 .rht .details-box.dbx-full .dbx-in button {
  background: transparent;
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.modal-type-2 .rht .details-box.dbx-full table.transactions-table tbody tr th,
.modal-type-2 .rht .details-box.dbx-full table.transactions-table tbody tr td {
  font: 400 12px/16px "SFPD Bold";
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in {
  text-align: left;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in h3 {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .lgo {
  margin-bottom: 20px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht h5 {
  font: 10px/12px "SFPD Bold";
  letter-spacing: 0.13px;
  color: #292926;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list {
  margin-bottom: 20px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list li {
  font: 10px/12px "SFPD Regular";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  padding-left: 20px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list .fa,
.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list .far,
.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list .fas {
  position: absolute;
  left: 0;
  top: 2px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs {
  text-align: right;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs p {
  font: 10px/12px "SFPD Bold";
  letter-spacing: 0.13px;
  color: #292926;
  margin-bottom: 10px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs h4 {
  font: 12px/14px "SFPD Bold";
  letter-spacing: 0;
  color: #59595c;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs .p-btn {
  width: 93px;
  height: 28px;
  background: #e6562f;
  border-radius: 4px;
  font: 10px/13px "SFPD Bold";
  letter-spacing: 0.17px;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  margin-top: 8px;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs .p-btn.disabled {
  background: #f9f9f9;
  color: #747474;
  pointer-events: none;
}

table.transactions-table.invoice-table {
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

table.transactions-table.invoice-table thead th {
  background: #59595c;
  font: 10px/10px "SFPD Bold";
  color: #fff;
  padding: 5px 10px;
  border: 0;
  text-transform: uppercase;
}

table.transactions-table.invoice-table thead th:nth-child(4) {
  text-align: right;
}

table.transactions-table.invoice-table thead th:nth-child(5) {
  text-align: right;
}

table.transactions-table.invoice-table tbody tr td {
  font: 10px/10px "SFPD Bold";
  color: #59595c;
  padding: 10px;
}

table.transactions-table.invoice-table tbody tr td:nth-child(4) {
  text-align: right;
}

table.transactions-table.invoice-table tbody tr td:nth-child(5) {
  text-align: right;
}

.rg-container {
  position: relative;
}

.rg-container .tac {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*background: #fff;*/
  /*padding: 15px 10px;*/
  /*height: 120px;*/
  /*overflow: hidden;*/
  /*left: 0;*/
  /*width: 370px;*/
  background: #fff;
  padding: 20px 20px;
  padding-left: 0;
  height: 120px;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #59595c80;
  margin-top: 20px;
}

.rg-container .tac h6 {
  font: 10px/10px "SFPD Bold" !important;
  letter-spacing: 0.13px !important;
  color: #59595c !important;
  opacity: 1 !important;
}

.rg-container .tac p {
  font: 8.8px/10px "SFPD Bold";
  letter-spacing: 0.13px;
  color: #59595c;
  opacity: 0.7;
}

.modal-type-2 .rht .details-box.d-inv-box .dbx-in h3 span.dwn {
  float: right;
}

#activation-modal .modal-body .lft .ext-btn.ext-t2 {
  background: transparent;
  border: 2px solid transparent;
}

.modal-type-2 .rht .tt-icns .icon-box.half-filled {
  border-left: 5px solid white;
}

/*plus-btn*/
#plus-button {
  position: fixed;
  z-index: 999;
  right: 30px;
  bottom: 100px;
  width: 70px;
  height: 70px;
  background: #77b53c;
  -webkit-box-shadow: -2px 4px 20px #00000029;
          box-shadow: -2px 4px 20px #00000029;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#plus-button .fas {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  margin: auto;
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

#plus-button.active {
  background: #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#plus-button.active .fas {
  color: #77b53c;
}

#plus-menu {
  position: fixed;
  z-index: 9999;
  right: -400px;
  bottom: 200px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 350px;
  -webkit-box-shadow: -2px 4px 20px #00000029;
          box-shadow: -2px 4px 20px #00000029;
  border-radius: 10px;
  overflow: hidden;
}

#plus-menu h4 {
  background: #2b2935;
  color: #fff;
  padding: 20px;
  font: 20px/24px "SFPD Bold";
  margin: 0;
}

#plus-menu h4 i {
  margin-right: 10px;
}

#plus-menu .pm-in {
  padding: 5px 20px;
  background: #fff;
}

#plus-menu .pm-in ul {
  margin: 0;
}

#plus-menu .pm-in ul li {
  position: relative;
  padding: 20px 20px 20px 70px;
  border-bottom: 1px solid #747474;
  list-style: none;
  margin: 0;
  cursor: pointer;
}

#plus-menu .pm-in ul li:last-child {
  border-bottom: 0;
}

#plus-menu .pm-in ul li .icn {
  position: absolute;
  left: 0;
  top: 15px;
  width: 50px;
  height: 50px;
  background: #77b53c;
  border-radius: 50%;
}

#plus-menu .pm-in ul li .icn .fas {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  margin: auto;
  text-align: center;
}

#plus-menu .pm-in ul li p.tp {
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

#plus-menu .pm-in ul li p.bp {
  font: 12px/20px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

#plus-menu.active {
  right: 30px;
}

.cup {
  cursor: pointer;
}

#activation-modal .modal-body .lft.cd-frm .form-group,
.ctm-modal .modal-body .lft.cd-frm .form-group {
  position: relative;
  /* For IE10 */
}

#activation-modal .modal-body .lft.cd-frm .form-group .icn,
.ctm-modal .modal-body .lft.cd-frm .form-group .icn {
  position: absolute;
  color: #59595c;
  background: transparent;
  right: 0;
  top: 50%;
  border: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#activation-modal .modal-body .lft.cd-frm .form-group select,
.ctm-modal .modal-body .lft.cd-frm .form-group select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

#activation-modal .modal-body .lft.cd-frm .form-group select::-ms-expand,
.ctm-modal .modal-body .lft.cd-frm .form-group select::-ms-expand {
  display: none;
}

#activation-modal .modal-body .lft.cd-frm .form-control,
.ctm-modal .modal-body .lft.cd-frm .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  padding-left: 0;
}

#activation-modal .modal-body .lft.cd-frm ::-webkit-input-placeholder,
.ctm-modal .modal-body .lft.cd-frm ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm :-ms-input-placeholder,
.ctm-modal .modal-body .lft.cd-frm :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm ::-ms-input-placeholder,
.ctm-modal .modal-body .lft.cd-frm ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm ::-webkit-input-placeholder, .ctm-modal .modal-body .lft.cd-frm ::-webkit-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm ::-moz-placeholder, .ctm-modal .modal-body .lft.cd-frm ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm :-ms-input-placeholder, .ctm-modal .modal-body .lft.cd-frm :-ms-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm ::-ms-input-placeholder, .ctm-modal .modal-body .lft.cd-frm ::-ms-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .lft.cd-frm ::placeholder,
.ctm-modal .modal-body .lft.cd-frm ::placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp .form-control,
.ctm-modal .modal-body .rht.cd-il .stmp .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  padding-left: 0;
}

#activation-modal .modal-body .rht.cd-il .details-box .dbx-in,
.ctm-modal .modal-body .rht.cd-il .details-box .dbx-in {
  border: 0.5px solid #59595c;
  padding: 0;
  overflow: hidden;
}

#activation-modal .modal-body .rht.cd-il .add-tr,
.ctm-modal .modal-body .rht.cd-il .add-tr {
  cursor: pointer;
}

#activation-modal .modal-body .rht.cd-il .add-tr td:nth-child(3),
.ctm-modal .modal-body .rht.cd-il .add-tr td:nth-child(3) {
  color: #c3c6d1;
}

#activation-modal .modal-body .rht.cd-il .add-tr td:nth-child(4),
.ctm-modal .modal-body .rht.cd-il .add-tr td:nth-child(4) {
  color: #c3c6d1;
}

#activation-modal .modal-body .rht.cd-il .add-tr td:nth-child(5),
.ctm-modal .modal-body .rht.cd-il .add-tr td:nth-child(5) {
  color: #c3c6d1;
}

#activation-modal .modal-body .rht.cd-il table.transactions-table.invoice-table thead th,
.ctm-modal .modal-body .rht.cd-il table.transactions-table.invoice-table thead th {
  padding: 12px 10px;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot,
.ctm-modal .modal-body .rht.cd-il .stmp-bot {
  margin-top: 30px;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::-webkit-input-placeholder,
.ctm-modal .modal-body .rht.cd-il .stmp-bot ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot :-ms-input-placeholder,
.ctm-modal .modal-body .rht.cd-il .stmp-bot :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::-ms-input-placeholder,
.ctm-modal .modal-body .rht.cd-il .stmp-bot ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::-webkit-input-placeholder, .ctm-modal .modal-body .rht.cd-il .stmp-bot ::-webkit-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::-moz-placeholder, .ctm-modal .modal-body .rht.cd-il .stmp-bot ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot :-ms-input-placeholder, .ctm-modal .modal-body .rht.cd-il .stmp-bot :-ms-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::-ms-input-placeholder, .ctm-modal .modal-body .rht.cd-il .stmp-bot ::-ms-input-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot ::placeholder,
.ctm-modal .modal-body .rht.cd-il .stmp-bot ::placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot p,
.ctm-modal .modal-body .rht.cd-il .stmp-bot p {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 30px;
  opacity: 0.7;
}

#activation-modal .modal-body .rht.cd-il .stmp-bot .form-control,
.ctm-modal .modal-body .rht.cd-il .stmp-bot .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  padding-left: 0;
}

.modal-type-2 .lft .tdm-in h6 {
  opacity: 0.7;
}

.modal-type-2 .lft.cd-frm .tdm-in h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.ancd-mod {
  max-width: 350px;
}

.ancd-mod .modal-content {
  background: #f9f9f9;
}

.ancd-mod .cd-frm {
  padding: 10px 15px;
}

.ancd-mod .cd-frm .tdm-in h6 {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  opacity: 0.7;
}

.ancd-mod .cd-frm .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

.ancd-mod ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2b2935;
}

.ancd-mod :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b2935;
}

.ancd-mod ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b2935;
}

.ancd-mod ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

.ancd-mod ::placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

.ancd-mod .nxt-btn {
  padding: 16px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

.ancd-mod a.help {
  font: 700 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 20px;
}

#activation-modal .modal-body .lft.cd-frm .form-group .icn {
  outline: 0;
}

.colse-mdl {
  width: 35px;
  height: 35px;
  background: #fff;
  -webkit-box-shadow: 0 3px 6px #00000029;
          box-shadow: 0 3px 6px #00000029;
  position: absolute;
  right: -15px;
  top: -15px;
  border-radius: 50%;
  padding: 5px 12px;
  font-weight: 300;
  color: #2b2935;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
}

#create-new-payment-link-model {
  max-width: 350px;
}

#create-new-payment-link-model .modal-content {
  background: #f9f9f9;
}

#create-new-payment-link-model .cd-frm {
  padding: 10px 15px;
}

#create-new-payment-link-model .cd-frm .tdm-in h6 {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  opacity: 0.7;
}

#create-new-payment-link-model .cd-frm .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

#create-new-payment-link-model .form-group {
  position: relative;
}

#create-new-payment-link-model .form-group select.na {
  -moz-appearance: none;
  -webkit-appearance: none;
}

#create-new-payment-link-model .form-group .icn {
  position: absolute;
  color: #59595c;
  background: transparent;
  right: 0;
  top: 50%;
  border: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: 0;
}

#create-new-payment-link-model h5.sh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  margin-top: -10px;
}

#create-new-payment-link-model ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2b2935;
}

#create-new-payment-link-model :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b2935;
}

#create-new-payment-link-model ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b2935;
}

#create-new-payment-link-model ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#create-new-payment-link-model ::placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#create-new-payment-link-model .nxt-btn {
  padding: 16px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#create-new-payment-link-model a.help {
  font: 700 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 20px;
}

#create-new-payment-link-model .mod-cbox {
  margin-bottom: 30px;
}

#create-new-payment-link-model .cont {
  width: 100%;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
}

#create-new-payment-link-model .checkmark {
  border: 1px solid #59595c;
}

#create-new-payment-link-model .cont input:checked ~ .checkmark {
  background-color: #77b53c;
  border: 1px solid #77b53c;
}

#create-new-payment-link-model .ext-btn {
  padding: 16px 30px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #e6562f;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#create-new-payment-link-model .ext-btn.ext-t2 {
  background: transparent;
  border: 2px solid transparent;
}

#create-new-payment-template-modal {
  max-width: 350px;
}

#create-new-payment-template-modal .modal-content {
  background: #f9f9f9;
}

#create-new-payment-template-modal .cd-frm {
  padding: 10px 15px;
}

#create-new-payment-template-modal .cd-frm .tdm-in h6 {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  opacity: 0.7;
}

#create-new-payment-template-modal .cd-frm .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

#create-new-payment-template-modal .form-group {
  position: relative;
}

#create-new-payment-template-modal .form-group select.na {
  -moz-appearance: none;
  -webkit-appearance: none;
}

#create-new-payment-template-modal .form-group .icn {
  position: absolute;
  color: #59595c;
  background: transparent;
  right: 0;
  top: 50%;
  border: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#create-new-payment-template-modal h5.sh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  margin-top: -10px;
}

#create-new-payment-template-modal .bot-h {
  margin-top: 40px !important;
}

#create-new-payment-template-modal ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #2b2935;
}

#create-new-payment-template-modal :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2b2935;
}

#create-new-payment-template-modal ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2b2935;
}

#create-new-payment-template-modal ::-moz-placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#create-new-payment-template-modal ::placeholder {
  /* Most modern browsers support this now. */
  color: #2b2935;
}

#create-new-payment-template-modal .nxt-btn {
  padding: 16px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#create-new-payment-template-modal a.help {
  font: 700 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-top: 20px;
}

#create-new-payment-template-modal .mod-cbox {
  margin-bottom: 30px;
}

#create-new-payment-template-modal .cont {
  width: 100%;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
}

#create-new-payment-template-modal .checkmark {
  border: 1px solid #59595c;
}

#create-new-payment-template-modal .cont input:checked ~ .checkmark {
  background-color: #77b53c;
  border: 1px solid #77b53c;
}

#create-new-payment-template-modal .ext-btn {
  padding: 16px 30px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #e6562f;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

#create-new-payment-template-modal .ext-btn.ext-t2 {
  background: transparent;
  border: 2px solid transparent;
}

#activation-modal.modal-dialog.ac-smb {
  max-width: 960px;
}

#activation-modal-2.modal-dialog.ac-smb {
  max-width: 960px;
}

.modal-body .rht.cl-top h6 {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  opacity: 0.7;
}

.modal-body .rht.cl-top h3.tmh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 25px;
}

.modal-body .rht.cl-top .dbx-in h3 {
  position: relative;
}

.modal-body .rht.cl-top .dbx-in h3 i {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.modal-body .rht.cl-top .bt2l {
  text-align: right;
}

.modal-body .rht.cl-top .bt2l .bty2 {
  display: inline-block;
  font-size: 30px;
  background: transparent;
  border: 0;
  color: #707070;
  margin-left: 20px;
  outline: 0;
}

.transaction-top .tab-content .tt-form .form-group.w50 {
  width: 50%;
}

table.transactions-table tbody tr td.cus-names {
  max-width: 260px;
}

table.transactions-table.invoice-table.iinvt {
  border: 0.5px solid #59595c;
  margin-bottom: 10px;
}

table.transactions-table.invoice-table.iinvt thead th {
  padding: 10px 10px;
}

.transaction-top.reports-top .reports-h h4 {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  padding-left: 15px;
  margin-top: 20px;
}

.transaction-top.reports-top .reports-h h3 {
  font: 28px/34px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  padding-left: 15px;
  margin-bottom: 30px;
}

.transaction-top.reports-top .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

.transaction-top.reports-top .icn {
  position: absolute;
  color: #59595c;
  background: transparent;
  right: 0;
  top: 50%;
  border: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  outline: 0;
}

.transaction-top.reports-top .form-group {
  position: relative;
}

.transaction-top.reports-top h6 {
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  opacity: 0.7;
}

.transaction-top.reports-top .cont {
  width: 100%;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-top: 25px;
}

.transaction-top.reports-top .cont .fas {
  margin-left: 5px;
}

.transaction-top.reports-top .checkmark {
  border: 1px solid #59595c;
}

.transaction-top.reports-top p {
  font: 12px/14px "SFPD Regular";
  letter-spacing: 0;
  color: #747474;
  margin-top: 12px;
  margin-bottom: 0;
}

.transaction-top.reports-top .nxt-btn {
  padding: 16px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

.transaction-top.reports-top .lft {
  border-right: 1px solid #59595c;
}

.transaction-top.reports-top .fas.fa-exchange-alt {
  position: absolute;
  right: 20px;
  bottom: 32px;
  font-size: 14px;
  cursor: pointer;
}

.settings-outer {
  padding: 0 15px;
  margin-top: 20px;
}

.settings-outer .lft-sd {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 385px;
          flex: 0 0 385px;
  max-width: 385px;
  margin-right: 30px;
  padding: 40px 0;
  background: #fff;
}

.settings-outer .lft-sd .lfs-in {
  padding: 0 30px;
}

.settings-outer .lft-sd .nxt-btn {
  padding: 16px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
}

.settings-outer .lft-sd .ext-btn {
  padding: 16px 30px;
  background: transparent;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #e6562f;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid rgba(230, 86, 47, 0);
  outline: 0;
}

.settings-outer .lft-sd img {
  margin: 0 auto;
  display: block;
}

.settings-outer .lft-sd #set-tab1 {
  padding: 0;
}

.settings-outer .lft-sd #set-tab1 .nav-item {
  width: 100%;
  margin: 0;
}

.settings-outer .lft-sd #set-tab1 .nav-item a {
  text-align: center;
  font: 24px/29px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  padding: 28px 10px;
  width: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}

.settings-outer .lft-sd #set-tab1 .nav-item a.active {
  background: rgba(230, 86, 47, 0.2);
}

.settings-outer .rht-sd {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 415px);
          flex: 0 0 calc(100% - 415px);
  max-width: calc(100% - 415px);
  padding: 40px 15px;
  background: #fff;
}

.settings-outer .rht-sd.cur-rht {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.settings-outer .rht-sd .tdh {
  font: 30px/36px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 20px;
}

.settings-outer .rht-sd .sth {
  font: 22px/26px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 30px;
  margin-top: 20px;
}

.settings-outer .rht-sd .sth.mb {
  margin-bottom: 10px;
}

.settings-outer .rht-sd .mp {
  font: 12px/14px "SFPD Regular";
  letter-spacing: 0;
  color: #747474;
}

.settings-outer .rht-sd .sh {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #747474;
}

.settings-outer .rht-sd .mh {
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
}

.settings-outer .rht-sd .soc-in {
  padding-left: 80px;
}

.settings-outer .rht-sd .soc-in img {
  position: absolute;
  left: 15px;
  top: 2px;
}

.settings-outer .rht-sd .soc-in .sh {
  margin-bottom: 0;
}

.settings-outer .rht-sd .soc-in .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

.settings-outer .rht-sd .nxt-btn {
  padding: 10px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 14px/17px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  margin-top: 15px;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
  float: right;
}

.settings-outer .rht-sd .table-drop {
  right: 40px !important;
  min-width: 5rem !important;
}

.settings-outer .rht-sd table.transactions-table thead th {
  background: #77b53c;
}

.ancd-mod .cd-frm .tdm-in .ur-in h6 {
  opacity: 1;
  margin-bottom: 8px;
}

.ancd-mod .cd-frm .tdm-in .ur-in h6 span {
  color: #e6562f;
}

.ancd-mod .cd-frm .tdm-in .ur-in p {
  font: 12.8px/16px "SFPD Regular";
  letter-spacing: 0;
  color: #000;
  opacity: 0.8;
}

/*=====================*/
.checkbox {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.checkbox:after, .checkbox:before {
  font-family: FontAwesome;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  -webkit-font-language-override: normal;
  font-language-override: normal;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
}

.checkbox label {
  width: 24px;
  height: 12px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 0;
}

.checkbox label:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 12px;
  border-radius: 100%;
  left: 0;
  top: -5px;
  z-index: 2;
  background: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.checkbox input:hover + label:after {
  -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}

.checkbox input:checked + label:after {
  left: 40px;
}

.model-11 .txt {
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
}

.model-11 .checkbox label {
  background: #eb6d4a;
  height: 12px;
  border-radius: 0;
  -webkit-box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
}

.model-11 .checkbox label:after {
  background-color: #fff;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr="#FFFFFFFF", endColorstr="#FFFFFFFF");
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48c3RvcCBvZmZzZXQ9IjQ1JSIgc3RvcC1jb2xvcj0iI2RkZGRkZCIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIi8+PHN0b3Agb2Zmc2V0PSI1NSUiIHN0b3AtY29sb3I9IiNkZGRkZGQiLz48c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #fff), color-stop(45%, #ddd), color-stop(50%, #fff), color-stop(55%, #ddd), color-stop(70%, #fff));
  background-image: linear-gradient(to right, #fff 30%, #ddd 45%, #fff 50%, #ddd 55%, #fff 70%);
  border-radius: 1px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  top: 0;
  width: 12px;
  height: 12px;
}

.model-11 .checkbox input:checked + label {
  background: #61b872;
}

.model-11 .checkbox input:checked + label:after {
  left: 12px;
}

p.mp {
  font: 12px/14px "SFPD Regular";
  letter-spacing: 0;
  color: #747474;
}

p.mp b {
  font-family: "SFPD Bold";
  color: #e6562f;
}

.tp.icn {
  outline: 0;
  background: transparent;
  border: 0;
  color: #2b2935;
  font-size: 12px;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  margin-left: 5px;
}

option {
  font-size: 14px !important;
}

.set-fi {
  margin-top: 20px;
}

.set-fi .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 14px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #84757d;
  padding-left: 0;
}

.mod-tbl .cbox {
  width: 12px;
  height: 12px;
  margin: 0 auto;
}

.mod-tbl .cbox .cont {
  padding-left: 0;
  margin-bottom: 0;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
}

.mod-tbl .cbox .cont .checkmark {
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
}

.mod-tbl .cbox .cont .checkmark:after {
  left: 3px;
  top: -1px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) scale(0.7);
  transform: rotate(45deg) scale(0.7);
}

p.mp.mpt2 b {
  color: #2b2935;
}

.set-msg-bd {
  border-top: 1px solid rgba(116, 116, 116, 0.3);
  border-bottom: 1px solid rgba(116, 116, 116, 0.3);
  padding: 15px 0 0;
}

.cont.set-cont {
  width: 100%;
  font: 14px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
}

.set-fi .form-control.tfc {
  font: 12px/21px "SFPD Bold";
  color: #2b2935;
}

:root {
  --dark-blue: #1f2029;
  --dark-light: #353746;
}

.r-section .r-btn {
  width: 268px;
  height: 176px;
  float: left;
  margin-right: 15px;
  margin-top: 20px;
}

.r-section [type=checkbox]:checked, .r-section [type=checkbox]:not(:checked),
.r-section [type=radio]:checked, .r-section [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.r-section .checkbox:checked + label, .r-section .checkbox:not(:checked) + label {
  position: relative;
  width: 70px;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  margin: 17px 0;
  margin-top: 100px;
  height: 6px;
  border-radius: 4px;
  background-image: linear-gradient(298deg, var(--red), var(--yellow));
  z-index: 100 !important;
}

.r-section .checkbox:checked + label:before,
.r-section .checkbox:not(:checked) + label:before {
  position: absolute;
  font-family: "unicons";
  cursor: pointer;
  top: -17px;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.r-section .checkbox:not(:checked) + label:before {
  content: "";
  left: 0;
  color: var(--grey);
  background-color: var(--dark-light);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(26, 53, 71, 0.07);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.r-section .checkbox:checked + label:before {
  content: "";
  left: 30px;
  color: var(--yellow);
  background-color: var(--dark-blue);
  -webkit-box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
          box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
}

.r-section .checkbox:checked ~ .section .container .row .col-12 p {
  color: var(--dark-blue);
}

.r-section .checkbox-tools:checked + label,
.r-section .checkbox-tools:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 auto;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--white);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  width: 268px;
  height: 176px;
  border-radius: 14px;
}

.r-section .checkbox-tools:not(:checked) + label {
  background-color: var(--dark-light);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.r-section .checkbox-tools:checked + label {
  background-color: transparent;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.r-section .checkbox-tools:not(:checked) + label:hover {
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.r-section .checkbox-tools:checked + label::before,
.r-section .checkbox-tools:not(:checked) + label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: linear-gradient(298deg, var(--red), var(--yellow));
  z-index: 1;
}

.r-section .checkbox-tools:not(:checked) + label::before {
  opacity: 0;
}

.r-section .checkbox-tools:checked + label::before {
  opacity: 1;
}

.r-section .checkbox-tools:checked + label .uil,
.r-section .checkbox-tools:not(:checked) + label .uil {
  font-size: 24px;
  line-height: 24px;
  display: block;
  padding-bottom: 10px;
}

.r-section .checkbox:checked ~ .section .container .row .col-12 .checkbox-tools:not(:checked) + label {
  background-color: var(--light);
  color: var(--dark-blue);
  -webkit-box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1x 4px 0 rgba(0, 0, 0, 0.05);
}

.r-section .checkbox-tools:checked + label.visa::before,
.r-section .checkbox-tools:not(:checked) + label.visa::before {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#113d97), to(#256978));
  background: transparent linear-gradient(180deg, #113d97 0%, #256978 100%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.r-section .checkbox-tools:checked + label.master::before,
.r-section .checkbox-tools:not(:checked) + label.master::before {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#eb001b), to(#ef991a));
  background: transparent linear-gradient(180deg, #eb001b 0%, #ef991a 100%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.r-section .checkbox-tools:checked + label.ae::before,
.r-section .checkbox-tools:not(:checked) + label.ae::before {
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#86c4e8), to(#2d73b5));
  background: transparent linear-gradient(180deg, #86c4e8 0%, #2d73b5 100%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.r-section .for-checkbox-tools span {
  display: block;
  position: relative;
  z-index: 3;
}

.r-section .for-checkbox-tools .icn {
  font-size: 40px;
  margin-bottom: 20px;
}

.r-section .for-checkbox-tools .no {
  font: 18px/20px "SFPD Bold";
  margin-bottom: 10px;
}

.r-section .for-checkbox-tools .name {
  font: 12px/14px "SFPD Regular";
  margin-bottom: 15px;
}

.r-section .for-checkbox-tools .date {
  width: 50%;
  float: left;
  font: 14px/20px "SFPD Bold";
}

.r-section .for-checkbox-tools .no2 {
  width: 50%;
  float: left;
  font: 14px/20px "SFPD Bold";
  text-align: right;
}

.r-section .r-btn {
  position: relative;
}

.r-section .r-btn.add-card {
  -webkit-box-shadow: -2px 4px 20px #2b293512;
          box-shadow: -2px 4px 20px #2b293512;
  border-radius: 14px;
  cursor: pointer;
}

.r-section .r-btn .txt {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.r-section .r-btn .txt i {
  display: block;
  color: rgba(116, 116, 116, 0.4);
  font-size: 40px;
  margin-bottom: 10px;
}

.r-section .r-btn .txt span {
  display: block;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  opacity: 0.5;
}

.clist-2 li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

#user-menu {
  position: fixed;
  z-index: 9999;
  left: -400px;
  bottom: 80px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 350px;
  -webkit-box-shadow: -2px 4px 20px #00000029;
          box-shadow: -2px 4px 20px #00000029;
  border-radius: 10px;
  overflow: hidden;
}

#user-menu h4 {
  background: #2b2935;
  color: #fff;
  padding: 20px;
  font: 20px/24px "SFPD Bold";
  margin: 0;
}

#user-menu h4 i {
  margin-right: 10px;
}

#user-menu .pm-in {
  padding: 5px 20px;
  background: #fff;
}

#user-menu .pm-in ul {
  margin: 0;
}

#user-menu .pm-in ul li {
  position: relative;
  padding: 20px 20px 20px 70px;
  border-bottom: 1px solid #747474;
  list-style: none;
  margin: 0;
  cursor: pointer;
}

#user-menu .pm-in ul li a {
  text-decoration: none;
}

#user-menu .pm-in ul li:last-child {
  border-bottom: 0;
}

#user-menu .pm-in ul li .icn {
  position: absolute;
  left: 0;
  top: 15px;
  width: 50px;
  height: 50px;
  background: #77b53c;
  border-radius: 50%;
}

#user-menu .pm-in ul li .icn .fas {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  margin: auto;
  text-align: center;
}

#user-menu .pm-in ul li p.tp {
  font: 16px/20px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

#user-menu .pm-in ul li p.bp {
  font: 12px/20px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
  margin-bottom: 0;
}

#user-menu.active {
  left: 40px;
}

.profile-form-section .form-control {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #59595c;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 18px/21px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b2935;
  margin-bottom: 20px;
  padding-left: 0;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #77b53c !important;
}

.daterangepicker td.in-range {
  background-color: #edf5e5 !important;
}

.daterangepicker td.active {
  color: #2e2e2e !important;
}

.daterangepicker td.end-date {
  background-color: #77b53c !important;
}

.btn-primary, .btn-primary:hover, .btn-primary.disabled, .btn-primary:disabled, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #77b53c;
  border-color: #77b53c;
}

.personalize-db .img-box .recharts-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.personalize-db .img-box .recharts-wrapper .recharts-surface {
  margin: 0 auto;
  display: block;
}

.personalize-db .img-box .recharts-wrapper .recharts-legend-wrapper {
  width: 100% !important;
  left: 0 !important;
  bottom: 10px !important;
  background: #fff;
}

.personalize-db .img-box .recharts-wrapper span.recharts-legend-item-text {
  font: 400 12px/16px "SFPD Bold";
  color: #2b2935;
}

.sct-fg {
  float: right;
  padding-top: 20px;
  margin-right: 15px;
}

.sct-fg label {
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  float: left;
  margin-top: 10px;
  margin-right: 15px;
}

.sct-fg #chart-selector {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #2b2935;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 12px/14px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #2b293b;
  padding-left: 0;
  display: inline-block;
  float: left;
  width: 200px;
}

#chart_lkr .recharts-wrapper {
  margin-left: 15px;
}

#chart_usd .recharts-wrapper {
  margin-left: 15px;
}

#chart_lkr, #chart_usd {
  margin-top: 15px;
}

#chart_lkr .recharts-legend-wrapper, #chart_usd .recharts-legend-wrapper {
  bottom: 15px !important;
}

#chart_lkr .recharts-layer tspan, #chart_usd .recharts-layer tspan {
  fill: #2b2935;
  font-weight: 700;
  font: 10px/16px "SFPD Bold";
  letter-spacing: -0.5px;
}

#chart-selector option {
  font-size: 12px !important;
}

li.recharts-legend-item .recharts-legend-item-text {
  font: 14px/16px "SFPD Regular";
  letter-spacing: 0;
  color: #2b2935;
}

#side-navigation .act-acc p.ns {
  display: none;
}

.personalize-db .recharts-responsive-container {
  margin: 0 auto;
}

.transaction-top .tab-content .tr-form .form-group:last-child .dropdown-toggle {
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}

.transaction-top .tab-content .tr-form .form-group:last-child .dropdown-item {
  font: 14px/20px "SFPD Bold";
  text-align: right;
}

.transaction-top .tab-content .tr-form .form-group:last-child .dropdown-item i {
  margin-right: 7px;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.transaction-top .tab-content .tr-form .form-group:last-child .dropdown-item:hover i {
  color: #e6562f;
}

.transaction-top .nav-tabs .nav-item .nav-link .sep {
  font: 28px/24px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  display: inline-block;
}

.transaction-top .nav-tabs .nav-item .nav-link.active .sep {
  color: #ffffff;
}

.pagination {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.pagination li {
  margin: 0 5px;
}

.pagination > li > a {
  border: 1px solid #e6562f;
  padding: 5px 5px;
  width: 30px;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  font: 400 14px/19px "SFPD Bold";
  color: #2b2935;
  height: 30px;
  display: inline-block;
  text-align: center;
  text-indent: 0px;
}

.pagination li.previous a {
  border: 0;
  width: auto;
}

.pagination li.next a {
  border: 0;
  width: auto;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #e6562f;
  border-color: #e6562f;
  outline: none;
}

.pagination li.disabled > a {
  opacity: 0.7;
}

.transaction-top {
  min-height: 675px;
}

.refund-modal .ancd-mod {
  max-width: 800px;
}

.refund-modal .ancd-mod h1 {
  font: 25px/34px "SFPD Bold";
  letter-spacing: 0;
  color: #2b2935;
  margin-top: 0;
  margin-bottom: 30px;
}

.refund-modal .ancd-mod .modal-body {
  padding: 40px;
  background: #f9f9f9;
}

.refund-modal .ancd-mod .modal-body p {
  margin-top: 0;
  margin-bottom: 12px;
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
  color: #000;
}

.refund-modal .ancd-mod .modal-body p.lst {
  margin-bottom: 20px;
}

.refund-modal .ancd-mod .modal-body p strong {
  margin-right: 7px;
  opacity: 0.7;
}

.refund-modal .ancd-mod .modal-body input.form-control {
  height: 45px;
  border: 1px solid #747474;
  border-radius: 6px;
  padding: 0 15px;
  outline: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  font: 14px/18px "SFPD Regular";
}

.refund-modal .ancd-mod .modal-body button.btn {
  padding: 10px 20px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 0;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0.3px;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 125px;
  margin-top: 0;
  text-transform: uppercase;
  outline: 0;
  transition: all 0.35s;
  color: #e6562f;
  border: 2px solid #e6562f;
  margin-left: 10px;
  float: right;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.refund-modal .ancd-mod .modal-body button.btn.btn-success {
  border-color: #2b2935;
  color: #2b2935;
}

.error {
  color: red;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
}

.refund-modal .ancd-mod .modal-body .error {
  color: red;
  text-align: left;
  font: 14px/16px "SFPD Bold";
  letter-spacing: 0;
}

#activation-modal .modal-body .lft .nxt-btn.anb {
  text-align: center;
  text-decoration: none;
}

.transaction-top .tab-content .tr-form.inv-tr {
  padding-left: 35px;
}

.transaction-top .tab-content .tr-form.inv-tr .srch-btn {
  padding: 11px 30px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 0;
  font: 16px/19px "SFPD Bold";
  letter-spacing: 0.3px;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  outline: 0;
  transition: all 0.35s;
  color: #e6562f;
  border: 2px solid #e6562f;
  -webkit-transform: translateX(-35px);
          transform: translateX(-35px);
}

.transaction-top .tab-content .tr-form.inv-tr .srch-btn:hover {
  background: #e6562f;
  color: #fff;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #007bff00;
}

#activation-modal.payment-link-modal.modal-dialog {
  width: 100%;
  /*max-width: none;*/
}

#activation-modal.payment-link-modal .modal-body .lft {
  padding: 40px;
}

#activation-modal.payment-link-modal #create-new-payment-link-model > .modal-content {
  border: 0;
}

#activation-modal.payment-link-modal #create-new-payment-link-model .cd-frm {
  border: 0;
  padding: 0;
}

#activation-modal.payment-link-modal > .modal-content {
  border: 0;
  -webkit-box-shadow: -2px 4px 20px #00000012;
          box-shadow: -2px 4px 20px #00000012;
  border-radius: 4px;
  overflow: hidden;
}

.rht.gr-rht .col-md-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

.rht.gr-rht .in.table-datepicker .date-btn {
  height: 32px;
  width: 100%;
  border: 1px solid #e6562f;
  border-radius: 4px;
  background: #fff;
  font: 10px/15px "SFPD Bold";
  letter-spacing: 0;
  color: #e6562f;
  margin-top: 10px;
}

.rht.gr-rht a[download] {
  margin-top: 10px;
  display: block;
}

.transaction-top.reports-top .fas.fa-exchange-alt {
  bottom: 24px;
}

.transaction-top.reports-top .nxt-btn {
  padding: 12px 10px;
  background: #e6562f;
  border-radius: 6px;
  margin-bottom: 10px;
  font: 15px/20px "SFPD Bold";
  letter-spacing: 0.3px;
  color: #fff;
  position: relative;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  border: 2px solid #e6562f;
  outline: 0;
  margin-top: 5px;
}

.transaction-top.reports-top .lft {
  border-right: 0;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  float: none;
  text-align: center;
}

.upload-btn-wrapper .btn {
  border: 0;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 5;
  text-indent: -99999px;
}

.msg-d {
  padding: 10px 30px 30px;
  margin: 0 -15px 30px;
  background: #77b53c;
}

.msg-d p {
  font: 14px/18px "SFPD Bold";
  letter-spacing: 0.13px;
  color: #ffffff;
  margin-bottom: 0;
}

.top-line .sandbox {
  text-decoration: none;
}

.sandbox-mode-activated .top-line .sandbox {
  background: #e6562f !important;
}

.sandbox-mode-activated .transaction-top .nav-tabs .nav-item .nav-link.active {
  background: #e6562f;
}

.detailed-invoice-live-view.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht h5 {
  font: 14px/16px "SFPD Bold";
}
.detailed-invoice-live-view.modal-type-2 .rht .details-box.d-inv-box .dbx-in .lht .i-list li {
  font: 12px/14px "SFPD Regular";
  margin-bottom: 7px;
}
.detailed-invoice-live-view.modal-type-2 .rht .details-box.d-inv-box .dbx-in .rhs p {
  font: 14px/12px "SFPD Bold";
  margin-bottom: 10px;
}
.detailed-invoice-live-view table.transactions-table.invoice-table tbody tr td, .detailed-invoice-live-view table.transactions-table.invoice-table thead th {
  font: 12px/14px "SFPD Bold";
  padding: 10px;
}
.detailed-invoice-live-view .rg-container .tac h6 {
  font: 14px/14px "SFPD Bold" !important;
}
.detailed-invoice-live-view .rg-container .tac p {
  font: 12px/16px "SFPD Bold";
}

.p-btn.pnw {
  width: 140px;
  height: 40px;
  background: #e6562f;
  border-radius: 4px;
  font: 12px/14px "SFPD Bold";
  letter-spacing: 0.17px;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  margin-top: 0;
  float: right;
}

.lgo.lg2 {
  margin-bottom: 20px;
}

.detailed-invoice-live-view .logo-outer img {
  margin: 10px auto 30px;
  display: block;
}

/*responsive styles*/
@media (min-width: 768px) and (max-width: 991px) {
  #navigation {
    padding: 15px 20px;
  }

  #navigation .navbar-nav li a {
    font-size: 12.8px;
  }

  #navigation .navbar-brand img, #navigation .navbar-brand svg {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 120px;
  }

  #navigation .navbar-nav li:last-child a img {
    margin-top: -5px;
  }

  #home-slider .carousel-item {
    height: 340px;
  }

  #home-slider .carousel-item .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p {
    font: 500 15px/26px "Poppins", sans-serif;
    padding: 0 45px;
  }

  #middle-main-content .taior-made p:nth-last-of-type(1) {
    margin-bottom: 70px;
  }

  .breadcrumb {
    padding: 20px 0 30px;
  }

  #middle-main-content .boxes .col.box.b1 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b2 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b3 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b4 {
    height: 620px;
  }

  #middle-main-content .boxes .col.box.b5 {
    height: 294px;
  }

  #middle-main-content .boxes .col.box.b6 {
    height: 296px;
  }

  #middle-main-content .boxes .col.box.b7 {
    height: 300px;
  }

  #middle-main-content .boxes .col.box a .txt {
    padding: 30px 30px;
  }

  #middle-main-content .best-travel-company h2 {
    margin-top: 80px;
    margin-bottom: 25px;
  }

  #middle-main-content .best-travel-company p {
    margin-bottom: 60px;
    font: 500 15px/26px "Poppins", sans-serif;
    padding: 0 45px;
  }

  .pi-top {
    margin-top: 80px;
  }

  #middle-main-content .partners {
    margin-bottom: 50px;
    width: 100%;
    padding: 0 10px;
  }

  #middle-main-content .slick-prev, #middle-main-content .slick-next {
    top: 155px;
  }

  .popular-itineraries .slick-slide .pi-in {
    padding: 30px;
  }

  .popular-itineraries .slick-slide ul.l-list li a.qe {
    padding: 9.5px 35px;
  }

  .popular-itineraries .slick-slide ul.l-list li {
    margin-right: 5px;
  }

  .find-my-itinerary {
    margin: 80px 0;
  }

  .social-hub {
    margin-top: 40px;
  }

  .find-my-itinerary .map-img img {
    width: 100%;
  }

  .find-my-itinerary .fmi-form {
    margin-top: 50px;
  }

  .find-my-itinerary .fmi-form .f-in {
    padding: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .awselect {
    margin-bottom: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .form-control {
    margin-bottom: 30px;
  }

  .social-hub .small-box {
    height: 400px;
  }

  .social-hub .small-box .text-box {
    bottom: -295px;
  }

  .social-hub .small-box .text-box h4 {
    font: 500 14px/18px "Poppins", sans-serif;
  }

  .fotter h4 {
    font: 700 20px/38px "Source Sans Pro", sans-serif;
    margin-bottom: 25px;
  }

  .fotter p {
    font: 400 16px/30px "Source Sans Pro", sans-serif;
    letter-spacing: 0;
  }

  .fotter p.sm-p a .fa:before {
    top: 52%;
  }

  .fotter .hp {
    padding: 20px 100px 10px;
  }

  #navigation .navbar-nav li a {
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }

  .navbar-dark .navbar-toggler {
    color: white;
    border-color: #b90622;
    background: #b90622;
  }

  .find-my-itinerary .fmi-form .f-in .submit {
    margin-top: 35px;
  }

  .popular-itineraries.special-offers span.gbtn {
    top: 100px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 15px;
  }

  .social-hub .big-box:hover .color-box {
    width: 330px;
    border-radius: 8px;
  }

  .fotter .partners .col-md-3 img, .fotter .partners .col-md-3 svg {
    width: 80% !important;
  }

  .fotter .partners {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
  }

  .fotter {
    padding: 40px 0 0;
  }

  .main-content .rht ul.l-list li a.qe,
.main-content .rht ul.l-list li a.offers {
    padding: 10px 5px;
  }

  .main-content .rht ul.l-list li a.offers,
.main-content .rht ul.l-list li a.qe {
    font-size: 11px;
  }

  .main-content > .container > .row.review-rating .rht .col-12 p {
    font: 400 11px/20px "Poppins", sans-serif;
    margin-bottom: 3px;
  }

  .main-content .rht .features {
    margin-top: 10px;
  }

  .main-content > .container > .row.c-form {
    padding: 30px 0;
  }

  .hotel-name .hname h3 {
    font-size: 25px;
  }

  .hotel-name {
    padding: 10px 0;
  }

  .rates-top .col-sm {
    padding-left: 0;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
  }

  .horizontal-form .f-in .budget-gp {
    width: 120px;
  }

  .horizontal-form .f-in .accommodation-type-gp {
    width: 180px;
  }

  .horizontal-form .f-in .destination-gp {
    width: 170px;
  }

  .horizontal-form .f-in .awselect .front_face {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .form-control {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  #acc-slider .caption {
    top: 60%;
  }

  #acc-slider .caption h1 {
    font: Bold 36px/36px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
    margin-top: 0;
    width: 98%;
  }

  .horizontal-form.res-bar .results-btns .row .row {
    width: 100%;
  }

  .horizontal-form.res-bar .results-btns .row .row:nth-child(2) {
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .horizontal-form.res-bar .results-btns .no-res {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .horizontal-form.res-bar .results-btns .list-inline li {
    font: 400 11px/15px "Source Sans Pro", sans-serif;
    padding: 6px 12px;
    margin: 0 5px 10px;
  }

  .acc-res-in .ls {
    overflow: hidden;
  }

  .acc-res-in .ls img {
    width: auto;
    height: 100%;
  }

  .acc-res-in .rs {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 40px;
  }

  .acc-res-in .rp p span.star {
    margin-right: 5px;
  }

  .acc-res-in .icon {
    width: 110px;
    position: relative;
    top: auto;
    right: 0;
    margin-bottom: 20px;
  }

  .acc-res-in ul.l-list li {
    margin-right: 11px;
    margin-bottom: 25px;
  }

  .acc-res-in ul.l-list {
    margin-top: 50px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 40px;
  }

  .acc-res-in .lp p.price span {
    font-size: 12px;
    line-height: 15px;
  }

  .acc-res-in .mid {
    padding-top: 20px;
  }

  .acc-res-in .rs {
    padding-top: 20px;
  }

  .acc-res-in h5 {
    margin-bottom: 5px;
  }

  #acc-slider .red-txt, .red-text-wrapper .red-txt {
    font: Bold 20px/25px "Poppins", sans-serif;
    padding: 10px 15px;
  }

  .red-text-wrapper {
    margin-top: -45px;
  }

  #middle-main-content .taior-made p.act-p {
    margin-bottom: 60px;
  }

  .horizontal-form .f-in .destination2-gp {
    width: 98%;
  }

  .horizontal-form .f-in .activities-gp {
    width: 98%;
  }

  #middle-main-content .slick-track .slick-slide img {
    width: 100%;
  }

  #middle-main-content .activities-gallery .slick-prev,
#middle-main-content .activities-gallery .slick-next {
    top: 100px;
  }

  .book-cond {
    padding: 0 40px;
  }

  .destinations-search .nav-list #nav-tab a {
    font: 600 17px/30px "Poppins", sans-serif;
  }

  .destinations-search #nav-tabContent {
    padding: 0 40px;
  }

  .destinations-search .outside-row {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: none;
    padding: 0;
  }

  .destinations-search .img-gallery ul li:last-child a span.lst-amt {
    font-size: 20px;
    padding: 0 20px;
  }

  .movie-modal .modal-dialog iframe {
    height: 480px;
    width: 100%;
  }

  .movie-modal .modal-dialog {
    width: 80%;
    height: 480px;
    margin-top: calc((100vh - 480px) / 2);
    max-width: none;
  }

  .horizontal-form .f-in .holiday-gp {
    width: 140px;
  }

  .horizontal-form .f-in .sd-gp {
    width: 130px;
  }

  .travel-board-boxes.itineraries .lft .box-list li,
.travel-board-boxes.itineraries .lft-mid .box-list li,
.travel-board-boxes.itineraries .rht-mid .box-list li,
.travel-board-boxes.itineraries .rht .box-list li,
.travel-board-boxes.activities .lft .box-list li,
.travel-board-boxes.activities .rht-mid .box-list li {
    width: 180px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li:first-child {
    width: 240px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li,
.travel-board-boxes.activities .rht .box-list li {
    width: 120px;
  }

  .travel-board-boxes .box-in {
    margin-bottom: 20px;
  }

  .tm-gallery .gal-outer a {
    padding: 0 7px;
    margin-bottom: 15px;
  }

  .tm-gallery .gal-outer a.gi1, .tm-gallery .gal-outer a.gi4 {
    height: 305px;
  }

  .tm-gallery .gal-outer a.gi5, .tm-gallery .gal-outer a.gi8 {
    width: 120px;
  }

  .tm-gallery .gal-outer a.gi6, .tm-gallery .gal-outer a.gi7 {
    width: calc(100% - 120px);
  }

  #acc-slider.tailor-slider .caption .caringly-crafted,
#acc-slider.video-slider .caption .caringly-crafted {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  .how-it-works a.enq {
    margin: 50px auto 0;
  }

  #middle-main-content .boxes.transport-boxes {
    padding: 0 40px;
  }

  #middle-main-content .boxes.transport-boxes .col.box {
    height: 320px !important;
  }

  .sign-up .w-box {
    width: 720px;
    padding: 40px 60px;
    margin: 200px auto;
  }

  .sign-up .w-box h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  .sign-up .w-box .ani-form .form-group .form-control {
    height: 50px;
  }

  .sign-up .w-box .ani-form .form-group.tc-fg .cont {
    margin: 15px 0;
    font-size: 12.8px;
  }

  .sign-up .w-box .ani-form .form-group.submit #sign {
    height: 50px;
    font: 500 16px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box .ani-form .form-group.bl-btn a {
    font: 500 15px/12px "Poppins", sans-serif;
    height: 50px;
    padding: 17px 12px;
  }

  .sign-up .w-box.login-box {
    padding: 40px 30px;
  }

  .sign-up .w-box.login-box .rht {
    padding-left: 20px;
  }

  .sign-up .w-box.login-box .lft {
    padding-right: 20px;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    height: 55px;
    font: 500 15px/20px "Poppins", sans-serif;
    padding: 10px 30px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 25px;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }

  .sign-up .w-box.login-box .rht .form-control {
    padding: 10px 30px;
    font: 500 17px/20px "Poppins", sans-serif;
    height: 55px;
  }

  .sign-up .w-box.login-box .rht .sub-btn {
    height: 50px;
    font: 500 16px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .rht .cont {
    margin: 25px 0 25px;
    font: 500 15px/20px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .lft .skip a {
    height: 45px;
    width: 200px;
    font: 700 18px/20px "Poppins", sans-serif;
    padding: 10px 40px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 15px/20px "Poppins", sans-serif;
  }

  .destinations-search .img-gallery ul {
    margin: 0 -10px;
  }

  .destinations-search .img-gallery ul li {
    margin-bottom: 15px;
    padding: 0 10px;
    margin-right: 0;
    float: left;
    width: 33.3333%;
  }

  .i-list.gi-list {
    margin-bottom: 100px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in {
    margin: 0 -50px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in .mid > p.bt.lst {
    margin-bottom: 20px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 0;
  }

  .acc-res-in.spfari .ari-in.s-offres-in ul.icon-list .icn {
    margin-bottom: 6px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    padding: 9.5px 10px;
  }

  .sl-tab .nav-list #nav-tab a {
    width: 160px;
    font: 700 14px/30px "Poppins", sans-serif;
    padding: 7px 5px;
  }

  .slh-in .g-box {
    width: 640px;
    padding: 50px 80px 40px;
    background: #f5f5f5;
    margin: 0 auto;
    float: none;
  }

  .map-outer .map-marker {
    width: 25px;
    height: 35px;
    background-repeat: no-repeat;
  }

  .map-outer {
    width: 680px;
    padding: 0;
    margin: 0 auto;
    position: relative;
  }

  .map-outer .map-marker#mm1 {
    bottom: 240px;
    right: 390px;
  }

  .map-outer .map-marker#mm2 {
    top: 60px;
    right: 370px;
  }

  .map-outer .map-marker#mm3 {
    top: 290px;
    right: 290px;
  }

  .map-outer .map-marker#mm4 {
    bottom: 135px;
    right: 220px;
  }

  .cont-page .sec-sec .form-group {
    margin-bottom: 50px;
  }

  .how-much {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .any-other {
    margin-top: 10px;
    margin-bottom: -20px;
  }

  .what-cur {
    margin-bottom: 25px;
  }

  .main-content.contact-cont .row.c-form h3.yhh.yhh2:after {
    width: 180px;
  }

  .aoq {
    margin-top: 0;
    margin-bottom: 0;
  }

  .feefo-img img {
    margin-left: 50px;
  }

  .horizontal-form .top250-gp {
    margin-top: 0;
  }

  .horizontal-form .f-in .submit.msub {
    width: 100%;
  }

  #navigation .navbar-nav li:last-child a {
    padding-right: 0;
    text-align: right;
    width: 33px;
    display: block;
    margin: 0 auto;
  }

  #cookie-msg {
    padding-right: 200px;
  }

  #cookie-msg #close-c-msg {
    width: 200px;
  }

  #navigation .dropdown-menu:after {
    top: 28px;
  }

  .social-hub .small-box .text-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    /* number of lines to show */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .main-content > .container > .row.review-rating #review .carousel-item .carousel-caption p {
    font: 400 18px/26px "Poppins", sans-serif;
  }

  .acc-res-in .ari-in .isr-in-row {
    min-height: 310px;
  }

  .acc-res-in .ari-in .isr-in-row .lp {
    width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .acc-res-in .ari-in .isr-in-row .bottom-paragraphs {
    bottom: 10px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 3px;
  }

  #nav-2 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 330px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 280px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    text-align: center;
    padding: 9.5px 16px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 10px;
    padding-left: 10px;
  }

  .destinations-search .nav-list #nav-tab a {
    padding: 0 20px 0;
  }

  .hotel-name .hname h3 span.tt {
    margin-left: 0;
    display: block;
    margin-top: 5px;
  }

  .dwn-pop {
    bottom: auto;
    top: 280px;
  }

  #middle-main-content .taior-made p.lp {
    margin-bottom: 60px;
  }

  .rates-top .cur label {
    font-size: 17px;
    margin: 15px 0 5px;
    padding-left: 0;
    width: 100%;
  }

  .rates-top .cur select {
    width: 100%;
    padding: 5px;
    margin-left: 0;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #navigation {
    padding: 15px 20px;
  }

  #navigation .navbar-nav li a {
    font-size: 12.8px;
  }

  #navigation .navbar-brand img, #navigation .navbar-brand svg {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 90px;
  }

  #navigation .navbar-nav li:last-child a img {
    margin-top: -5px;
  }

  #home-slider .carousel-item {
    height: 460px;
  }

  #home-slider .carousel-item .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p:nth-last-of-type(1) {
    margin-bottom: 80px;
  }

  #middle-main-content .boxes .col.box.b1 {
    height: 440px;
  }

  #middle-main-content .boxes .col.box.b2 {
    height: 273px;
  }

  #middle-main-content .boxes .col.box.b3 {
    height: 328px;
  }

  #middle-main-content .boxes .col.box.b4 {
    height: 620px;
  }

  #middle-main-content .boxes .col.box.b5 {
    height: 294px;
  }

  #middle-main-content .boxes .col.box.b6 {
    height: 296px;
  }

  #middle-main-content .boxes .col.box.b7 {
    height: 450px;
  }

  #middle-main-content .boxes .col.box a .txt {
    padding: 30px 30px;
  }

  #middle-main-content .best-travel-company h2 {
    margin-top: 80px;
    margin-bottom: 25px;
  }

  #middle-main-content .best-travel-company p {
    margin-bottom: 80px;
  }

  .pi-top {
    margin-top: 80px;
  }

  #middle-main-content .slick-prev, #middle-main-content .slick-next {
    top: 135px;
  }

  .popular-itineraries .slick-slide .pi-in {
    padding: 30px;
  }

  .popular-itineraries .slick-slide ul.l-list li a.qe {
    padding: 9.5px 35px;
  }

  .popular-itineraries .slick-slide ul.l-list li {
    margin-right: 5px;
  }

  .find-my-itinerary {
    margin: 80px 0;
  }

  .social-hub {
    margin-top: 80px;
  }

  .find-my-itinerary .map-img img {
    width: 100%;
  }

  .find-my-itinerary .fmi-form {
    margin-top: 50px;
  }

  .find-my-itinerary .fmi-form .f-in {
    padding: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .awselect {
    margin-bottom: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .form-control {
    margin-bottom: 25px;
  }

  .social-hub .small-box {
    height: 400px;
  }

  .social-hub .small-box .text-box {
    bottom: -295px;
  }

  .social-hub .small-box .text-box h4 {
    font: 500 14px/18px "Poppins", sans-serif;
  }

  .fotter h4 {
    font: 700 20px/38px "Source Sans Pro", sans-serif;
    margin-bottom: 25px;
  }

  .fotter p {
    font: 400 16px/30px "Source Sans Pro", sans-serif;
    letter-spacing: 0;
  }

  .fotter p.sm-p a .fa:before {
    top: 52%;
  }

  .fotter .hp {
    padding: 20px 100px 10px;
  }

  .main-content .rht ul.l-list li a.qe,
.main-content .rht ul.l-list li a.offers {
    padding: 10px 25px;
  }

  .main-content > .container > .row.review-rating .rht .col-12 p {
    font: 400 11px/20px "Poppins", sans-serif;
  }

  .main-content > .container > .row.c-form {
    padding: 30px 100px;
  }

  .hotel-name .hname h3 {
    font-size: 25px;
  }

  .hotel-name {
    padding: 10px 0;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
  }

  .horizontal-form .f-in .budget-gp {
    width: 120px;
  }

  .horizontal-form .f-in .accommodation-type-gp {
    width: 180px;
  }

  .horizontal-form .f-in .destination-gp {
    width: 170px;
  }

  .horizontal-form .f-in .awselect .front_face {
    font: 300 11px/12px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .form-control {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  .horizontal-form.res-bar .results-btns .list-inline li {
    font: 400 11px/15px "Source Sans Pro", sans-serif;
    padding: 6px 12px;
    margin: 0 5px;
  }

  .horizontal-form.res-bar .results-btns .r-search {
    width: 142px;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    -webkit-box-flex: unset;
        -ms-flex-positive: unset;
            flex-grow: unset;
    padding: 0;
    margin-left: 0;
    margin-right: 5px;
    padding-right: 14px;
    position: relative;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 45px;
  }

  .acc-res-in .rs {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 40px;
  }

  .acc-res-in .rp p span.star {
    margin-right: 5px;
  }

  #acc-slider .caption h1 {
    font: Bold 38px/40px "Poppins", sans-serif;
    margin-bottom: 0;
  }

  #acc-slider .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p.act-p {
    margin-bottom: 90px;
  }

  .horizontal-form .f-in .destination2-gp {
    width: 330px;
  }

  .horizontal-form .f-in .activities-gp {
    width: 350px;
  }

  #middle-main-content .slick-track .slick-slide img {
    width: 100%;
  }

  #middle-main-content .activities-gallery .slick-prev,
#middle-main-content .activities-gallery .slick-next {
    top: 100px;
  }

  .destinations-search .nav-list #nav-tab a {
    font: 600 17px/30px "Poppins", sans-serif;
  }

  .destinations-search .img-gallery ul {
    margin: 0 -10px;
  }

  .destinations-search .img-gallery ul li {
    margin-bottom: 15px;
    padding: 0 10px;
    margin-right: 0;
    float: left;
    width: 33.3333%;
  }

  .destinations-search .img-gallery ul li:last-child a span.lst-amt {
    font-size: 18px;
    line-height: 18px;
    padding: 0 10px;
  }

  .horizontal-form .f-in .holiday-gp {
    width: 180px;
  }

  .horizontal-form .f-in .sd-gp {
    width: 140px;
  }

  .travel-board-boxes.itineraries .lft .box-list li,
.travel-board-boxes.itineraries .lft-mid .box-list li,
.travel-board-boxes.itineraries .rht-mid .box-list li,
.travel-board-boxes.itineraries .rht .box-list li,
.travel-board-boxes.activities .lft .box-list li,
.travel-board-boxes.activities .rht-mid .box-list li {
    width: 240px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li:first-child {
    width: 319.98px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li,
.travel-board-boxes.activities .rht .box-list li {
    width: 160px;
  }

  .travel-board-boxes .box-in {
    margin-bottom: 20px;
  }

  #middle-main-content .taior-made h1 {
    font: Bold 28px/32px "Poppins", sans-serif;
  }

  .tm-gallery .gal-outer a.gi5, .tm-gallery .gal-outer a.gi8 {
    width: 160px;
  }

  .tm-gallery .gal-outer a.gi6, .tm-gallery .gal-outer a.gi7 {
    width: calc(100% - 160px);
  }

  #middle-main-content .boxes.transport-boxes {
    padding: 0 80px;
  }

  #middle-main-content .boxes.transport-boxes .col.box {
    height: 320px !important;
  }

  .sign-up .w-box {
    width: 920px;
    padding: 40px 80px;
    margin: 200px auto;
  }

  .sign-up .w-box h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  .sign-up .w-box .ani-form .form-group .form-control {
    height: 50px;
  }

  .sign-up .w-box .ani-form .form-group.tc-fg .cont {
    margin: 15px 0;
    font-size: 15px;
  }

  .sign-up .w-box .ani-form .form-group.submit #sign {
    height: 50px;
    font: 500 20px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box .ani-form .form-group.bl-btn a {
    font: 500 20px/12px "Poppins", sans-serif;
    height: 50px;
    padding: 17px;
  }

  .sign-up .w-box.login-box {
    padding: 55px 50px;
  }

  .sign-up .w-box.login-box .rht {
    padding-left: 50px;
  }

  .sign-up .w-box.login-box .lft {
    padding-right: 50px;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    height: 70px;
    font: 500 20px/20px "Poppins", sans-serif;
    padding: 10px 30px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 25px;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }

  .sign-up .w-box.login-box .rht .form-control {
    padding: 10px 40px;
    font: 500 20px/20px "Poppins", sans-serif;
    height: 70px;
  }

  .sign-up .w-box.login-box .rht .sub-btn {
    height: 55px;
    font: 500 20px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .rht .cont {
    margin: 35px 0 30px;
    font: 500 18px/20px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .lft .skip a {
    height: 55px;
    width: 200px;
    font: 700 20px/20px "Poppins", sans-serif;
    padding: 10px 40px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 18px/20px "Poppins", sans-serif;
  }

  .destinations-search ul.l-list li a.offers {
    padding: 10px 8px;
  }

  .destinations-search ul.l-list li a.qe {
    padding: 10px 8px;
  }

  .popular-itineraries.special-offers span.gbtn {
    top: 90px;
  }

  /*itineraries-individual.php*/
  .acc-res-in .ls img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left;
  }

  .acc-res-in.spfari .ari-in.s-offres-in .mid > p.bt.lst {
    margin-bottom: 20px;
  }

  .acc-res-in ul.l-list {
    margin-top: 60px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 15px;
    padding-left: 15px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in ul.l-list li {
    margin: 10px 0 0;
  }

  .sl-tab .nav-list #nav-tab a {
    width: 210px;
  }

  .slh-in .g-box {
    width: 740px;
    padding: 50px 80px 40px;
    background: #f5f5f5;
    margin: 0 auto;
    float: none;
  }

  .cont-page .sec-sec .form-group {
    margin-bottom: 50px;
  }

  .how-much {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .any-other {
    margin-top: 10px;
    margin-bottom: -20px;
  }

  .what-cur {
    margin-bottom: 25px;
  }

  .main-content.contact-cont .row.c-form h3.yhh.yhh2:after {
    width: 210px;
  }

  .feefo-img img {
    margin-left: 100px;
  }

  .horizontal-form .f-in .submit.msub {
    width: 100%;
  }

  #navigation .navbar-brand {
    margin-right: 0;
  }

  #cookie-msg {
    padding-right: 200px;
  }

  #cookie-msg #close-c-msg {
    width: 200px;
  }

  #navigation .dropdown-menu .dropdown-item {
    padding: 4px 7px;
    font-size: 10px;
    background: transparent !important;
  }

  .social-hub .small-box .text-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    /* number of lines to show */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .main-content > .container > .row.nc .share ul.nav li a svg {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .acc-res-in .ari-in .isr-in-row {
    min-height: 310px;
  }

  .acc-res-in.fis-in h4.nad {
    margin-bottom: 85px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 3px;
  }

  #nav-2 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 320px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 280px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    text-align: center;
    padding: 9.5px 30px;
  }

  .dwn-pop {
    bottom: auto;
    top: 370px;
  }

  .rates-top h5 {
    font-size: 14px;
    line-height: 20px;
  }

  .rates-top .cur label {
    font-size: 14px;
    margin: 15px 0;
    padding-left: 0;
  }

  .rates-top .cur select {
    width: 100px;
    padding: 5px;
    margin-left: 10px;
    font-size: 12px;
  }

  .popular-itineraries .slick-slide .iti-d-2 {
    min-height: 180px;
  }
}
@media (min-width: 1200px) and (max-width: 1680px) {
  .msg-d {
    padding-top: 0;
    padding-bottom: 20px;
  }
  .msg-d p {
    font-size: 12px;
  }

  .transaction-top {
    min-height: 480px;
  }

  .personalize-db .img-box .recharts-wrapper .recharts-surface {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }

  .transaction-top .tab-content .tr-form .form-group:last-child .dropdown-item {
    font: 12px/18px "SFPD Bold";
  }

  .transaction-top .nav-tabs .nav-item .nav-link .sep {
    font: 22px/24px "SFPD Bold";
  }

  #main-content .top-account-line .tal-in.tal-in-middle {
    margin: 0 120px;
  }

  #main-content .top-account-line .tal-in.tal-in-middle:before {
    content: "";
    width: 95px;
    left: -110px;
  }

  #main-content .top-account-line .tal-in.tal-in-middle:after {
    content: "";
    width: 95px;
    right: -105px;
  }

  .mid-gate-line .gate-tool .g-tool {
    padding: 22px 15px;
  }

  .mid-gate-line .gate-tool .g-tool h4 {
    font: 16px/30px "SFPD Bold";
    margin-bottom: 5px;
  }

  .mid-gate-line .gate-tool .g-tool p {
    font: 12.8px/16px "SFPD Regular";
    margin-bottom: 10px;
  }

  .mid-gate-line .gate-tool .g-tool a {
    font: 11px/25px "SFPD Bold";
  }

  .mid-gate-line .personalize-db .nav-tabs .nav-item a {
    font: 12px/21px "SFPD Bold";
    padding: 15px 5px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 h4 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane {
    padding: 28px 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li {
    font-size: 15px;
  }

  .transactions .img-box .mi {
    max-width: 80%;
  }

  .footer p {
    font: 12px/16px "SFPD Bold";
  }

  .mid-gate-line .gate-tool h3, .mid-gate-line .personalize-db h3 {
    font: 15px/26px "SFPD Bold";
    margin: 0 0 15px;
  }

  .mid-gate-line .gate-tool h3 span.rs,
.mid-gate-line .personalize-db h3 span.rs {
    font: 10px/20px "SFPD Bold";
  }

  #side-navigation .logo-outer {
    height: 80px;
  }

  #side-navigation .logo-outer img.logo {
    width: 200px;
  }

  #side-navigation .act-acc {
    padding: 10px 30px;
    max-height: 95px;
  }

  #side-navigation .act-acc h3 {
    font: 12.8px/15px "SFPD Bold";
    margin-bottom: 10px;
  }

  #side-navigation .act-acc p.ns {
    font: 12px/15px "SFPD Regular";
    margin-bottom: 5px;
  }

  #side-navigation .nav-user {
    height: 60px;
    padding: 0 20px;
  }

  #side-navigation .nav-list-outer {
    padding: 20px 0;
    height: calc(100% - 80px - 95px - 60px);
  }

  #side-navigation .nav-list-outer .nav-list li a {
    font: 14px/27px "SFPD Bold";
    padding-left: 100px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img {
    width: 80px;
    margin-right: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-cont {
    width: calc(100% - 100px);
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box {
    width: 80px;
    height: 80px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box img {
    width: 80%;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 h4 {
    font: 14px/21px "SFPD Bold";
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p1 {
    font: 12px/14px "SFPD Regular";
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p2 {
    font: 12px/16px "SFPD Regular";
    margin-bottom: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
    font: 12px/25px "SFPD Bold";
    text-decoration: none;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab2 .user-list li {
    padding: 0 10px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab2 h4 {
    font-size: 15px;
    margin-bottom: 18.3px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li .ball {
    width: 60px;
    height: 60px;
    margin: 10px auto 20px;
  }

  .balance-line .in h3 {
    font: 20px/34px "SFPD Bold";
  }

  .balance-line .in h5 {
    font: 12px/16px "SFPD Bold";
    margin-bottom: 0;
  }

  .balance-line .in {
    padding: 0 20px;
  }

  .balance-line .in .date-btn {
    height: 45px;
    width: 180px;
    font: 15px/27px "SFPD Bold";
    margin-right: -20px;
  }

  .transactions .t-list li h3 {
    font: 20px/30px "SFPD Bold";
  }

  .transactions .t-list li i {
    right: 25px;
  }

  #side-navigation .nav-list-outer .nav-list li a .ni {
    left: 34px;
  }

  #side-navigation .nav-user .nu-in a img.dots {
    margin-left: 40px;
  }

  #side-navigation .nav-user .nu-in a {
    font: 14px/30px "SFPD Bold";
  }

  #inside-container {
    padding: 0 60px;
  }

  .sign-box {
    width: 37.203%;
    height: calc(100vh - 100px);
    margin-top: 50px;
    padding: 40px 55px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 37.203%;
            flex: 0 0 37.203%;
    max-width: 37.203%;
  }

  #inside-container .sign-top .sign-icon {
    margin: 0 auto 10px;
    width: 50px;
  }

  #inside-container .sign-top .sign-head {
    font: 700 25px/36px "SFPD Regular";
    margin-bottom: 20px;
  }

  #inside-container .form-type-1 .form-group .form-control {
    height: 45px;
    padding: 10px 20px;
    font-size: 12.8px;
  }

  #inside-container .form-type-1 .submit #submit-btn {
    font: 700 15px "Roboto", sans-serif;
    height: 45px;
    padding: 8px 35px;
    margin: 30px auto 30px;
  }

  #inside-container .sign-box .change-tel {
    font: 700 15px "Roboto", sans-serif;
    height: 45px;
    padding: 12px 35px;
    margin: -15px auto 20px;
  }

  #inside-container .no-account .no-account-p {
    font: 400 14px/20px "SFPD Regular";
    margin-bottom: 20px;
  }

  #inside-container .copy-txt {
    padding: 0 10%;
  }

  .sign-box .cont {
    font: 700 14px/19px "SFPD Bold";
  }

  #inside-container .form-type-1 .fogp .fp {
    font: 700 14px "SFPD Bold";
  }

  #inside-container .main-row .logo-txt {
    margin-top: 100px;
    padding-left: 30px;
  }

  #inside-container .no-account p.sms-p {
    font: 700 14px/16px "SFPD Bold";
    margin-bottom: 10px;
  }

  #inside-container .sign-box p.time-left {
    font: 400 18px/25px "SFPD Regular";
    margin-bottom: 25px;
    margin-top: -7px;
  }

  #inside-container .sign-box .pin-ver-form .form-group .form-control {
    height: 50px;
    padding: 10px 10px;
    margin-bottom: -10px;
  }

  #inside-container .main-row .logo-txt h1 {
    font: 700 40px/60px "SFPD Bold";
    margin: 80px 0 10px;
  }

  #inside-container .main-row .logo-txt h4 {
    font: 400 18px/40px "SFPD Regular";
    margin-top: 50px;
  }

  #turn-box {
    width: 1400px;
    height: 1400px;
    top: 120px;
    right: -50%;
  }

  #side-navigation {
    width: 280px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 280px;
            flex: 0 0 280px;
    max-width: 280px;
  }

  #main-content {
    padding-left: 280px;
  }

  #main-content .top-line p {
    font: 14px/21px "SFPD Regular";
  }

  #main-content .top-line .sandbox {
    padding: 12.5px 20px;
    font: 15px/25px "SFPD Bold";
  }

  #main-content .top-line .sandbox .l-circle {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
  }

  #main-content .top-line .notification-bell {
    right: 240px;
    top: 12px;
    width: 25px;
    height: 25px;
  }

  #main-content .top-line {
    padding: 14.5px 0;
    padding-left: 30px;
  }

  #main-content.dashboard-live-mode .top-line p {
    padding-right: 310px;
  }

  #main-content.dashboard-live-mode .top-line p:after {
    right: 285px;
  }

  #main-content .notification-bar .nb-in {
    padding: 15px 30px;
  }

  #main-content .notification-bar p {
    font: 14px/24px "SFPD Bold";
  }

  #main-content .notification-bar .colps-btn {
    font: 12.8px/20px "SFPD Bold";
  }

  table.transactions-table thead th {
    font: 400 12.8px/16px "SFPD Bold";
    padding: 20px 10px;
  }

  table.transactions-table tbody tr td {
    font: 400 12px/16px "SFPD Bold";
    color: #2b2935;
    padding: 20px 10px;
    border: 0;
  }

  #activation-modal.modal-dialog, #activation-modal-2.modal-dialog, .ancd-mod,
.ctm-modal.modal-dialog {
    -webkit-transform: scale(0.75) !important;
    transform: scale(0.75) !important;
    margin: -7vh auto;
  }

  .transaction-top .nav-tabs .nav-item .nav-link {
    padding: 15px;
  }

  .transaction-top .tab-content .tt-form .form-group {
    padding: 0 5px;
  }

  .transaction-top .tab-content .tt-form .form-group .form-control {
    height: 40px;
    padding: 0 12px;
    font: 12px/18px "SFPD Regular";
  }

  .transaction-top .tab-content .tt-form .form-group .srch-btn {
    padding: 8.5px 30px;
    font: 12.8px/19px "SFPD Bold";
  }

  .transaction-top .tab-content .tr-form {
    padding: 0;
    padding-left: 0;
  }

  .transaction-top .tab-content .tr-form .form-group:last-child {
    width: 40px;
  }

  .transaction-top .tab-content .tr-form .form-group {
    width: calc((100% - 40px) / 2);
    padding: 0 5px;
    margin: 0;
  }

  .transaction-top .tab-content .tr-form .form-group .form-control {
    height: 40px;
    padding: 0 25px;
    font: 12px/18px "SFPD Regular";
  }

  .transaction-top .tab-content .tr-form .form-group .slct i {
    left: 10px;
    top: 14px;
  }

  .transaction-top .tab-content .tr-form .form-group .slct:after,
.transaction-top .tab-content .tt-form .form-group .slct:after {
    top: 12px;
  }

  .transaction-top .tab-content .tr-form .form-group .dwn-btn {
    width: 30px;
    font-size: 26px;
  }

  .transaction-top .tab-content .tt-ttp {
    padding: 10px 10px 15px;
  }

  .transaction-top .tab-content .tr-form .form-group label {
    font: 12px/14px "SFPD Bold";
  }

  .transaction-top .tab-content .tt-form .form-group label {
    font: 12px/14px "SFPD Bold";
  }

  .transaction-top .nav-tabs .nav-item .nav-link .amt {
    font: 22px/34px "SFPD Bold";
  }

  #side-navigation .act-acc .t-amt {
    font: 18px/18px "SFPD Bold";
  }

  #plus-button {
    bottom: 70px;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: center;
    transform-origin: center;
    right: 20px;
  }

  #plus-button.active {
    -webkit-transform: rotate(-45deg) scale(0.75);
    transform: rotate(-45deg) scale(0.75);
  }

  #plus-menu {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    bottom: 150px;
  }

  #create-new-payment-link-model, #create-new-payment-template-modal {
    -webkit-transform: scale(0.75) !important;
    transform: scale(0.75) !important;
    margin: -7vh auto;
  }

  .transaction-top.reports-top .form-control {
    font: 11px/18px "SFPD Bold";
  }

  .transaction-top.reports-top h6 {
    font: 10px/12px "SFPD Bold";
  }

  .transaction-top.reports-top .cont {
    font: 11px/16px "SFPD Bold";
    padding-left: 27px;
  }

  .transaction-top.reports-top p {
    font: 8.5px/12px "SFPD Regular";
    margin-top: 5px;
  }

  .transaction-top.reports-top .nxt-btn {
    padding: 14px 10px;
    font: 12px/10px "SFPD Bold";
  }

  .transaction-top.reports-top .checkmark {
    top: -2px;
  }

  .transaction-top.reports-top .cont .fas {
    margin-left: 3px;
  }

  .transaction-top.reports-top .reports-h h3 {
    font: 22px/28px "SFPD Bold";
    margin-bottom: 20px;
  }

  .transaction-top.reports-top .reports-h h4 {
    font: 12px/14px "SFPD Bold";
    margin-top: 15px;
  }

  .transaction-top.reports-top .tab-content .tt-ttp {
    padding: 10px 0 15px;
  }

  .transaction-top.reports-top .fas.fa-exchange-alt {
    right: 12px;
    bottom: 27px;
    font-size: 10px;
  }

  .settings-outer .lft-sd {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 285px;
            flex: 0 0 285px;
    max-width: 285px;
    margin-right: 30px;
    padding: 40px 0;
  }

  .settings-outer .rht-sd {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 315px);
            flex: 0 0 calc(100% - 315px);
    max-width: calc(100% - 315px);
    padding: 40px 15px;
  }

  .settings-outer .lft-sd .nxt-btn, .settings-outer .lft-sd .ext-btn {
    padding: 12px 10px;
    font: 14px/21px "SFPD Bold";
  }

  .settings-outer .lft-sd #set-tab1 .nav-item a {
    font: 18px/22px "SFPD Bold";
    padding: 20px 10px;
  }

  .settings-outer .rht-sd .tdh {
    font: 24px/30px "SFPD Bold";
    margin-bottom: 20px;
  }

  .settings-outer .rht-sd .sth {
    font: 18px/22px "SFPD Bold";
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .settings-outer .rht-sd .sh {
    font: 12px/14px "SFPD Bold";
  }

  .settings-outer .rht-sd .mh {
    font: 14px/20px "SFPD Bold";
    margin-bottom: 20px;
  }

  .settings-outer .rht-sd .soc-in img {
    width: 30px;
  }

  .settings-outer .rht-sd .soc-in {
    padding-left: 60px;
  }

  .settings-outer .rht-sd .soc-in .form-control {
    font: 12px/18px "SFPD Bold";
    margin-top: -2px;
  }

  .settings-outer .rht-sd .nxt-btn {
    padding: 7px 10px;
    font: 12px/17px "SFPD Bold";
  }

  .settings-outer .rht-sd table.transactions-table thead th {
    font: 400 11px/12px "SFPD Bold";
    padding: 20px 10px;
  }

  .r-section .r-btn, .r-section .checkbox-tools:checked + label,
.r-section .checkbox-tools:not(:checked) + label {
    width: 196px;
    height: 130px;
  }

  .r-section .for-checkbox-tools .icn {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .r-section .for-checkbox-tools .no {
    font: 12px/20px "SFPD Bold";
    margin-bottom: 5px;
  }

  .r-section .for-checkbox-tools .name {
    font: 8px/8px "SFPD Regular";
    margin-bottom: 15px;
  }

  .r-section .for-checkbox-tools .date {
    width: 50%;
    float: left;
    font: 10px/15px "SFPD Bold";
  }

  .r-section .for-checkbox-tools .no2 {
    width: 50%;
    float: left;
    font: 10px/15px "SFPD Bold";
    text-align: right;
  }

  .r-section .checkbox-tools:checked + label,
.r-section .checkbox-tools:not(:checked) + label {
    padding: 15px;
    border-radius: 10px;
  }

  .r-section .r-btn .txt span {
    font: 12px/14px "SFPD Bold";
  }

  .r-section .r-btn .txt i {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .r-section .r-btn.add-card {
    border-radius: 10px;
  }

  .profile-form-section .form-control {
    font: 14px/20px "SFPD Bold";
    margin-bottom: 20px;
  }

  #user-menu {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    bottom: 60px;
  }

  #user-menu.active {
    left: 20px;
  }
}
@media only screen and (max-width: 360px) {
  #acc-slider .caption h1 {
    font: Bold 28px/32px "Poppins", sans-serif;
  }

  #middle-main-content .slick-prev, #middle-main-content .slick-next {
    top: 135px;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 30px;
  }

  .sec-sec.cur-btns .btn {
    width: 90px;
  }

  .destinations-search .nav-list #nav-tab a {
    padding: 0 40px 0;
  }

  .sign-up .w-box {
    width: 310px;
  }

  .sign-up .w-box.login-box .lft .btn-col a .fa {
    font-size: 35px;
    width: 35px;
    margin-right: 20px;
    text-align: center;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    font: 500 14px/20px "Poppins", sans-serif;
    padding: 10px 20px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 14px/20px "Poppins", sans-serif;
  }

  .map-outer {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }

  #acc-slider .red-txt, .red-text-wrapper .red-txt {
    font: Bold 12px/25px "Poppins", sans-serif;
    padding: 7px;
    width: 100%;
    text-align: center;
    left: 0;
  }

  .red-text-wrapper {
    margin-top: -39px;
  }

  .acc-res-in .rp p {
    font: bold 12px/25px "Poppins", sans-serif;
  }

  .acc-res-in .rp p span.star i.fa, .acc-res-in .rp p span.trip i.fa {
    font-size: 17px;
  }
}
/*responsive styles*/
@media (min-width: 768px) and (max-width: 991px) {
  #navigation {
    padding: 15px 20px;
  }

  #navigation .navbar-nav li a {
    font-size: 12.8px;
  }

  #navigation .navbar-brand img, #navigation .navbar-brand svg {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 120px;
  }

  #navigation .navbar-nav li:last-child a img {
    margin-top: -5px;
  }

  #home-slider .carousel-item {
    height: 340px;
  }

  #home-slider .carousel-item .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p {
    font: 500 15px/26px "Poppins", sans-serif;
    padding: 0 45px;
  }

  #middle-main-content .taior-made p:nth-last-of-type(1) {
    margin-bottom: 70px;
  }

  .breadcrumb {
    padding: 20px 0 30px;
  }

  #middle-main-content .boxes .col.box.b1 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b2 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b3 {
    height: 275px;
  }

  #middle-main-content .boxes .col.box.b4 {
    height: 620px;
  }

  #middle-main-content .boxes .col.box.b5 {
    height: 294px;
  }

  #middle-main-content .boxes .col.box.b6 {
    height: 296px;
  }

  #middle-main-content .boxes .col.box.b7 {
    height: 300px;
  }

  #middle-main-content .boxes .col.box a .txt {
    padding: 30px 30px;
  }

  #middle-main-content .best-travel-company h2 {
    margin-top: 80px;
    margin-bottom: 25px;
  }

  #middle-main-content .best-travel-company p {
    margin-bottom: 60px;
    font: 500 15px/26px "Poppins", sans-serif;
    padding: 0 45px;
  }

  .pi-top {
    margin-top: 80px;
  }

  #middle-main-content .partners {
    margin-bottom: 50px;
    width: 100%;
    padding: 0 10px;
  }

  #middle-main-content .slick-prev,
#middle-main-content .slick-next {
    top: 155px;
  }

  .popular-itineraries .slick-slide .pi-in {
    padding: 30px;
  }

  .popular-itineraries .slick-slide ul.l-list li a.qe {
    padding: 9.5px 35px;
  }

  .popular-itineraries .slick-slide ul.l-list li {
    margin-right: 5px;
  }

  .find-my-itinerary {
    margin: 80px 0;
  }

  .social-hub {
    margin-top: 40px;
  }

  .find-my-itinerary .map-img img {
    width: 100%;
  }

  .find-my-itinerary .fmi-form {
    margin-top: 50px;
  }

  .find-my-itinerary .fmi-form .f-in {
    padding: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .awselect {
    margin-bottom: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .form-control {
    margin-bottom: 30px;
  }

  .social-hub .small-box {
    height: 400px;
  }

  .social-hub .small-box .text-box {
    bottom: -295px;
  }

  .social-hub .small-box .text-box h4 {
    font: 500 14px/18px "Poppins", sans-serif;
  }

  .fotter h4 {
    font: 700 20px/38px "Source Sans Pro", sans-serif;
    margin-bottom: 25px;
  }

  .fotter p {
    font: 400 16px/30px "Source Sans Pro", sans-serif;
    letter-spacing: 0;
  }

  .fotter p.sm-p a .fa:before {
    top: 52%;
  }

  .fotter .hp {
    padding: 20px 100px 10px;
  }

  #navigation .navbar-nav li a {
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }

  .navbar-dark .navbar-toggler {
    color: white;
    border-color: #b90622;
    background: #b90622;
  }

  .find-my-itinerary .fmi-form .f-in .submit {
    margin-top: 35px;
  }

  .popular-itineraries.special-offers span.gbtn {
    top: 100px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 15px;
  }

  .social-hub .big-box:hover .color-box {
    width: 330px;
    border-radius: 8px;
  }

  .fotter .partners .col-md-3 img, .fotter .partners .col-md-3 svg {
    width: 80% !important;
  }

  .fotter .partners {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 40px;
  }

  .fotter {
    padding: 40px 0 0;
  }

  .main-content .rht ul.l-list li a.qe, .main-content .rht ul.l-list li a.offers {
    padding: 10px 5px;
  }

  .main-content .rht ul.l-list li a.offers, .main-content .rht ul.l-list li a.qe {
    font-size: 11px;
  }

  .main-content > .container > .row.review-rating .rht .col-12 p {
    font: 400 11px/20px "Poppins", sans-serif;
    margin-bottom: 3px;
  }

  .main-content .rht .features {
    margin-top: 10px;
  }

  .main-content > .container > .row.c-form {
    padding: 30px 0px;
  }

  .hotel-name .hname h3 {
    font-size: 25px;
  }

  .hotel-name {
    padding: 10px 0;
  }

  .rates-top .col-sm {
    padding-left: 0;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
  }

  .horizontal-form .f-in .budget-gp {
    width: 120px;
  }

  .horizontal-form .f-in .accommodation-type-gp {
    width: 180px;
  }

  .horizontal-form .f-in .destination-gp {
    width: 170px;
  }

  .horizontal-form .f-in .awselect .front_face {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .form-control {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  #acc-slider .caption {
    top: 60%;
  }

  #acc-slider .caption h1 {
    font: Bold 36px/36px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
    margin-top: 0;
    width: 98%;
  }

  .horizontal-form.res-bar .results-btns .row .row {
    width: 100%;
  }
  .horizontal-form.res-bar .results-btns .row .row:nth-child(2) {
    width: 100%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .horizontal-form.res-bar .results-btns .no-res {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .horizontal-form.res-bar .results-btns .list-inline li {
    font: 400 11px/15px "Source Sans Pro", sans-serif;
    padding: 6px 12px;
    margin: 0 5px 10px;
  }

  .acc-res-in .ls {
    overflow: hidden;
  }
  .acc-res-in .ls img {
    width: auto;
    height: 100%;
  }

  .acc-res-in .rs {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 40px;
  }

  .acc-res-in .rp p span.star {
    margin-right: 5px;
  }

  .acc-res-in .icon {
    width: 110px;
    position: relative;
    top: auto;
    right: 0;
    margin-bottom: 20px;
  }

  .acc-res-in ul.l-list li {
    margin-right: 11px;
    margin-bottom: 25px;
  }

  .acc-res-in ul.l-list {
    margin-top: 50px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 40px;
  }

  .acc-res-in .lp p.price span {
    font-size: 12px;
    line-height: 15px;
  }

  .acc-res-in .mid {
    padding-top: 20px;
  }

  .acc-res-in .rs {
    padding-top: 20px;
  }

  .acc-res-in h5 {
    margin-bottom: 5px;
  }

  #acc-slider .red-txt, .red-text-wrapper .red-txt {
    font: Bold 20px/25px "Poppins", sans-serif;
    padding: 10px 15px;
  }

  .red-text-wrapper {
    margin-top: -45px;
  }

  #middle-main-content .taior-made p.act-p {
    margin-bottom: 60px;
  }

  .horizontal-form .f-in .destination2-gp {
    width: 98%;
  }

  .horizontal-form .f-in .activities-gp {
    width: 98%;
  }

  #middle-main-content .slick-track .slick-slide img {
    width: 100%;
  }

  #middle-main-content .activities-gallery .slick-prev,
#middle-main-content .activities-gallery .slick-next {
    top: 100px;
  }

  .book-cond {
    padding: 0 40px;
  }

  .destinations-search .nav-list #nav-tab a {
    font: 600 17px/30px "Poppins", sans-serif;
  }

  .destinations-search #nav-tabContent {
    padding: 0 40px;
  }

  .destinations-search .outside-row {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: none;
    padding: 0;
  }

  .destinations-search .img-gallery ul li:last-child a span.lst-amt {
    font-size: 20px;
    padding: 0 20px;
  }

  .movie-modal .modal-dialog iframe {
    height: 480px;
    width: 100%;
  }

  .movie-modal .modal-dialog {
    width: 80%;
    height: 480px;
    margin-top: calc((100vh - 480px) / 2);
    max-width: none;
  }

  .horizontal-form .f-in .holiday-gp {
    width: 140px;
  }

  .horizontal-form .f-in .sd-gp {
    width: 130px;
  }

  .travel-board-boxes.itineraries .lft .box-list li,
.travel-board-boxes.itineraries .lft-mid .box-list li,
.travel-board-boxes.itineraries .rht-mid .box-list li,
.travel-board-boxes.itineraries .rht .box-list li, .travel-board-boxes.activities .lft .box-list li, .travel-board-boxes.activities .rht-mid .box-list li {
    width: 180px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li:first-child {
    width: 240px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li, .travel-board-boxes.activities .rht .box-list li {
    width: 120px;
  }

  .travel-board-boxes .box-in {
    margin-bottom: 20px;
  }

  .tm-gallery .gal-outer a {
    padding: 0 7px;
    margin-bottom: 15px;
  }

  .tm-gallery .gal-outer a.gi1, .tm-gallery .gal-outer a.gi4 {
    height: 305px;
  }

  .tm-gallery .gal-outer a.gi5, .tm-gallery .gal-outer a.gi8 {
    width: 120px;
  }

  .tm-gallery .gal-outer a.gi6, .tm-gallery .gal-outer a.gi7 {
    width: calc(100% - 120px);
  }

  #acc-slider.tailor-slider .caption .caringly-crafted, #acc-slider.video-slider .caption .caringly-crafted {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }

  .how-it-works a.enq {
    margin: 50px auto 0;
  }

  #middle-main-content .boxes.transport-boxes {
    padding: 0 40px;
  }

  #middle-main-content .boxes.transport-boxes .col.box {
    height: 320px !important;
  }

  .sign-up .w-box {
    width: 720px;
    padding: 40px 60px;
    margin: 200px auto;
  }

  .sign-up .w-box h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  .sign-up .w-box .ani-form .form-group .form-control {
    height: 50px;
  }

  .sign-up .w-box .ani-form .form-group.tc-fg .cont {
    margin: 15px 0;
    font-size: 12.8px;
  }

  .sign-up .w-box .ani-form .form-group.submit #sign {
    height: 50px;
    font: 500 16px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box .ani-form .form-group.bl-btn a {
    font: 500 15px/12px "Poppins", sans-serif;
    height: 50px;
    padding: 17px 12px;
  }

  .sign-up .w-box.login-box {
    padding: 40px 30px;
  }

  .sign-up .w-box.login-box .rht {
    padding-left: 20px;
  }

  .sign-up .w-box.login-box .lft {
    padding-right: 20px;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    height: 55px;
    font: 500 15px/20px "Poppins", sans-serif;
    padding: 10px 30px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 25px;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }

  .sign-up .w-box.login-box .rht .form-control {
    padding: 10px 30px;
    font: 500 17px/20px "Poppins", sans-serif;
    height: 55px;
  }

  .sign-up .w-box.login-box .rht .sub-btn {
    height: 50px;
    font: 500 16px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .rht .cont {
    margin: 25px 0 25px;
    font: 500 15px/20px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .lft .skip a {
    height: 45px;
    width: 200px;
    font: 700 18px/20px "Poppins", sans-serif;
    padding: 10px 40px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 15px/20px "Poppins", sans-serif;
  }

  .destinations-search .img-gallery ul {
    margin: 0 -10px;
  }

  .destinations-search .img-gallery ul li {
    margin-bottom: 15px;
    padding: 0 10px;
    margin-right: 0;
    float: left;
    width: 33.3333%;
  }

  .i-list.gi-list {
    margin-bottom: 100px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in {
    margin: 0 -50px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in .mid > p.bt.lst {
    margin-bottom: 20px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 0;
  }

  .acc-res-in.spfari .ari-in.s-offres-in ul.icon-list .icn {
    margin-bottom: 6px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    padding: 9.5px 10px;
  }

  .sl-tab .nav-list #nav-tab a {
    width: 160px;
    font: 700 14px/30px "Poppins", sans-serif;
    padding: 7px 5px;
  }

  .slh-in .g-box {
    width: 640px;
    padding: 50px 80px 40px;
    background: #f5f5f5;
    margin: 0 auto;
    float: none;
  }

  .map-outer .map-marker {
    width: 25px;
    height: 35px;
    background-repeat: no-repeat;
  }

  .map-outer {
    width: 680px;
    padding: 0;
    margin: 0 auto;
    position: relative;
  }

  .map-outer .map-marker#mm1 {
    bottom: 240px;
    right: 390px;
  }

  .map-outer .map-marker#mm2 {
    top: 60px;
    right: 370px;
  }

  .map-outer .map-marker#mm3 {
    top: 290px;
    right: 290px;
  }

  .map-outer .map-marker#mm4 {
    bottom: 135px;
    right: 220px;
  }

  .cont-page .sec-sec .form-group {
    margin-bottom: 50px;
  }

  .how-much {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .any-other {
    margin-top: 10px;
    margin-bottom: -20px;
  }

  .what-cur {
    margin-bottom: 25px;
  }

  .main-content.contact-cont .row.c-form h3.yhh.yhh2:after {
    width: 180px;
  }

  .aoq {
    margin-top: 0;
    margin-bottom: 0;
  }

  .feefo-img img {
    margin-left: 50px;
  }

  .horizontal-form .top250-gp {
    margin-top: 0;
  }

  .horizontal-form .f-in .submit.msub {
    width: 100%;
  }

  #navigation .navbar-nav li:last-child a {
    padding-right: 0;
    text-align: right;
    width: 33px;
    display: block;
    margin: 0 auto;
  }

  #cookie-msg {
    padding-right: 200px;
  }

  #cookie-msg #close-c-msg {
    width: 200px;
  }

  #navigation .dropdown-menu:after {
    top: 28px;
  }

  .social-hub .small-box .text-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    /* number of lines to show */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .main-content > .container > .row.review-rating #review .carousel-item .carousel-caption p {
    font: 400 18px/26px "Poppins", sans-serif;
  }

  .acc-res-in .ari-in .isr-in-row {
    min-height: 310px;
  }

  .acc-res-in .ari-in .isr-in-row .lp {
    width: 100%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .acc-res-in .ari-in .isr-in-row .bottom-paragraphs {
    bottom: 10px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 3px;
  }

  #nav-2 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 330px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 280px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    text-align: center;
    padding: 9.5px 16px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 10px;
    padding-left: 10px;
  }

  .destinations-search .nav-list #nav-tab a {
    padding: 0 20px 0;
  }

  .hotel-name .hname h3 span.tt {
    margin-left: 0;
    display: block;
    margin-top: 5px;
  }

  .dwn-pop {
    bottom: auto;
    top: 280px;
  }

  #middle-main-content .taior-made p.lp {
    margin-bottom: 60px;
  }

  .rates-top .cur label {
    font-size: 17px;
    margin: 15px 0 5px;
    padding-left: 0;
    width: 100%;
  }

  .rates-top .cur select {
    width: 100%;
    padding: 5px;
    margin-left: 0;
    font-size: 14px;
    margin-bottom: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #navigation {
    padding: 15px 20px;
  }

  #navigation .navbar-nav li a {
    font-size: 12.8px;
  }

  #navigation .navbar-brand img, #navigation .navbar-brand svg {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 90px;
  }

  #navigation .navbar-nav li:last-child a img {
    margin-top: -5px;
  }

  #home-slider .carousel-item {
    height: 460px;
  }

  #home-slider .carousel-item .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p:nth-last-of-type(1) {
    margin-bottom: 80px;
  }

  #middle-main-content .boxes .col.box.b1 {
    height: 440px;
  }

  #middle-main-content .boxes .col.box.b2 {
    height: 273px;
  }

  #middle-main-content .boxes .col.box.b3 {
    height: 328px;
  }

  #middle-main-content .boxes .col.box.b4 {
    height: 620px;
  }

  #middle-main-content .boxes .col.box.b5 {
    height: 294px;
  }

  #middle-main-content .boxes .col.box.b6 {
    height: 296px;
  }

  #middle-main-content .boxes .col.box.b7 {
    height: 450px;
  }

  #middle-main-content .boxes .col.box a .txt {
    padding: 30px 30px;
  }

  #middle-main-content .best-travel-company h2 {
    margin-top: 80px;
    margin-bottom: 25px;
  }

  #middle-main-content .best-travel-company p {
    margin-bottom: 80px;
  }

  .pi-top {
    margin-top: 80px;
  }

  #middle-main-content .slick-prev,
#middle-main-content .slick-next {
    top: 135px;
  }

  .popular-itineraries .slick-slide .pi-in {
    padding: 30px;
  }

  .popular-itineraries .slick-slide ul.l-list li a.qe {
    padding: 9.5px 35px;
  }

  .popular-itineraries .slick-slide ul.l-list li {
    margin-right: 5px;
  }

  .find-my-itinerary {
    margin: 80px 0;
  }

  .social-hub {
    margin-top: 80px;
  }

  .find-my-itinerary .map-img img {
    width: 100%;
  }

  .find-my-itinerary .fmi-form {
    margin-top: 50px;
  }

  .find-my-itinerary .fmi-form .f-in {
    padding: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .awselect {
    margin-bottom: 25px;
  }

  .find-my-itinerary .fmi-form .f-in .form-control {
    margin-bottom: 25px;
  }

  .social-hub .small-box {
    height: 400px;
  }

  .social-hub .small-box .text-box {
    bottom: -295px;
  }

  .social-hub .small-box .text-box h4 {
    font: 500 14px/18px "Poppins", sans-serif;
  }

  .fotter h4 {
    font: 700 20px/38px "Source Sans Pro", sans-serif;
    margin-bottom: 25px;
  }

  .fotter p {
    font: 400 16px/30px "Source Sans Pro", sans-serif;
    letter-spacing: 0;
  }

  .fotter p.sm-p a .fa:before {
    top: 52%;
  }

  .fotter .hp {
    padding: 20px 100px 10px;
  }

  .main-content .rht ul.l-list li a.qe, .main-content .rht ul.l-list li a.offers {
    padding: 10px 25px;
  }

  .main-content > .container > .row.review-rating .rht .col-12 p {
    font: 400 11px/20px "Poppins", sans-serif;
  }

  .main-content > .container > .row.c-form {
    padding: 30px 100px;
  }

  .hotel-name .hname h3 {
    font-size: 25px;
  }

  .hotel-name {
    padding: 10px 0;
  }

  .horizontal-form .f-in .submit {
    width: 180px;
  }

  .horizontal-form .f-in .budget-gp {
    width: 120px;
  }

  .horizontal-form .f-in .accommodation-type-gp {
    width: 180px;
  }

  .horizontal-form .f-in .destination-gp {
    width: 170px;
  }

  .horizontal-form .f-in .awselect .front_face {
    font: 300 11px/12px "Poppins", sans-serif;
  }

  .horizontal-form .f-in .form-control {
    font: 300 12px/12px "Poppins", sans-serif;
  }

  .horizontal-form.res-bar .results-btns .list-inline li {
    font: 400 11px/15px "Source Sans Pro", sans-serif;
    padding: 6px 12px;
    margin: 0 5px;
  }

  .horizontal-form.res-bar .results-btns .r-search {
    width: 142px;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    -webkit-box-flex: unset;
        -ms-flex-positive: unset;
            flex-grow: unset;
    padding: 0;
    margin-left: 0px;
    margin-right: 5px;
    padding-right: 14px;
    position: relative;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 45px;
  }

  .acc-res-in .rs {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 40px;
  }

  .acc-res-in .rp p span.star {
    margin-right: 5px;
  }

  #acc-slider .caption h1 {
    font: Bold 38px/40px "Poppins", sans-serif;
    margin-bottom: 0;
  }

  #acc-slider .caption {
    top: 55%;
  }

  #middle-main-content .taior-made p.act-p {
    margin-bottom: 90px;
  }

  .horizontal-form .f-in .destination2-gp {
    width: 330px;
  }

  .horizontal-form .f-in .activities-gp {
    width: 350px;
  }

  #middle-main-content .slick-track .slick-slide img {
    width: 100%;
  }

  #middle-main-content .activities-gallery .slick-prev,
#middle-main-content .activities-gallery .slick-next {
    top: 100px;
  }

  .destinations-search .nav-list #nav-tab a {
    font: 600 17px/30px "Poppins", sans-serif;
  }

  .destinations-search .img-gallery ul {
    margin: 0 -10px;
  }

  .destinations-search .img-gallery ul li {
    margin-bottom: 15px;
    padding: 0 10px;
    margin-right: 0;
    float: left;
    width: 33.3333%;
  }

  .destinations-search .img-gallery ul li:last-child a span.lst-amt {
    font-size: 18px;
    line-height: 18px;
    padding: 0 10px;
  }

  .horizontal-form .f-in .holiday-gp {
    width: 180px;
  }

  .horizontal-form .f-in .sd-gp {
    width: 140px;
  }

  .travel-board-boxes.itineraries .lft .box-list li,
.travel-board-boxes.itineraries .lft-mid .box-list li,
.travel-board-boxes.itineraries .rht-mid .box-list li,
.travel-board-boxes.itineraries .rht .box-list li, .travel-board-boxes.activities .lft .box-list li, .travel-board-boxes.activities .rht-mid .box-list li {
    width: 240px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li:first-child {
    width: 319.98px;
  }

  .travel-board-boxes.activities .lft-mid .box-list li, .travel-board-boxes.activities .rht .box-list li {
    width: 160px;
  }

  .travel-board-boxes .box-in {
    margin-bottom: 20px;
  }

  #middle-main-content .taior-made h1 {
    font: Bold 28px/32px "Poppins", sans-serif;
  }

  .tm-gallery .gal-outer a.gi5, .tm-gallery .gal-outer a.gi8 {
    width: 160px;
  }

  .tm-gallery .gal-outer a.gi6, .tm-gallery .gal-outer a.gi7 {
    width: calc(100% - 160px);
  }

  #middle-main-content .boxes.transport-boxes {
    padding: 0 80px;
  }

  #middle-main-content .boxes.transport-boxes .col.box {
    height: 320px !important;
  }

  .sign-up .w-box {
    width: 920px;
    padding: 40px 80px;
    margin: 200px auto;
  }

  .sign-up .w-box h1 {
    margin-bottom: 30px;
    font-size: 40px;
  }

  .sign-up .w-box .ani-form .form-group .form-control {
    height: 50px;
  }

  .sign-up .w-box .ani-form .form-group.tc-fg .cont {
    margin: 15px 0;
    font-size: 15px;
  }

  .sign-up .w-box .ani-form .form-group.submit #sign {
    height: 50px;
    font: 500 20px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box .ani-form .form-group.bl-btn a {
    font: 500 20px/12px "Poppins", sans-serif;
    height: 50px;
    padding: 17px;
  }

  .sign-up .w-box.login-box {
    padding: 55px 50px;
  }

  .sign-up .w-box.login-box .rht {
    padding-left: 50px;
  }

  .sign-up .w-box.login-box .lft {
    padding-right: 50px;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    height: 70px;
    font: 500 20px/20px "Poppins", sans-serif;
    padding: 10px 30px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 25px;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }

  .sign-up .w-box.login-box .rht .form-control {
    padding: 10px 40px;
    font: 500 20px/20px "Poppins", sans-serif;
    height: 70px;
  }

  .sign-up .w-box.login-box .rht .sub-btn {
    height: 55px;
    font: 500 20px/12px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .rht .cont {
    margin: 35px 0 30px;
    font: 500 18px/20px "Poppins", sans-serif;
  }

  .sign-up .w-box.login-box .lft .skip a {
    height: 55px;
    width: 200px;
    font: 700 20px/20px "Poppins", sans-serif;
    padding: 10px 40px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 18px/20px "Poppins", sans-serif;
  }

  .destinations-search ul.l-list li a.offers {
    padding: 10px 8px;
  }

  .destinations-search ul.l-list li a.qe {
    padding: 10px 8px;
  }

  .popular-itineraries.special-offers span.gbtn {
    top: 90px;
  }

  /*itineraries-individual.php*/
  .acc-res-in .ls img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left;
       object-position: left;
  }

  .acc-res-in.spfari .ari-in.s-offres-in .mid > p.bt.lst {
    margin-bottom: 20px;
  }

  .acc-res-in ul.l-list {
    margin-top: 60px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in .rs {
    padding-right: 15px;
    padding-left: 15px;
  }

  .acc-res-in.spfari .ari-in.s-offres-in.acc-so-in ul.l-list li {
    margin: 10px 0 0;
  }

  .sl-tab .nav-list #nav-tab a {
    width: 210px;
  }

  .slh-in .g-box {
    width: 740px;
    padding: 50px 80px 40px;
    background: #f5f5f5;
    margin: 0 auto;
    float: none;
  }

  .cont-page .sec-sec .form-group {
    margin-bottom: 50px;
  }

  .how-much {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .any-other {
    margin-top: 10px;
    margin-bottom: -20px;
  }

  .what-cur {
    margin-bottom: 25px;
  }

  .main-content.contact-cont .row.c-form h3.yhh.yhh2:after {
    width: 210px;
  }

  .feefo-img img {
    margin-left: 100px;
  }

  .horizontal-form .f-in .submit.msub {
    width: 100%;
  }

  #navigation .navbar-brand {
    margin-right: 0px;
  }

  #cookie-msg {
    padding-right: 200px;
  }

  #cookie-msg #close-c-msg {
    width: 200px;
  }

  #navigation .dropdown-menu .dropdown-item {
    padding: 4px 7px;
    font-size: 10px;
    background: transparent !important;
  }

  .social-hub .small-box .text-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    /* number of lines to show */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .main-content > .container > .row.nc .share ul.nav li a svg {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  .acc-res-in .ari-in .isr-in-row {
    min-height: 310px;
  }

  .acc-res-in.fis-in h4.nad {
    margin-bottom: 85px;
  }

  .popular-itineraries.special-offers .rp p span.star {
    margin-right: 3px;
  }

  #nav-2 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 320px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in {
    min-height: 280px;
  }

  #nav-3 .acc-res-in.spfari .ari-in.s-offres-in ul.l-list li a.qe {
    text-align: center;
    padding: 9.5px 30px;
  }

  .dwn-pop {
    bottom: auto;
    top: 370px;
  }

  .rates-top h5 {
    font-size: 14px;
    line-height: 20px;
  }

  .rates-top .cur label {
    font-size: 14px;
    margin: 15px 0;
    padding-left: 0;
  }

  .rates-top .cur select {
    width: 100px;
    padding: 5px;
    margin-left: 10px;
    font-size: 12px;
  }

  .popular-itineraries .slick-slide .iti-d-2 {
    min-height: 180px;
  }
}
@media (min-width: 1200px) and (max-width: 1680px) {
  #main-content .top-account-line .tal-in.tal-in-middle {
    margin: 0 120px;
  }

  #main-content .top-account-line .tal-in.tal-in-middle:before {
    content: "";
    width: 95px;
    left: -110px;
  }

  #main-content .top-account-line .tal-in.tal-in-middle:after {
    content: "";
    width: 95px;
    right: -105px;
  }

  .mid-gate-line .gate-tool .g-tool {
    padding: 22px 15px;
  }

  .mid-gate-line .gate-tool .g-tool h4 {
    font: 16px/30px "SFPD Bold";
    margin-bottom: 5px;
  }

  .mid-gate-line .gate-tool .g-tool p {
    font: 12.8px/16px "SFPD Regular";
    margin-bottom: 10px;
  }

  .mid-gate-line .gate-tool .g-tool a {
    font: 11px/25px "SFPD Bold";
  }

  .mid-gate-line .personalize-db .nav-tabs .nav-item a {
    font: 12px/21px "SFPD Bold";
    padding: 15px 5px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 h4 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane {
    padding: 28px 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li {
    font-size: 15px;
  }

  .transactions .img-box .mi {
    max-width: 80%;
  }

  .footer p {
    font: 12px/16px "SFPD Bold";
  }

  .mid-gate-line .gate-tool h3,
.mid-gate-line .personalize-db h3 {
    font: 15px/26px "SFPD Bold";
    margin: 0 0 15px;
  }

  .mid-gate-line .gate-tool h3 span.rs,
.mid-gate-line .personalize-db h3 span.rs {
    font: 10px/20px "SFPD Bold";
  }

  #side-navigation .logo-outer {
    height: 80px;
  }

  #side-navigation .logo-outer img.logo {
    width: 200px;
  }

  #side-navigation .act-acc {
    padding: 10px 30px;
    max-height: 95px;
  }

  #side-navigation .act-acc h3 {
    font: 15px/15px "SFPD Bold";
    margin-bottom: 5px;
  }

  #side-navigation .act-acc p.ns {
    font: 12px/15px "SFPD Regular";
    margin-bottom: 5px;
  }

  #side-navigation .nav-user {
    height: 60px;
    padding: 0 20px;
  }

  #side-navigation .nav-list-outer {
    padding: 20px 0;
    height: calc(100% - 80px - 95px - 60px);
  }

  #side-navigation .nav-list-outer .nav-list li a {
    font: 14px/27px "SFPD Bold";
    padding-left: 100px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img {
    width: 80px;
    margin-right: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-cont {
    width: calc(100% - 100px);
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box {
    width: 80px;
    height: 80px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-img .up-box img {
    width: 80%;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 h4 {
    font: 14px/21px "SFPD Bold";
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p1 {
    font: 12px/14px "SFPD Regular";
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 p.p2 {
    font: 12px/16px "SFPD Regular";
    margin-bottom: 20px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab1 .up-form label {
    font: 12px/25px "SFPD Bold";
    text-decoration: none;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab2 .user-list li {
    padding: 0 10px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 10px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab2 h4 {
    font-size: 15px;
    margin-bottom: 18.3px;
  }

  .mid-gate-line .personalize-db .tab-content .tab-pane#tab3 .color-list li .ball {
    width: 60px;
    height: 60px;
    margin: 10px auto 20px;
  }

  .balance-line .in h3 {
    font: 20px/34px "SFPD Bold";
  }

  .balance-line .in h5 {
    font: 12px/16px "SFPD Bold";
    margin-bottom: 0;
  }

  .balance-line .in {
    padding: 0 20px;
  }

  .balance-line .in .date-btn {
    height: 45px;
    width: 180px;
    font: 15px/27px "SFPD Bold";
    margin-right: -20px;
  }

  .transactions .t-list li h3 {
    font: 22px/34px "SFPD Bold";
  }

  .transactions .t-list li i {
    right: 25px;
  }

  #side-navigation .nav-list-outer .nav-list li a .ni {
    left: 34px;
  }

  #side-navigation .nav-user .nu-in a img.dots {
    margin-left: 40px;
  }

  #side-navigation .nav-user .nu-in a {
    font: 14px/30px "SFPD Bold";
  }

  #inside-container {
    padding: 0 60px;
  }

  .sign-box {
    width: 37.203%;
    height: calc(100vh - 100px);
    margin-top: 50px;
    padding: 40px 55px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 37.203%;
            flex: 0 0 37.203%;
    max-width: 37.203%;
  }

  #inside-container .sign-top .sign-icon {
    margin: 0 auto 10px;
    width: 50px;
  }

  #inside-container .sign-top .sign-head {
    font: 700 25px/36px "SFPD Regular";
    margin-bottom: 20px;
  }

  #inside-container .form-type-1 .form-group .form-control {
    height: 45px;
    padding: 10px 20px;
    font-size: 12.8px;
  }

  #inside-container .form-type-1 .submit #submit-btn {
    font: 700 15px "Roboto", sans-serif;
    height: 45px;
    padding: 8px 35px;
    margin: 30px auto 30px;
  }

  #inside-container .sign-box .change-tel {
    font: 700 15px "Roboto", sans-serif;
    height: 45px;
    padding: 12px 35px;
    margin: -15px auto 20px;
  }

  #inside-container .no-account .no-account-p {
    font: 400 14px/20px "SFPD Regular";
    margin-bottom: 20px;
  }

  #inside-container .copy-txt {
    padding: 0 10%;
  }

  .sign-box .cont {
    font: 700 14px/19px "SFPD Bold";
  }

  #inside-container .form-type-1 .fogp .fp {
    font: 700 14px "SFPD Bold";
  }

  #inside-container .main-row .logo-txt {
    margin-top: 100px;
    padding-left: 30px;
  }

  #inside-container .no-account p.sms-p {
    font: 700 14px/16px "SFPD Bold";
    margin-bottom: 10px;
  }

  #inside-container .sign-box p.time-left {
    font: 400 18px/25px "SFPD Regular";
    margin-bottom: 25px;
    margin-top: -7px;
  }

  #inside-container .sign-box .pin-ver-form .form-group .form-control {
    height: 50px;
    padding: 10px 10px;
    margin-bottom: -10px;
  }

  #inside-container .main-row .logo-txt h1 {
    font: 700 40px/60px "SFPD Bold";
    margin: 80px 0 10px;
  }

  #inside-container .main-row .logo-txt h4 {
    font: 400 18px/40px "SFPD Regular";
    margin-top: 50px;
  }

  #turn-box {
    width: 1400px;
    height: 1400px;
    top: 120px;
    right: -50%;
  }

  #side-navigation {
    width: 280px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 280px;
            flex: 0 0 280px;
    max-width: 280px;
  }

  #main-content {
    padding-left: 280px;
  }

  #main-content .top-line p {
    font: 14px/21px "SFPD Regular";
  }

  #main-content .top-line .sandbox {
    padding: 12.5px 20px;
    font: 15px/25px "SFPD Bold";
  }

  #main-content .top-line .sandbox .l-circle {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
  }

  #main-content .top-line .notification-bell {
    right: 240px;
    top: 12px;
    width: 25px;
    height: 25px;
  }

  #main-content .top-line {
    padding: 14.5px 0;
    padding-left: 30px;
  }

  #main-content.dashboard-live-mode .top-line p {
    padding-right: 310px;
  }

  #main-content.dashboard-live-mode .top-line p:after {
    right: 285px;
  }

  #main-content .notification-bar .nb-in {
    padding: 15px 30px;
  }

  #main-content .notification-bar p {
    font: 14px/24px "SFPD Bold";
  }

  #main-content .notification-bar .colps-btn {
    font: 12.8px/20px "SFPD Bold";
  }

  table.transactions-table thead th {
    font: 400 12.8px/16px "SFPD Bold";
    padding: 20px 10px;
  }

  table.transactions-table tbody tr td {
    font: 400 12px/16px "SFPD Bold";
    color: #2b2935;
    padding: 20px 10px;
    border: 0;
  }

  #activation-modal.modal-dialog,
#activation-modal-2.modal-dialog,
.ancd-mod,
.ctm-modal.modal-dialog {
    -webkit-transform: scale(0.75) !important;
            transform: scale(0.75) !important;
    margin: -7vh auto;
  }

  .transaction-top .nav-tabs .nav-item .nav-link {
    padding: 15px;
  }

  .transaction-top .tab-content .tt-form .form-group {
    padding: 0 5px;
  }

  .transaction-top .tab-content .tt-form .form-group .form-control {
    height: 40px;
    padding: 0 12px;
    font: 12px/18px "SFPD Regular";
  }

  .transaction-top .tab-content .tt-form .form-group .srch-btn {
    padding: 8.5px 30px;
    font: 12.8px/19px "SFPD Bold";
  }

  .transaction-top .tab-content .tr-form {
    padding: 0;
    padding-left: 0;
  }

  .transaction-top .tab-content .tr-form .form-group:last-child {
    width: 40px;
  }

  .transaction-top .tab-content .tr-form .form-group {
    width: calc((100% - 40px) / 2);
    padding: 0 5px;
    margin: 0;
  }

  .transaction-top .tab-content .tr-form .form-group .form-control {
    height: 40px;
    padding: 0 25px;
    font: 12px/18px "SFPD Regular";
  }

  .transaction-top .tab-content .tr-form .form-group .slct i {
    left: 10px;
    top: 14px;
  }

  .transaction-top .tab-content .tr-form .form-group .slct:after,
.transaction-top .tab-content .tt-form .form-group .slct:after {
    top: 12px;
  }

  .transaction-top .tab-content .tr-form .form-group .dwn-btn {
    width: 30px;
    font-size: 26px;
  }

  .transaction-top .tab-content .tt-ttp {
    padding: 10px 10px 15px;
  }

  .transaction-top .tab-content .tr-form .form-group label {
    font: 12px/14px "SFPD Bold";
  }

  .transaction-top .tab-content .tt-form .form-group label {
    font: 12px/14px "SFPD Bold";
  }

  .transaction-top .nav-tabs .nav-item .nav-link .amt {
    font: 22px/34px "SFPD Bold";
  }

  #side-navigation .act-acc .t-amt {
    font: 24px/34px "SFPD Bold";
  }

  #plus-button {
    bottom: 70px;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    -webkit-transform-origin: center;
            transform-origin: center;
    right: 20px;
  }

  #plus-button.active {
    -webkit-transform: rotate(-45deg) scale(0.75);
    transform: rotate(-45deg) scale(0.75);
  }

  #plus-menu {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
    bottom: 150px;
  }

  #create-new-payment-link-model,
#create-new-payment-template-modal {
    -webkit-transform: scale(0.75) !important;
    transform: scale(0.75) !important;
    margin: -7vh auto;
  }

  .transaction-top.reports-top .form-control {
    font: 11px/18px "SFPD Bold";
  }

  .transaction-top.reports-top h6 {
    font: 10px/12px "SFPD Bold";
  }

  .transaction-top.reports-top .cont {
    font: 11px/16px "SFPD Bold";
    padding-left: 27px;
  }

  .transaction-top.reports-top p {
    font: 8.5px/12px "SFPD Regular";
    margin-top: 5px;
  }

  .transaction-top.reports-top .nxt-btn {
    padding: 14px 10px;
    font: 12px/10px "SFPD Bold";
  }

  .transaction-top.reports-top .checkmark {
    top: -2px;
  }

  .transaction-top.reports-top .cont .fas {
    margin-left: 3px;
  }

  .transaction-top.reports-top .reports-h h3 {
    font: 22px/28px "SFPD Bold";
    margin-bottom: 20px;
  }

  .transaction-top.reports-top .reports-h h4 {
    font: 12px/14px "SFPD Bold";
    margin-top: 15px;
  }

  .transaction-top.reports-top .tab-content .tt-ttp {
    padding: 10px 0px 15px;
  }

  .transaction-top.reports-top .fas.fa-exchange-alt {
    right: 12px;
    bottom: 27px;
    font-size: 10px;
  }

  .settings-outer .lft-sd {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 285px;
            flex: 0 0 285px;
    max-width: 285px;
    margin-right: 30px;
    padding: 40px 0;
  }

  .settings-outer .rht-sd {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 315px);
            flex: 0 0 calc(100% - 315px);
    max-width: calc(100% - 315px);
    padding: 40px 15px;
  }

  .settings-outer .lft-sd .nxt-btn,
.settings-outer .lft-sd .ext-btn {
    padding: 12px 10px;
    font: 14px/21px "SFPD Bold";
  }

  .settings-outer .lft-sd #set-tab1 .nav-item a {
    font: 18px/22px "SFPD Bold";
    padding: 20px 10px;
  }

  .settings-outer .rht-sd .tdh {
    font: 24px/30px "SFPD Bold";
    margin-bottom: 20px;
  }

  .settings-outer .rht-sd .sth {
    font: 18px/22px "SFPD Bold";
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .settings-outer .rht-sd .sh {
    font: 12px/14px "SFPD Bold";
  }

  .settings-outer .rht-sd .mh {
    font: 14px/20px "SFPD Bold";
    margin-bottom: 20px;
  }

  .settings-outer .rht-sd .soc-in img {
    width: 30px;
  }

  .settings-outer .rht-sd .soc-in {
    padding-left: 60px;
  }

  .settings-outer .rht-sd .soc-in .form-control {
    font: 12px/18px "SFPD Bold";
    margin-top: -2px;
  }

  .settings-outer .rht-sd .nxt-btn {
    padding: 7px 10px;
    font: 12px/17px "SFPD Bold";
  }

  .settings-outer .rht-sd table.transactions-table thead th {
    font: 400 11px/12px "SFPD Bold";
    padding: 20px 10px;
  }

  .r-section .r-btn,
.r-section .checkbox-tools:checked + label,
.r-section .checkbox-tools:not(:checked) + label {
    width: 196px;
    height: 130px;
  }

  .r-section .for-checkbox-tools .icn {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .r-section .for-checkbox-tools .no {
    font: 12px/20px "SFPD Bold";
    margin-bottom: 5px;
  }

  .r-section .for-checkbox-tools .name {
    font: 8px/8px "SFPD Regular";
    margin-bottom: 15px;
  }

  .r-section .for-checkbox-tools .date {
    width: 50%;
    float: left;
    font: 10px/15px "SFPD Bold";
  }

  .r-section .for-checkbox-tools .no2 {
    width: 50%;
    float: left;
    font: 10px/15px "SFPD Bold";
    text-align: right;
  }

  .r-section .checkbox-tools:checked + label,
.r-section .checkbox-tools:not(:checked) + label {
    padding: 15px;
    border-radius: 10px;
  }

  .r-section .r-btn .txt span {
    font: 12px/14px "SFPD Bold";
  }

  .r-section .r-btn .txt i {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .r-section .r-btn.add-card {
    border-radius: 10px;
  }

  .profile-form-section .form-control {
    font: 14px/20px "SFPD Bold";
    margin-bottom: 20px;
  }

  #user-menu {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    bottom: 60px;
  }

  #user-menu.active {
    left: 20px;
  }
}
@media only screen and (max-width: 360px) {
  #acc-slider .caption h1 {
    font: Bold 28px/32px "Poppins", sans-serif;
  }

  #middle-main-content .slick-prev,
#middle-main-content .slick-next {
    top: 135px;
  }

  .acc-res-in ul.l-list li a.qe {
    padding: 9.5px 30px;
  }

  .sec-sec.cur-btns .btn {
    width: 90px;
  }

  .destinations-search .nav-list #nav-tab a {
    padding: 0 40px 0;
  }

  .sign-up .w-box {
    width: 310px;
  }

  .sign-up .w-box.login-box .lft .btn-col a .fa {
    font-size: 35px;
    width: 35px;
    margin-right: 20px;
    text-align: center;
  }

  .sign-up .w-box.login-box .lft .btn-col a {
    font: 500 14px/20px "Poppins", sans-serif;
    padding: 10px 20px;
  }

  .sign-up .w-box.login-box .rht .sup .rn-p {
    font: 500 14px/20px "Poppins", sans-serif;
  }

  .map-outer {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }

  #acc-slider .red-txt, .red-text-wrapper .red-txt {
    font: Bold 12px/25px "Poppins", sans-serif;
    padding: 7px;
    width: 100%;
    text-align: center;
    left: 0;
  }

  .red-text-wrapper {
    margin-top: -39px;
  }

  .acc-res-in .rp p {
    font: bold 12px/25px "Poppins", sans-serif;
  }

  .acc-res-in .rp p span.star i.fa, .acc-res-in .rp p span.trip i.fa {
    font-size: 17px;
  }
}/*# sourceMappingURL=master.css.map */